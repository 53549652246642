import { Editor, TLShapeId } from 'tldraw';
import { useOnSelection } from './useOnSelection';
import { useState } from 'react';

export const useSelectionWatcher = (editor: Editor | null) => {
  const [hasObjectSelected, setHasSelected] = useState(false);
  const [hasImageSelected, setHasImageSelected] = useState(false);
  const [hasDrawingSelected, setHasDrawingSelected] = useState(false);

  useOnSelection((shapes: TLShapeId[]) => {
    setHasSelected(shapes.length > 0);

    let hasImageSelectedTemp = false;
    let hasDrawingSelectedTemp = false;

    shapes.forEach((shapeId) => {
      if (!editor) return;

      const shape = editor.getShape(shapeId);
      if (!shape) return;
      if (shape.type === 'image') hasImageSelectedTemp = true;
      else if (shape.type === 'drawCustom') hasDrawingSelectedTemp = true;

      setHasDrawingSelected(hasDrawingSelectedTemp);
      setHasImageSelected(hasImageSelectedTemp);
    });
  }, editor);

  return { hasObjectSelected, hasImageSelected, hasDrawingSelected };
};
