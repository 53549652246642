export const checkIfEveryFileUnder10MB = (files: File[]) => {
  return files.every((file) => file.size < 10000000);
};

export const checkIfEveryFileAnImage = (files: File[] | DataTransferItem[]) => {
  return files.every((file) => {
    return (
      file.type.includes('image') &&
      ( file.type.includes('bmp') ||
        file.type.includes('webp') ||
        file.type.includes('svg') ||
        file.type.includes('png') ||
        file.type.includes('jpeg'))
    );
  });
};

/**
 * Opens a file input dialog allowing the user to select multiple image files (JPG, PNG, SVG).
 * Once files are selected, it checks if each file is under 10MB and then invokes the provided callback with the selected files.
 *
 *
 * @param callback - A function to be called with the selected files. If no files are selected or if any file exceeds 10MB, an empty array is passed to the callback.
 */
export const addImagesDialog = async (callback: (files: File[]) => void) => {
  const input = document.createElement('input');
  input.type = 'file';
  input.multiple = true;

  // Accepted formats
  input.accept = '.bmp,.jpg,.jpeg,.png,.svg,.webp';
  input.click();

  //Handle the files
  //TODO: handling errors, wrong files, etc
  input.onchange = async () => {
    if (!input.files) {
      callback([]);
      return;
    }

    const files = Array.from(input.files);

    if (!checkIfEveryFileUnder10MB(files)) {
      callback([]);
      return;
    }

    callback(files);
  };
};

export const base64ToFile = (base64: string, fileName: string, mimeType: string) => {
  const byteString = atob(base64); // Decode Base64 to binary string
  const byteArray = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i); // Convert binary string to byte array
  }

  return new File([byteArray], fileName, { type: mimeType });
}