import {
  motion,
  MotionValue,
  useMotionValue,
  useMotionValueEvent,
  useTransform,
} from 'framer-motion';
import { useRef } from 'react';
import { debounce } from 'lodash';

type SliderProps = {
  onChange?: (value: number) => void;
  labels: string[];
  step: number;
  range: number[];
  initialValue: number;
};

const SliderLabel = ({
  label,
  position,
  percentage,
}: {
  label: string;
  position: number;
  percentage: MotionValue<number>;
}) => {
  const opacity = useTransform(
    percentage,
    [position - 0.3, position, position + 0.3],
    [0.5, 1, 0.5]
  );

  return (
    <motion.div className='text-xs' style={{ opacity }}>
      {label}
    </motion.div>
  );
};

const Slider = ({ onChange, labels, range, initialValue }: SliderProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const sliderWidth = sliderRef.current ? sliderRef.current.clientWidth - 16 : 332;
  const x = useMotionValue(initialValue * sliderWidth);
  const percentage = useTransform(
    () =>
      x.get() / sliderWidth
  );
  const valueInRange = useTransform(percentage, [0, 1], range);
  const debouncedOnChange = debounce((latestValue: number) => {
    onChange?.(latestValue);
  }, 300);

  useMotionValueEvent(valueInRange, 'change', debouncedOnChange);

  /* Clicking directly on the track */
  const handleTrackClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      x.set(clickX - 8);
    }
  };

  /* Keyboard navigation */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowLeft') {
      x.set(Math.max(x.get() - 10, 0));
    } else if (event.key === 'ArrowRight') {
      x.set(
        Math.min(
          x.get() + 10,
          sliderRef.current ? sliderRef.current.clientWidth - 16 : 344
        )
      );
    }
  };

  return (
    <motion.div
      layout
      ref={sliderRef}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      className='focus:outline-none'>
      <motion.div
        layout
        className='relative flex h-4 w-full content-center items-center overflow-visible'
        onClick={handleTrackClick}>
        {/* THUMB */}
        <motion.button
          drag='x'
          dragElastic={0}
          dragMomentum={false}
          dragConstraints={sliderRef}
          whileDrag={{ scale: 1.2 }}
          className='absolute z-[500] h-4 w-4 rounded-full bg-black'
          style={{
            transform: 'translateY(-100%)',
            x,
          }}></motion.button>
        {/* TRACK */}
        <motion.div
          layout
          className='h-1 w-full cursor-pointer overflow-hidden rounded-sm bg-white focus:scale-105'>
          <motion.div
            className='h-2 w-full bg-black'
            style={{
              scaleX: percentage,
              originX: 0,
            }}></motion.div>
        </motion.div>
      </motion.div>
      {/* LABELS */}
      <motion.div layout className='flex w-full justify-between'>
        {labels.map((label, i) => (
          <SliderLabel
            key={i}
            label={label}
            position={i / (labels.length - 1)}
            percentage={percentage}
          />
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Slider;
