import { useKeycloak } from '@react-keycloak/web';
import type { DesignStyle } from '../../types';
import useAppContext from '../useAppContext';
import { dataURItoBlob } from '../../helpers/ImageManipulation';
import { usePanelContext } from '../../components/Creator/Panel/context/usePanelContext';
import { useParams } from 'react-router-dom';

const useImagine = () => {
  const { generationsLeft } = useAppContext();
  const { keycloak } = useKeycloak();
  const { whiteboardId } = useParams<{ whiteboardId: string }>();

  const { setInProgressGeneration, setError, setLoading } = usePanelContext();

  const imagine = async ({
    graphicStyle,
    input,
    dataImg,
    inspireAlbumId,
    inspireWeight,
  }: {
    graphicStyle: DesignStyle;
    input: string;
    dataImg: string;
    inspireAlbumId?: string;
    inspireWeight?: number;
  }) => {
    if (!dataImg.startsWith('data:image/png;base64,'))
      throw new Error('Invalid dataImg');

    if (!keycloak.token) {
      return setError('wrongKey');
    }

    if (generationsLeft < 1) {
      return setError('noAttemptsLeft');
    }

    setError(null);
    setLoading(true);

    const formData = new FormData();
    formData.append('input', input);
    formData.append('graphicStyle', graphicStyle);
    formData.append('source', 'Webapp');
    formData.append('scribble', dataURItoBlob(dataImg), 'scribble.png');
    if (whiteboardId) formData.append('whiteboardId', whiteboardId);

    if (inspireAlbumId) {
      console.log('using inspire with album id:', inspireAlbumId);
      formData.append('inspireAlbumId', inspireAlbumId);
      formData.append('inspireWeight', inspireWeight?.toString() ?? '0.5');
    }

    const url = new URL(
      inspireAlbumId ? '/ai/inspire' : '/ai/imagine',
      import.meta.env.VITE_API_URL
    );

    fetch(url, {
      body: formData,
      method: 'POST',
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then(async (res) => {
        if (!res.ok) throw res;

        //Get the id of the generation to poll every second
        const data = await res.json();
        if (!data) throw new Error('no data received from imagine');

        setInProgressGeneration(data);
      })
      .catch((err) => {
        console.error(err);
        setError('default');
        setLoading(false);
      });
  };

  return {
    imagine,
  };
};

export default useImagine;
