import { useQuery } from 'react-query';
import { useWhiteboardAPI } from './useWhiteboardAPI';
import { Whiteboard } from '../../types/api';

export const useGetLastUpdatedWhiteboard = () => {
  const { getLastUpdatedWhiteboard } = useWhiteboardAPI();

  const { data, isLoading, isError, error, status } = useQuery<
    Whiteboard,
    Error
  >(['whiteboards', 'lastUpdatedWhiteboard'], getLastUpdatedWhiteboard);

  return {
    whiteboard: data,
    status,
    isLoading,
    isError,
    error,
  };
};
