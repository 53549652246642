import { faBoltLightning, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from '@mui/material';

import {
  DefaultToolbarProps,
  DefaultToolbar,
  ArrowToolbarItem,
  AssetToolbarItem,
  SelectToolbarItem,
  HandToolbarItem,
  TldrawUiMenuToolItem,
  useTools,
  ToolbarItemProps,
  TLUiToolItem,
  useEditor,
  useValue,
  TldrawUiButton,
  TldrawUiDropdownMenuContent,
  TldrawUiDropdownMenuRoot,
  TldrawUiDropdownMenuTrigger,
} from 'tldraw';

function CustomToolbar(props: DefaultToolbarProps) {
  const isMobile = useMediaQuery('(max-width: 729px)');

  if (isMobile) {
    return (
      <div
        style={{
          position: 'relative',
          bottom: 90,
          left: 0,
        }}>
        <DefaultToolbar {...props}>
          <ToolbarContent />
        </DefaultToolbar>
      </div>
    );
  }

  return (
    <DefaultToolbar {...props}>
      <ToolbarContent />
      <HandToolbarItem />
    </DefaultToolbar>
  );
}

const ToolbarContent = () => {
  /// Redefine useIsToolSelected because the tldraw version
  /// does not work with our custom geo tools.
  /* Originale code:
   export function useIsToolSelected(tool: TLUiToolItem) {
   	const editor = useEditor()
   	const geo = tool.meta?.geo
   	return useValue(
   		'is tool selected',
   		() => {
   			const activeToolId = editor.getCurrentToolId()
   			const geoState = editor.getSharedStyles().getAsKnownValue(GeoShapeGeoStyle)
   			return geo ? activeToolId === 'geo' && geoState === geo : activeToolId === tool.id
   		},
   		[editor, tool.id, geo]
   	)
  } */

  /// Copy ToolbarItem code from tldraw in order to use our useIsToolSelected
  /// hook (code unchanged).

  return (
    <>
      <ToolbarItem tool='drawToolCustom' />
      <ToolbarItem tool='rectangleToolCustom' />
      <ToolbarItem tool='ellipseToolCustom' />

      <EraserSwitch />

      <ArrowToolbarItem />
      <ToolbarItem tool='lineToolCustom' />

      <ToolbarItem tool='textToolCustom' />
      <AssetToolbarItem />

      <SelectToolbarItem />
    </>
  );
};

const EraserSwitch = () => {
  const editor = useEditor();

  const isEraserSelected = useValue(
    'is eraser selected',
    () => {
      const currentTool = editor.getCurrentToolId();
      return currentTool === 'eraser' || currentTool === 'customEraser';
    },
    [editor]
  );

  const isNormalEraser = useValue(
    'is normal eraser',
    () => {
      const currentTool = editor.getCurrentToolId();
      return currentTool === 'eraser';
    },
    [editor]
  );

  return (
    <TldrawUiDropdownMenuRoot id='eraserMenu'>
      <TldrawUiDropdownMenuTrigger>
        <TldrawUiButton type='tool' className='relative min-w-min p-0'>
          <FontAwesomeIcon
            icon={faCaretUp}
            className='absolute right-1 top-0 z-100 rotate-45 p-1'
            style={{ color: isEraserSelected ? 'white' : 'black' }}
          />

          {!isNormalEraser ? (
            <ToolbarItem tool='customEraser' />
          ) : (
            <div className='relative'>
              <FontAwesomeIcon
                icon={faBoltLightning}
                className='absolute left-[55%] top-5 z-100 rotate-[20deg]'
                style={{ color: isNormalEraser ? 'white' : 'black' }}
              />
              <ToolbarItem tool='eraser' />
            </div>
          )}
        </TldrawUiButton>
      </TldrawUiDropdownMenuTrigger>
      <TldrawUiDropdownMenuContent>
        <div className='flex'>
          <div className='relative'>
            <FontAwesomeIcon
              icon={faBoltLightning}
              className='absolute left-[55%] top-5 z-100 rotate-[20deg]'
              style={{ color: isNormalEraser ? 'white' : 'black' }}
            />
            <ToolbarItem tool='eraser' />
          </div>
          <ToolbarItem tool='customEraser' />
        </div>
      </TldrawUiDropdownMenuContent>
    </TldrawUiDropdownMenuRoot>
  );
};

export function ToolbarItem({ tool }: ToolbarItemProps) {
  const tools = useTools();
  const isSelected = useIsToolSelected(tools[tool]);
  return <TldrawUiMenuToolItem toolId={tool} isSelected={isSelected} />;
}

function useIsToolSelected(tool: TLUiToolItem) {
  const editor = useEditor();
  return useValue(
    'is tool selected',
    () => {
      return tool.id === editor.getCurrentToolId();
    },
    [editor, tool.id]
  );
}

export default CustomToolbar;
