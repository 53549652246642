// Override default palette to force the fill color to be the same

import { DefaultColorThemePalette } from 'tldraw';

export const setDefaultPalette = () => {
  // as the one for contour
  DefaultColorThemePalette.lightMode.black.semi =
    DefaultColorThemePalette.lightMode.black.solid;
  DefaultColorThemePalette.lightMode.blue.semi =
    DefaultColorThemePalette.lightMode.blue.solid;
  DefaultColorThemePalette.lightMode.green.semi =
    DefaultColorThemePalette.lightMode.green.solid;
  DefaultColorThemePalette.lightMode.grey.semi =
    DefaultColorThemePalette.lightMode.grey.solid;
  DefaultColorThemePalette.lightMode['light-blue'].semi =
    DefaultColorThemePalette.lightMode['light-blue'].solid;
  DefaultColorThemePalette.lightMode['light-green'].semi =
    DefaultColorThemePalette.lightMode['light-green'].solid;
  DefaultColorThemePalette.lightMode['light-red'].semi =
    DefaultColorThemePalette.lightMode['light-red'].solid;
  DefaultColorThemePalette.lightMode['light-violet'].semi =
    DefaultColorThemePalette.lightMode['light-violet'].solid;
  DefaultColorThemePalette.lightMode.orange.semi =
    DefaultColorThemePalette.lightMode.orange.solid;
  DefaultColorThemePalette.lightMode.red.semi =
    DefaultColorThemePalette.lightMode.red.solid;
  DefaultColorThemePalette.lightMode.violet.semi =
    DefaultColorThemePalette.lightMode.violet.solid;
  DefaultColorThemePalette.lightMode.white.semi =
    DefaultColorThemePalette.lightMode.white.solid;
  DefaultColorThemePalette.lightMode.yellow.semi =
    DefaultColorThemePalette.lightMode.yellow.solid;

  DefaultColorThemePalette.darkMode.black.semi =
    DefaultColorThemePalette.darkMode.black.solid;
  DefaultColorThemePalette.darkMode.blue.semi =
    DefaultColorThemePalette.darkMode.blue.solid;
  DefaultColorThemePalette.darkMode.green.semi =
    DefaultColorThemePalette.darkMode.green.solid;
  DefaultColorThemePalette.darkMode.grey.semi =
    DefaultColorThemePalette.darkMode.grey.solid;
  DefaultColorThemePalette.darkMode['light-blue'].semi =
    DefaultColorThemePalette.darkMode['light-blue'].solid;
  DefaultColorThemePalette.darkMode['light-green'].semi =
    DefaultColorThemePalette.darkMode['light-green'].solid;
  DefaultColorThemePalette.darkMode['light-red'].semi =
    DefaultColorThemePalette.darkMode['light-red'].solid;
  DefaultColorThemePalette.darkMode['light-violet'].semi =
    DefaultColorThemePalette.darkMode['light-violet'].solid;
  DefaultColorThemePalette.darkMode.orange.semi =
    DefaultColorThemePalette.darkMode.orange.solid;
  DefaultColorThemePalette.darkMode.red.semi =
    DefaultColorThemePalette.darkMode.red.solid;
  DefaultColorThemePalette.darkMode.violet.semi =
    DefaultColorThemePalette.darkMode.violet.solid;
  DefaultColorThemePalette.darkMode.white.semi =
    DefaultColorThemePalette.darkMode.white.solid;
  DefaultColorThemePalette.darkMode.yellow.semi =
    DefaultColorThemePalette.darkMode.yellow.solid;
};
