import { useQueryClient, useMutation } from 'react-query';

import { useAlbumAPI } from './useAlbumAPI';

const useRemoveAlbumFromProjects = () => {
  const queryClient = useQueryClient();
  const { removeAlbumFromProjects: removeAlbumFromProjectsAPI } = useAlbumAPI();

  const removeAlbumFromProjectsMutation = useMutation({
    mutationFn: async ({
      albumId,
      projectIds,
    }: {
      albumId: string;
      projectIds: string[];
    }) => {
      return await removeAlbumFromProjectsAPI(albumId, projectIds);
    },
    onMutate: async (variables) => {
      queryClient.invalidateQueries(['album', variables.albumId]);

      // Invalidate project queries
      variables.projectIds.forEach((projectId) => {
        queryClient.invalidateQueries(['project', projectId]);
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['projects']);
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['albums']);
    },
  });

  return {
    removeAlbumFromProjects: (albumId: string, projectIds: string[]) =>
      removeAlbumFromProjectsMutation.mutate({ albumId, projectIds }),
    isLoading: removeAlbumFromProjectsMutation.isLoading,
    isError: removeAlbumFromProjectsMutation.isError,
    error: removeAlbumFromProjectsMutation.error,
  };
};

export default useRemoveAlbumFromProjects;
