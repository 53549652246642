import { motion } from 'framer-motion';

const variants = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
  exit: {
    opacity: 0,
  },
};

const PageAnimationWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <motion.div
      variants={variants}
      initial={'exit'}
      animate='animate'
      exit={'exit'}
      transition={{ duration: 0.25 }}
      layout
      className='relative flex h-full w-full basis-full flex-col overflow-y-auto md:overflow-hidden'>
      {children}
    </motion.div>
  );
};

export default PageAnimationWrapper;
