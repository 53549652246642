import SidebarHeader from './SidebarHeader';
import SidebarLink from './SidebarLink';
import User from './User';
import NavbarSection from './NavbarSection';

import { dashboardRoutes } from '../../../pages/router';

import { useHasRole } from '@/hooks/useHasRole';
import { useTranslation } from 'react-i18next';
import { useIsSidebarOpen } from '../store/isSidebarOpenStore';
import { motion } from 'framer-motion';

const Sidebar = () => {
  const hasRole = useHasRole();
  const { t } = useTranslation('dashboard');
  const { isSidebarOpen } = useIsSidebarOpen();

  return (
    <motion.div
      layout
      className='z-100 flex h-full w-max flex-col justify-between bg-surface-primary text-slate-200'>
      <motion.div
        layout='position'
        className='flex h-full flex-col justify-between p-2 md:p-4'>
        <div className=''>
          <SidebarHeader />

          <div className='flex flex-col gap-4 py-4'>
            <NavbarSection>
              <div className='flex flex-col gap-2'>
                {dashboardRoutes.map((link) => {
                  if (link.hideInSidebar) return null;
                  if (link.necessaryRoles) {
                    const hasRoles = link.necessaryRoles.every((role) =>
                      hasRole(role)
                    );
                    if (!hasRoles) return null;
                  }
                  return (
                    <SidebarLink
                      key={link.path}
                      activeSelectorId='sidebar'
                      to={link.path}
                      showLabel={isSidebarOpen}
                      icon={link.icon}>
                      {t(link.label)}
                    </SidebarLink>
                  );
                })}
              </div>
            </NavbarSection>
          </div>
        </div>
      </motion.div>
      <User />
    </motion.div>
  );
};

export default Sidebar;
