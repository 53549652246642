import { useTranslation } from 'react-i18next';
import useGetProject from '../../../hooks/projects/useGetProject';

type ProjectNameProps = {
  projectId: string;
};

export const ProjectName = ({ projectId }: ProjectNameProps) => {
  const { project } = useGetProject(projectId);
  const { t } = useTranslation('dashboard');

  if (!project) return <>{t('common.noFolder')}</>;

  return <>{project.name}</>;
};

export default ProjectName;
