import { LayoutGroup } from 'framer-motion';

import AlbumCard from './AlbumCard';
import { useTranslation } from 'react-i18next';

type AlbumGridProps = {
  albums: {
    id: string;
    name: string;
  }[];
};

const AlbumGrid = ({ albums }: AlbumGridProps) => {
  const { t } = useTranslation('dashboard');
  if (!albums) {
    return <p>{t('noItems.noAlbums')}</p>;
  }

  return (
    <div className='grid max-h-full grid-cols-1 gap-4 overflow-y-auto md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
      <LayoutGroup>
        {albums.map((album) => {
          return <AlbumCard key={album.id} albumId={album.id} />;
        })}
      </LayoutGroup>
    </div>
  );
};

export default AlbumGrid;
