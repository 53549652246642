import useGetAlbum from '@/hooks/albums/useGetAlbum';
import { useCurrentAlbumId } from '../store/currentAlbumIdStore';
import Card from '@/components/lib/Containers/Card';
import RenamableAlbum from '@/components/Creator/Panel/Album/RenamableAlbum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '@/components/lib/Button';
import AlbumImageGallery from '@/components/Creator/Panel/Album/AlbumImageGallery';
import { TimeTags } from '../Creations/CreationCard';
import InfoTag from '@/components/lib/InfoTag';
import { useNavigate } from 'react-router-dom';
import { useHasRole } from '@/hooks/useHasRole';
import { UserRole } from '@/types';
import { useTranslation } from 'react-i18next';

const AlbumView = () => {
  const { currentAlbumId, setCurrentAlbumId } = useCurrentAlbumId();
  const { album } = useGetAlbum(currentAlbumId as string);
  const navigate = useNavigate();
  const hasRole = useHasRole();
  const { t } = useTranslation('dashboard');

  if (!album) return null;

  return (
    <Card>
      {/* HEADER */}
      <div className='flex justify-between'>
        <div className='flex items-center gap-2 rounded-xl p-1 font-semibold'>
          <FontAwesomeIcon icon={faBookOpen} />
          <RenamableAlbum albumId={currentAlbumId as string} key={album.name} />
        </div>
        <Button
          round
          leftIcon={faTimes}
          size='xs'
          iconSize='12px'
          onClick={() => {
            setCurrentAlbumId(null);
          }}
        />
      </div>

      {/* IMAGES */}

      <Card.Section>
        <Card.SectionHeader>{t('album.view.inThisAlbum')}</Card.SectionHeader>
        <AlbumImageGallery album={album} />
      </Card.Section>

      {/* DETAILS */}
      <Card.Section>
        <Card.SectionHeader>{t('album.view.details')}</Card.SectionHeader>
        <div className='flex gap-4'>
          {/* Date */}
          <Card.SubSection>
            <Card.SubSectionHeader>
              {t('common.createdOn')}
            </Card.SubSectionHeader>
            <TimeTags createdAt={album.createdAt} />
          </Card.SubSection>

          {/* Projets */}

          {hasRole(UserRole.Projects) && (
            <Card.SubSection>
              <Card.SubSectionHeader>
                {t('album.view.projects')}
              </Card.SubSectionHeader>
              <div className='flex flex-wrap gap-2'>
                {album?.projects?.map((projet) => {
                  return (
                    <InfoTag>
                      <div
                        onClick={() => {
                          navigate(`/projects/${projet.id}`);
                          setCurrentAlbumId(null);
                        }}
                        className='h-min w-max cursor-pointer text-xs'>
                        {projet.name}
                      </div>
                    </InfoTag>
                  );
                })}
              </div>
            </Card.SubSection>
          )}
        </div>
      </Card.Section>
    </Card>
  );
};

export default AlbumView;
