import { UserRole } from '@/types';
import { useKeycloak } from '@react-keycloak/web';

export const useHasRole = () => {
  const { keycloak } = useKeycloak();
  const userRoles: string[] = keycloak.realmAccess?.roles || [];

  const hasRole = (role: UserRole | UserRole[] | undefined): boolean => {
    if (!role) return true;
    if (Array.isArray(role)) {
      return role.every((r) => userRoles.includes(r));
    }
    return userRoles.includes(role);
  };

  return hasRole;
};
