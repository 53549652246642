import { faCaretUp, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next, { changeLanguage } from 'i18next';

import { withTranslation } from 'react-i18next';
import { $language } from '../../store/language';

export const LanguageSelector = withTranslation()(() => {
  return (
    <div
      className='bg-button-inactive pointer-events-auto mr-2 flex cursor-pointer gap-2 rounded-2xl text-sm uppercase'
      onClick={() => {
        const lang = i18next.language === 'en' ? 'fr' : 'en';
        changeLanguage(lang);
        $language.set(lang);
      }}>
      <div className='text-text-color flex items-center gap-2 p-4 font-bold'>
        <FontAwesomeIcon icon={faLanguage} />
        {i18next.language}
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    </div>
  );
});
