import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  ColorPicker as ColorPickerContainer,
  ColorArea,
  ColorThumb,
  ColorSwatch,
  Input,
  ColorField,
  Label,
  ColorSlider,
  SliderTrack,
  parseColor,
  Color,
} from 'react-aria-components';

import { useParams } from 'react-router-dom';
import {
  OpacitySlider,
  TldrawUiMenuContextProvider,
  useEditor,
  useRelevantStyles,
  useValue,
} from 'tldraw';
import { hexColor } from '../CustomStyles/HexColor';
import { TLDrawShapeCustom } from '../CustomTools/CustomDraw/CustomDrawShapeUtil';
import { ToolbarItem } from './CustomToolbar';
import { useOnSelection } from '../hooks/useOnSelection';

export const supportedShapeTypes = ['drawCustom', 'geoCustom', 'textCustom'];

const ColorPicker = () => {
  const editor = useEditor();
  const styles = useRelevantStyles();
  const { whiteboardId } = useParams<{ whiteboardId: string }>();
  const [lastColors, setLastColors] = useState<string[]>([]);

  const addColorToHistory = useCallback(
    debounce((color: string) => {
      const parseRGBAColor = (color: string) => {
        //format: rgba(0, 0, 0, 1)
        const [r, g, b, a] = color
          .replace('rgba(', '')
          .replace(')', '')
          .split(',')
          .map((v) => parseFloat(v));

        return [r, g, b, a];
      };

      setLastColors((prev) => {
        if (prev[0]) {
          //Calculate the distance to the last color and replace it if it is too close

          const lastColor = parseRGBAColor(prev[0]);
          const newColor = parseRGBAColor(color);

          const distance = Math.sqrt(
            Math.pow(newColor[0] - lastColor[0], 2) +
              Math.pow(newColor[1] - lastColor[1], 2) +
              Math.pow(newColor[2] - lastColor[2], 2)
          );

          if (distance < 60) {
            prev.shift();
          }

          const newColors = [color, ...prev];

          const lastColors = newColors.slice(0, 16);

          // Save to local storage
          localStorage.setItem(
            'lastColors' + whiteboardId,
            JSON.stringify(lastColors)
          );
          return lastColors;
        }

        const newColors = [color, ...prev];

        const lastColors = newColors.slice(0, 16);

        // Save to local storage
        localStorage.setItem(
          'lastColors' + whiteboardId,
          JSON.stringify(lastColors)
        );

        return lastColors;
      });
    }, 500),
    []
  );

  //Set the color from the last selected shape to be the next color
  useOnSelection((shapeIds) => {
    if (shapeIds.length === 0) {
      return;
    }
    const shape = editor.getShape(shapeIds[0]);
    if (!shape) return;

    if (supportedShapeTypes.includes(shape.type)) {
      const customShape = shape as TLDrawShapeCustom;
      const color = customShape.props.hexColor;
      editor.setStyleForNextShapes(hexColor, color);
    }
  }, editor);

  // Load last colors from local storage
  useEffect(() => {
    const colors = localStorage.getItem('lastColors' + whiteboardId);
    if (colors) {
      setLastColors(JSON.parse(colors));
    }
  }, []);

  //Get next color as a signal (it rerenders the component on change)
  const nextColor = useValue(
    'next color',
    () => editor.getStyleForNextShape(hexColor),
    [editor]
  );

  //This needs to be debounced because ColorPicker is controlled (it both changes states and is rerendered on change)
  const changeColor = debounce((color: Color) => {
    console.log(color.toString('hex'));
    const value = hexColor.validate(color.toString('hex'));
    editor.setStyleForSelectedShapes(hexColor, value);
    editor.setStyleForNextShapes(hexColor, value);

    addColorToHistory(color.toFormat('rgb').toString());
  }, 100);

  if (!styles) return null;
  return (
    <div className='flex w-full flex-col gap-1'>
      <ColorPickerContainer
        key={nextColor}
        defaultValue={nextColor}
        onChange={changeColor}>
        <div className='relative grid aspect-square w-full min-w-[200px] grid-rows-[1fr_min-content] gap-2 p-2'>
          <ColorArea
            className='h-full w-full rounded-xl'
            colorSpace='hsb'
            xChannel='saturation'
            yChannel='brightness'>
            <ColorThumb
              className={
                'aspect-square h-5 rounded-full outline outline-2 outline-white'
              }
            />
          </ColorArea>
          <ColorSlider colorSpace='hsb' channel={'hue'}>
            <SliderTrack className='relative h-4 w-full rounded-md'>
              <ColorThumb className='absolute top-1/2 aspect-square h-6 rounded-full outline outline-2 outline-white' />
            </SliderTrack>
          </ColorSlider>
        </div>

        {/* HEX */}
        <ColorField>
          <div className='flex w-min flex-col content-start gap-1 px-2'>
            <Label className='text-xs font-semibold opacity-50'>Hex</Label>
            <div className='flex items-center gap-2'>
              <div
                className={
                  'flex w-[90px] items-center justify-between overflow-hidden rounded-lg border border-grey-200 border-opacity-35 bg-grey bg-opacity-5 p-1'
                }>
                <Input className={'w-full bg-transparent opacity-60'} />
                <FontAwesomeIcon
                  icon={faCopy}
                  className={'cursor-pointer opacity-60'}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      parseColor(
                        editor.getStyleForNextShape(hexColor)
                      ).toString('hex')
                    );
                  }}
                />
              </div>
              {/* Eye dropper */}
              {true && (
                <TldrawUiMenuContextProvider type='toolbar' sourceId='toolbar'>
                  <ToolbarItem tool='colorSampler'></ToolbarItem>
                </TldrawUiMenuContextProvider>
              )}
            </div>
          </div>
        </ColorField>

        <div className='flex flex-col'>
          <Label className='px-2 text-xs font-semibold opacity-50'>
            Opacité
          </Label>
          <div className='flex h-5 items-center justify-center'>
            <OpacitySlider />
          </div>
        </div>

        {/* Swatches */}
        <div className='border-y p-2'>
          <Label className='text-xs font-semibold opacity-50'>
            Dernières couleurs
          </Label>

          <div className='grid grid-cols-8 gap-1 py-4'>
            {lastColors.map((color, i) => (
              <div
                key={i}
                onClick={() => {
                  editor.setStyleForNextShapes(hexColor, color);
                  editor.setStyleForSelectedShapes(hexColor, color);
                }}>
                <ColorSwatch
                  className={
                    'aspect-square w-full cursor-pointer rounded-full border border-grey border-opacity-30'
                  }
                  color={color}
                />
              </div>
            ))}
          </div>
        </div>
      </ColorPickerContainer>
    </div>
  );
};

export default ColorPicker;
