import {
  faImages,
  faPlus,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import Button from '@/lib/Button';
import PageTitle from '../Layout/PageTitle';
import useCreateProject from '@/hooks/projects/useCreateProject';
import useGetProjects from '@/hooks/projects/useGetProjects';
import { ProjectCard } from './ProjectCard';
import { useTranslation } from 'react-i18next';
import Tabs from '@/components/lib/Tabs/Tabs';

const Projects = () => {
  const { createProject } = useCreateProject();
  const { t } = useTranslation(['dashboard']);

  const tabs = [
    {
      content: <VisibleProjects />,
      id: 'visible',
      title: t('items.projects_other'),
      icon: faImages,
    },
    {
      content: <ArchivedProjects />,
      id: 'archived',
      title: t('common.archived_other'),
      icon: faTrashCan,
    },
  ];

  return (
    <div className='flex flex-col gap-4 overflow-hidden'>
      <div className='flex items-center gap-2'>
        <PageTitle title={t('pageNames.projects')} />
        <Button
          variant={'accent'}
          size={'small'}
          round
          rightIcon={faPlus}
          onClick={() => {
            createProject();
          }}></Button>
      </div>
      <Tabs className='overflow-auto' tabs={tabs} />
    </div>
  );
};

const ArchivedProjects = () => {
  const { projects } = useGetProjects('archived');

  return (
    <div className='lg:grid-cols- grid h-full grid-cols-1 gap-4 overflow-y-auto md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
      {projects &&
        projects.map((project) => (
          <ProjectCard key={project.id} projectId={project.id} />
        ))}
    </div>
  );
};

const VisibleProjects = () => {
  const { projects } = useGetProjects('visible');

  return (
    <div className='lg:grid-cols- grid h-full grid-cols-1 gap-4 overflow-y-auto md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
      {projects &&
        projects.map((project) => (
          <ProjectCard key={project.id} projectId={project.id} />
        ))}
    </div>
  );
};

export default Projects;
