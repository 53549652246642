import { CustomLineShapeUtil } from './CustomLineTool/CustomLineShapeUtil';
import { CustomDrawShapeUtil } from './CustomDraw/CustomDrawShapeUtil';
import { CustomGeoShapeUtil } from './CustomGeo/CustomGeoShapeUtil';
import { CustomTextShapeUtil } from './CustomText/CustomTextShapeUtil';
import { CustomImageShapeUtil } from '../CustomShapeUtil/CustomImageShapeUtil';

export const customShapes = [
  CustomDrawShapeUtil,
  CustomGeoShapeUtil,
  CustomTextShapeUtil,
  CustomLineShapeUtil,
  CustomImageShapeUtil,
];

//When we directly override the default shape util (without changing the shape type name), we need to remove the default shape util from the editor
//This goes in the editor config and store config
export const UNWANTED_DEFAULT_SHAPE_TYPES = ['image'];
