import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import enTranslation from './locales/en/translation.json';
import frTranslation from './locales/fr/translation.json';

import enPanel from './locales/en/panel.json';
import frPanel from './locales/fr/panel.json';

import enLib from './locales/en/lib.json';
import frLib from './locales/fr/lib.json';

import enDashboard from './locales/en/dashboard.json';
import frDashboard from './locales/fr/dashboard.json';

import enTldraw from './locales/en/tldraw.json';
import frTldraw from './locales/fr/tldraw.json';

//TODO: when fnac is over, delete old translations
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources: {
      fr: {
        translation: frTranslation,
        panel: frPanel,
        lib: frLib,
        dashboard: frDashboard,
        tldraw: frTldraw,
      },
      en: {
        translation: enTranslation,
        panel: enPanel,
        lib: enLib,
        dashboard: enDashboard,
        tldraw: enTldraw,
      },
    },
    defaultNS: 'translation',
    fallbackNS: 'translation',
    fallbackLng: 'fr', // Use French as fallback
    supportedLngs: ['fr', 'en'],
    interpolation: { escapeValue: false },
  });

export default i18n;
