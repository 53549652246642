import { useGetWhiteboard } from '@/hooks/whiteboard/useGetWhiteboard';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../Layout/PageTitle';
import Tabs from '@/components/lib/Tabs/Tabs';
import {
  faArrowUpRightFromSquare,
  faChalkboard,
  faFolder,
  faImages,
} from '@fortawesome/free-solid-svg-icons';
import { WhiteboardImage } from './WhiteboardImage';
import InfoTag from '@/components/lib/InfoTag';
import { CreationCard, TimeTags } from '../Creations/CreationCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@/components/lib/Button';
import useGetCreations from '@/hooks/creations/useGetCreations';
import { useHasRole } from '@/hooks/useHasRole';
import { UserRole } from '@/types';
import { useTranslation } from 'react-i18next';

const WhiteboardPage = () => {
  const { whiteboardId } = useParams<{ whiteboardId: string }>();
  const hasRole = useHasRole();
  const hasProjectsRole = hasRole(UserRole.Projects);
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  const { whiteboard } = useGetWhiteboard(whiteboardId ?? '');


  const { creations } = useGetCreations(whiteboardId);

  if (!whiteboard) {
    return <PageTitle title={t("notFound.whiteboard")} />;
  }
  return (
    <div className='relative flex h-full flex-col gap-4 overflow-visible'>
      <PageTitle title={whiteboard.name} />

      <Tabs
        tabs={[
          {
            id: 'whiteboard',
            title: t('pages.whiteboard.overview'),
            icon: faChalkboard,
            content: (
              <div className='flex flex-col gap-6'>
                <div className='text-lg font-semibold'>{t('pages.whiteboard.overviewTitle')}</div>
                <div className='grid grid-cols-2 gap-10'>
                  <div className='relative aspect-[16/10]'>
                    <WhiteboardImage whiteboardId={whiteboard.id} />
                    <div className='absolute right-0 top-0 p-4'>
                      <Button
                        onClick={() => {
                          navigate(`/creator/${whiteboard.id}`);
                        }}
                        variant='accent'
                        size='small'
                        round
                        rightIcon={faArrowUpRightFromSquare}>
                        Creator
                      </Button>
                    </div>
                  </div>
                  <div className='flex flex-col gap-4'>
                    <h1 className='text-xl font-semibold'>{t('common.details')}</h1>
                    {/* PROJECT */}
                    {hasProjectsRole && (
                      <div className='flex flex-col gap-1'>
                        <h2 className='text-xs opacity-50'>{t('pages.whiteboard.inProject')}</h2>
                        <InfoTag>
                          <FontAwesomeIcon icon={faFolder} className='' />
                          {whiteboard.project?.name}
                        </InfoTag>
                      </div>
                    )}
                    {/* CREATED AT */}
                    <div className='flex flex-col gap-1'>
                      <h2 className='text-xs opacity-50'>{t('common.createdOn')}</h2>
                      <TimeTags createdAt={whiteboard.createdAt} />
                    </div>
                    {/* LAST MODIFIED */}
                    <div className='flex flex-col gap-1'>
                      <h2 className='text-xs opacity-50'>{t('pages.whiteboard.lastModified')}</h2>
                      <TimeTags createdAt={whiteboard.updatedAt} />
                    </div>
                  </div>
                </div>
              </div>
            ),
          },
          {
            id: 'creations',
            title: t('pageNames.creations'),
            icon: faImages,
            content: (
              <div className='grid grid-cols-3 gap-4 overflow-y-auto'>
                {creations.map((creation) => (
                  <CreationCard key={creation.id} creationId={creation.id} />
                ))}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default WhiteboardPage;
