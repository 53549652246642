import { StateNode, TLPointerEventInfo } from '@tldraw/editor';

export class CustomIdle extends StateNode {
  static override id = 'idle';

  override onPointerDown(info: TLPointerEventInfo) {
    this.parent.transition('customDrawing', info);
  }

  override onEnter() {
    this.editor.setCursor({ type: 'cross', rotation: 0 });
  }

  override onCancel() {
    this.editor.setCurrentTool('select');
  }
}
