import { faSignOut, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../../helpers/string';
import { UserInfo } from '../../../types/user';
import { useHasRole } from '@/hooks/useHasRole';
import { UserRole } from '@/types';
import { LanguageSelector } from '../LanguageSelector';
import { useTranslation } from 'react-i18next';
import { useIsSidebarOpen } from '../store/isSidebarOpenStore';
import { motion } from 'framer-motion';

const User = () => {
  const keycloak = useKeycloak();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const hasRole = useHasRole();
  const { t } = useTranslation('dashboard');
  const { isSidebarOpen } = useIsSidebarOpen();

  const hasProjects = hasRole(UserRole.Projects);

  useEffect(() => {
    const getUserInfo = async () => {
      const userInfo = (await keycloak.keycloak.loadUserInfo()) as UserInfo;

      setUserInfo(userInfo);
    };

    getUserInfo();
  }, []);

  if (!userInfo) return null;

  const userName = capitalizeFirstLetter(userInfo.given_name);

  return (
    <motion.div layout='position' className='flex flex-col p-2 md:p-4'>
      {isSidebarOpen && (
        <div className='self-end'>
          <LanguageSelector />
        </div>
      )}
      <div className='flex w-full items-center justify-between gap-4 rounded-2xl bg-surface-secondary p-3 px-4'>
        {isSidebarOpen && (
          <div className='flex items-center justify-center gap-2'>
            <div className='flex flex-col justify-center'>
              {/* PROJECT MANAGEMENT ACTIVATED? */}
              {hasProjects && (
                <div className='flex items-center gap-0.5 text-xs text-slate-200/60'>
                  <FontAwesomeIcon icon={faUserGroup} style={{ height: 10 }} />
                  {t('user.projectManagement')}
                </div>
              )}
              {/* USERNAME */}
              <div className=''>{userName}</div>
            </div>
          </div>
        )}

        {/* LOGOUT */}
        <div className='flex gap-4'>
          <FontAwesomeIcon
            className='cursor-pointer'
            icon={faSignOut}
            onClick={() => {
              keycloak.keycloak.logout();
            }}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default User;
