import {
  SVGContainer,
  TLDrawShape,
  TLDrawShapeProps,
  TLResizeInfo,
  TLShapeUtilCanBindOpts,
  VecLike,
  createShapePropsMigrationIds,
  createShapePropsMigrationSequence,
  drawShapeProps,
  rng,
  useEditor,
  useValue,
} from '@tldraw/editor';
import { STROKE_SIZES, TLBaseShape, DrawShapeUtil } from 'tldraw';
import { RecordProps } from 'tldraw';
import {
  getPointsFromSegments,
  getStrokePoints,
  getFreehandOptions,
  getSvgPathFromStrokePoints,
  getDrawShapeStrokeDashArray,
} from '../TldrawExports';
import { ShapeFill } from '../sharedFeatures/CustomShapeFill';
import { last } from '../../helpers/array';
import { svgInk } from '../../helpers/svgInk';
import {
  erasableShapeDefaultProps,
  ErasableShapeProps,
  erasableShapeProps,
  getEraserMask,
  resizeEraserPaths,
} from '../sharedFeatures/EraserMaskFeature';

import {
  colorableShapeDefaultProps,
  colorableShapeProps,
  ColorableShapeProps,
} from '../sharedFeatures/HexColorFeature';
import {
  freeBrushSizeShapeDefaultProps,
  FreeBrushSizeShapeProps,
  freeBrushSizeShapeProps,
} from '../sharedFeatures/FreeBrushSizeFeature';

// Defining the custom draw shape props
type TLCustomDrawShapeProps = ErasableShapeProps &
  ColorableShapeProps &
  FreeBrushSizeShapeProps &
  TLDrawShapeProps;

// Defining the custom draw shape
export type TLDrawShapeCustom = TLBaseShape<
  'drawCustom',
  TLCustomDrawShapeProps
>;

const Versions = createShapePropsMigrationIds('drawCustom', {
  AddBrushSize: 1,
  AddEraserSize: 2,
});

/* Extending the already existing DrawShape */
export class CustomDrawShapeUtil extends DrawShapeUtil {
  static override type = 'drawCustom' as 'draw'; //There's a inheritance error if we don't cast it back as draw (maybe there's a better way to do this)

  // Adding the custom properties (hexColor and eraserPaths) to the baseProps
  // baseProps is an object of validators coming from the TLDRAW validating library (https://tldraw.dev/reference/validate/T)
  // ex: T.string, T.number, T.any, etc
  static override props: RecordProps<TLDrawShapeCustom> = {
    ...drawShapeProps, //We inherit the baseProps from the DrawShape...
    //...and add the custom properties
    ...freeBrushSizeShapeProps,
    ...erasableShapeProps,
    ...colorableShapeProps,
  };

  //A migration is necessary when the shape properties change and we need to update the old shapes to the new properties
  static migrations = createShapePropsMigrationSequence({
    sequence: [
      {
        id: Versions.AddBrushSize,
        up(props) {
          // set the default color
          props.brushSize = 10;
        },
      },
      {
        id: Versions.AddEraserSize,
        up(props) {
          props.eraserPaths = [];
        },
      },
    ],
  });

  override canBind({
    toShapeType,
  }: TLShapeUtilCanBindOpts<TLDrawShapeCustom>): boolean {
    return toShapeType !== 'arrow' && toShapeType !== 'arrowCustom';
  }

  override getDefaultProps(): TLDrawShapeCustom['props'] {
    const superProps = super.getDefaultProps();
    return {
      ...superProps,
      ...colorableShapeDefaultProps,
      ...erasableShapeDefaultProps,
      ...freeBrushSizeShapeDefaultProps,
    };
  }

  //This is the function that renders the shape onto the canvas
  //We need to override it to pass it our own DrawShapeSvg component
  override component(shape: TLDrawShape) {
    return (
      <SVGContainer>
        <DrawShapeSvg shape={shape} />
      </SVGContainer>
    );
  }

  // Override toSvg function in order to apply color and eraser path
  // on export
  override toSvg(shape: TLDrawShape) {
    //ctx.addExportDef(getFillDefForExport(shape.props.fill))
    const scaleFactor = 1 / shape.props.scale;
    return (
      <g transform={`scale(${scaleFactor})`}>
        <DrawShapeSvg shape={shape} zoomOverride={1} />
      </g>
    );
  }

  //This function is called when the shape is resized
  //The super handles the resizing of the shape segments
  //We need to resize the eraser paths ourselves
  override onResize(shape: TLDrawShape, info: TLResizeInfo<TLDrawShape>) {
    // Super
    const { props } = super.onResize(shape, info);

    // Casting the shape to our custom shape
    const customShape = shape as unknown as TLDrawShapeCustom;

    const newEraserSegments = resizeEraserPaths(customShape, info);

    // Returning the new props with new eraser paths added
    return {
      props: { ...props, eraserPaths: newEraserSegments },
    };
  }
}

//This function comes from the DrawShapeUtil but it's not exported
function getDot(point: VecLike, sw: number) {
  const r = (sw + 1) * 0.5;
  return `M ${point.x} ${point.y} m -${r}, 0 a ${r},${r} 0 1,0 ${r * 2},0 a ${r},${r} 0 1,0 -${
    r * 2
  },0`;
}

//Customized component to render the shape,
// it comes from the DrawShapeUtil but it's not exported,
// and needs to be modified to handle the eraser paths
function DrawShapeSvg({
  shape,
  zoomOverride,
}: {
  shape: TLDrawShape;
  zoomOverride?: number;
}) {
  const editor = useEditor();

  //Casting the shape to our custom shape
  const customShape = {
    ...shape,
    props: {
      ...(shape.props as TLCustomDrawShapeProps),
      dash: 'draw', //Force draw dash mode
    },
  } as unknown as TLDrawShapeCustom;

  const allPointsFromSegments = getPointsFromSegments(
    customShape.props.segments
  );

  //-----------------------ORIGINAL CODE--------------------------------//
  const showAsComplete =
    customShape.props.isComplete ||
    last(customShape.props.segments)?.type === 'straight';

  let sw = (STROKE_SIZES[customShape.props.size] + 1) * customShape.props.scale;
  const forceSolid = useValue(
    'force solid',
    () => {
      const zoomLevel = zoomOverride ?? editor.getZoomLevel();
      return zoomLevel < 0.5 && zoomLevel < 1.5 / sw;
    },
    [editor, sw, zoomOverride]
  );

  const dotAdjustment = useValue(
    'dot adjustment',
    () => {
      const zoomLevel = zoomOverride ?? editor.getZoomLevel();
      // If we're zoomed way out (10%), then we need to make the dotted line go to 9 instead 0.1
      // Chrome doesn't render anything otherwise.
      return zoomLevel < 0.2 ? 0 : 0.1;
    },
    [editor, zoomOverride]
  );

  if (
    !forceSolid &&
    !customShape.props.isPen &&
    customShape.props.dash === 'draw' &&
    allPointsFromSegments.length === 1
  ) {
    sw += rng(customShape.id)() * (sw / 6);
  }

  const options = getFreehandOptions(
    customShape.props,
    customShape.props.brushSize + 1,
    showAsComplete,
    forceSolid
  );

  //---------------------END OF ORIGINAL CODE----------------------------//

  const eraserMask = getEraserMask(editor, customShape);

  //-----------------------ORIGINAL CODE--------------------------------//
  //We just insert the hexColors in the paths stroke and fill, instead of the base colors
  //and apply the mask
  //We use a custom ShapeFill that does the same.

  if (!forceSolid && customShape.props.dash === 'draw') {
    return (
      <>
        {customShape.props.isClosed &&
        customShape.props.fill === 'solid' &&
        allPointsFromSegments.length > 1 ? (
          <ShapeFill
            d={getSvgPathFromStrokePoints(
              getStrokePoints(allPointsFromSegments, options),
              customShape.props.isClosed
            )}
            fill={customShape.props.fill}
            mask={'url(#eraserMask' + customShape.id + ')'} // Edit: apply eraser mask
            color={customShape.props.hexColor} // Edit: apply custom color
          />
        ) : null}

        <path
          d={svgInk(allPointsFromSegments, options)}
          strokeLinecap='round'
          mask={'url(#eraserMask' + customShape.id + ')'} // Edit: apply eraser mask
          stroke={customShape.props.hexColor} // Edit: apply custom color
          fill={customShape.props.hexColor}
        />
        {eraserMask}
      </>
    );
  }

  const strokePoints = getStrokePoints(allPointsFromSegments, options);
  const isDot = strokePoints.length < 2;
  const solidStrokePath = isDot
    ? getDot(allPointsFromSegments[0], 0)
    : getSvgPathFromStrokePoints(strokePoints, customShape.props.isClosed);

  return (
    <>
      {customShape.props.isClosed &&
        customShape.props.fill === 'solid' &&
        allPointsFromSegments.length > 1 && (
          <ShapeFill
            d={solidStrokePath}
            fill={customShape.props.fill}
            color={customShape.props.hexColor}
            mask={'url(#eraserMask' + customShape.id + ')'} //Apply the mask
          />
        )}
      <path
        d={solidStrokePath}
        strokeLinecap='round'
        fill={isDot ? customShape.props.hexColor : 'none'}
        stroke={customShape.props.hexColor}
        strokeWidth={sw}
        strokeDasharray={
          isDot
            ? 'none'
            : getDrawShapeStrokeDashArray(
                customShape as unknown as TLDrawShape,
                sw,
                dotAdjustment
              )
        }
        strokeDashoffset='0'
      />
    </>
  );
  //---------------------END OF ORIGINAL CODE----------------------------//
}
