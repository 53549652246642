import { Dispatch, SetStateAction, useState, createContext } from 'react';

import { BeinkMode, BeinkPhase } from '../../../../types';
import { ErrorType } from '../Error';
import { Creation } from '@/types/api';

type PanelContextType = {
  isPanelOpen: boolean;
  setIsPanelOpen: Dispatch<SetStateAction<boolean>>;
  showHelp: boolean;
  setShowHelp: Dispatch<SetStateAction<boolean>>;
  error: ErrorType | null;
  setError: Dispatch<SetStateAction<ErrorType | null>>;
  currentMode: BeinkMode;
  setCurrentMode: Dispatch<SetStateAction<BeinkMode>>;
  phase: BeinkPhase;
  setPhase: Dispatch<SetStateAction<BeinkPhase>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  inProgressGeneration: Creation | null;
  setInProgressGeneration: Dispatch<SetStateAction<Creation | null>>;
  showCreateNewAlbum: boolean;
  setShowCreateNewAlbum: Dispatch<SetStateAction<boolean>>;
};

export const PanelContext = createContext<PanelContextType | undefined>(
  undefined
);

//This context should be further splitted
export const PanelProvider = ({ children }: { children: React.ReactNode }) => {
  const [showCreateNewAlbum, setShowCreateNewAlbum] = useState<boolean>(false);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentMode, setCurrentMode] = useState<BeinkMode>(BeinkMode.Imagine);
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | null>(null);
  const [inProgressGeneration, setInProgressGeneration] =
    useState<Creation | null>(null);
  const [phase, setPhase] = useState<BeinkPhase>(BeinkPhase.Generate);

  return (
    <PanelContext.Provider
      value={{
        showCreateNewAlbum,
        setShowCreateNewAlbum,
        phase,
        setPhase,
        isPanelOpen,
        setIsPanelOpen,
        showHelp,
        setShowHelp,
        error,
        setError,
        setLoading,
        loading,
        currentMode,
        setCurrentMode,
        inProgressGeneration,
        setInProgressGeneration,
      }}>
      {children}
    </PanelContext.Provider>
  );
};
