import { TLShape } from 'tldraw';
import { hexColor, HexColorStyle } from '../../CustomStyles/HexColor';

export type ColorableShape = TLShape & {
  props: {
    hexColor: HexColorStyle;
  };
};

export type ColorableShapeProps = {
  hexColor: HexColorStyle;
};

export const colorableShapeProps = {
  hexColor: hexColor,
};

export const colorableShapeDefaultProps = {
  hexColor: '#000000',
};
