import useGetProject from '@/hooks/projects/useGetProject';
import { useParams } from 'react-router-dom';
import PageTitle from '../../Layout/PageTitle';

import {
  faBookOpen,
  faChalkboard,
  faImages,
} from '@fortawesome/free-solid-svg-icons';
import { UserRole } from '@/types';
import Tabs from '@/components/lib/Tabs/Tabs';
import { useHasRole } from '@/hooks/useHasRole';
import { useTranslation } from 'react-i18next';

// Import the new tab components
import WhiteboardTab from './WhiteboardTab';
import AlbumTab from './AlbumTab';
import CreationTab from './CreationTab';

const ProjectPage = () => {
  const { projectId } = useParams();
  const { project, isError, isLoading } = useGetProject(projectId ?? '');
  const hasRole = useHasRole();
  const { t } = useTranslation("dashboard");

  const hasInspireRole = hasRole(UserRole.Inspire);

  if (!projectId) return null;

  if (isError) return <PageTitle title={t('notFound.project')} />;
  if (isLoading) return <PageTitle title={t('state.loading')} />;
  if (!project) return <PageTitle title={t('state.loading')} />;

  return (
    <div className='relative flex h-full flex-col gap-4 overflow-visible'>
      <PageTitle title={project?.name ?? t('state.loading')} />

      <Tabs
        tabs={[
          {
            id: 'whiteboards',
            title: t('pageNames.whiteboards'),
            icon: faChalkboard,
            content: <WhiteboardTab projectId={projectId} />,
          },
          hasInspireRole
            ? {
                id: 'albums',
                title: t('pageNames.albums'),
                icon: faBookOpen,
                content: <AlbumTab projectId={projectId} />,
              }
            : null,
          {
            id: 'creations',
            title: t('pageNames.creations'),
            icon: faImages,
            content: <CreationTab projectId={projectId} />,
          },
        ].filter(Boolean)}></Tabs>
    </div>
  );
};

export default ProjectPage;
