import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { ProtectedLayout } from '../helpers/ProtectedLayout';

import NotFoundPage from './NotFound';

import { useKeycloak } from '@react-keycloak/web';

import Creator from './Creator';
import Layout from '../components/Dashboard/Layout/Layout';
import Homepage from '../components/Dashboard/Homepage/Homepage';
import Projects from '../components/Dashboard/Projects/Projects';
import Whiteboards from '../components/Dashboard/Whiteboards/Whiteboards';
import Albums from '../components/Dashboard/Albums/Albums';

import { ParseKeys } from 'i18next';

import {
  faBookOpen,
  faChalkboard,
  faHome,
  faImages,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { ReactNode } from 'react';
import Creations from '../components/Dashboard/Creations/Creations';
import ProjectPage from '@/components/Dashboard/Projects/ProjectPage/ProjectPage';
import WhiteboardPage from '@/components/Dashboard/Whiteboards/WhiteboardPage';
import { UserRole } from '@/types';

export type RouteType = {
  path: string;
  icon: IconDefinition;
  label: ParseKeys<'dashboard'>;
  component: ReactNode;
  index?: boolean;
  hideInSidebar?: boolean;
  necessaryRoles?: UserRole[];
};

export const dashboardRoutes: Array<RouteType> = [
  {
    path: '/',
    icon: faHome,
    label: 'pageNames.homepage',

    component: <Homepage />,
    index: true,
  },

  {
    path: '/projects',
    icon: faImages,
    label: 'pageNames.projects',
    necessaryRoles: [UserRole.Projects],

    component: <Projects />,
  },
  {
    path: '/whiteboards',
    icon: faChalkboard,
    label: 'pageNames.whiteboards',
    /*     necessaryRoles: [UserRole.Projects], */

    component: <Whiteboards />,
  },
  {
    path: '/creation',
    icon: faImages,
    label: 'pageNames.creations',
    component: <Creations />,
  },
  {
    path: '/albums',
    icon: faBookOpen,
    label: 'pageNames.albums',
    component: <Albums />,
    necessaryRoles: [UserRole.Inspire],
  },
] as const;

const otherRoutes: Array<RouteType> = [
  {
    path: '/creator',
    icon: faChalkboard,
    label: 'pageNames.creator',
    component: <Creator />,
  },
] as const;
export const allRoutes = [...dashboardRoutes, ...otherRoutes] as const;

const router = (roles: string[]) =>
  createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<ProtectedLayout />}>
          <Route element={<Layout />}>
            {dashboardRoutes.map((route) => {
              // If the route has necessary roles, check if the user has all of them
              if (route.necessaryRoles) {
                const hasRoles = route.necessaryRoles.every((role) =>
                  roles.includes(role)
                );
                if (!hasRoles) return null;
              }

              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.component}
                />
              );
            })}
            <Route path='/projects/:projectId' element={<ProjectPage />} />
            <Route
              path='/whiteboards/:whiteboardId'
              element={<WhiteboardPage />}
            />
            <Route
              path='/projects/:projectId/whiteboard/:whiteboardId'
              element={<WhiteboardPage />}
            />

            <Route path='*' element={<NotFoundPage />} />
          </Route>
          <Route path='/creator/:whiteboardId' element={<Creator />} />

          {otherRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          ))}
        </Route>

        <Route path='*' element={<NotFoundPage />}></Route>
      </>
    )
  );
const Router = () => {
  const { initialized, keycloak } = useKeycloak();
  if (!initialized) return;
  const userRoles: string[] = keycloak.realmAccess?.roles || [];

  return <RouterProvider router={router(userRoles)} />;
};

export default Router;
