import {
  Box,
  FONT_SIZES,
  preventDefault,
  RecordProps,
  SvgTextLabel,
  TEXT_PROPS,
  textShapeProps,
  TextShapeUtil,
  TLBaseShape,
  TLPropsMigrations,
  TLShapeId,
  TLTextShape,
  TLTextShapeProps,
  useEditor,
} from 'tldraw';

import { TextHelpers } from '../TldrawExports';
import { useCallback } from 'react';
import { TextLabel } from '../sharedFeatures/TextLabel';
import {
  erasableShapeDefaultProps,
  erasableShapeProps,
  ErasableShapeProps,
} from '../sharedFeatures/EraserMaskFeature';
import {
  colorableShapeDefaultProps,
  colorableShapeProps,
  ColorableShapeProps,
} from '../sharedFeatures/HexColorFeature';

// Defining the custom text shape props
type TLCustomTextShapeProps = TLTextShapeProps &
  ErasableShapeProps &
  ColorableShapeProps;
// Defining the custom text shape
export type TLTextShapeCustom = TLBaseShape<
  'textCustom',
  TLCustomTextShapeProps
>;

///Eraser function is implemented in TextLabel but commented out
///It doesn't work well with text, because you can edit them, hence changing their size

export class CustomTextShapeUtil extends TextShapeUtil {
  static override type = 'textCustom' as 'text';

  // Adding the custom properties (hexColor and eraserPaths) to the baseProps
  // baseProps is an object of validators coming from the TLDRAW validating library (https://tldraw.dev/reference/validate/T)
  // ex: T.string, T.number, T.any, etc
  static override props: RecordProps<TLTextShapeCustom> = {
    ...textShapeProps, //We inherit the baseProps from the TextShape...
    ...erasableShapeProps,
    ...colorableShapeProps,
  };

  // Migration should be handle at some point
  // A migration is necessary when the shape properties change and we need to update the old shapes to the new properties
  static override migrations = null as unknown as TLPropsMigrations;

  getDefaultProps(): TLTextShapeCustom['props'] {
    const superProps = super.getDefaultProps();
    return {
      ...superProps,
      ...erasableShapeDefaultProps,
      ...colorableShapeDefaultProps,
    };
  }

  override component(shape: TLTextShape) {
    const customShape = shape as unknown as TLTextShapeCustom;
    const {
      id,
      props: { font, size, text, hexColor, scale, textAlign },
    } = customShape;
    const { width, height } = this.getMinDimensions(shape);
    const isSelected = customShape.id === this.editor.getOnlySelectedShapeId();
    ///const theme = useDefaultColorTheme()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleKeyDown = useTextShapeKeydownHandler(id);

    return (
      <TextLabel
        shapeId={id}
        classNamePrefix='tl-text-shape'
        type='text'
        font={font}
        fontSize={FONT_SIZES[size]}
        lineHeight={TEXT_PROPS.lineHeight}
        align={textAlign}
        verticalAlign='middle'
        text={text}
        labelColor={hexColor}
        isSelected={isSelected}
        textWidth={width}
        textHeight={height}
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
        }}
        wrap
        onKeyDown={handleKeyDown}
      />
    );
  }

  override toSvg(shape: TLTextShape) {
    /*     if (shape.props.text)
      ctx.addExportDef(getFontDefForExport(shape.props.font)); */

    const customShape = shape as unknown as TLTextShapeCustom;

    const bounds = this.editor.getShapeGeometry(shape).bounds;
    const width = bounds.width / (shape.props.scale ?? 1);
    const height = bounds.height / (shape.props.scale ?? 1);

    //const theme = getDefaultColorTheme(ctx)

    return (
      <SvgTextLabel
        fontSize={FONT_SIZES[customShape.props.size]}
        font={customShape.props.font}
        align={customShape.props.textAlign}
        verticalAlign='middle'
        text={customShape.props.text}
        labelColor={customShape.props.hexColor}
        bounds={new Box(0, 0, width, height)}
        padding={0}
      />
    );
  }

  /*  // This function is called when the shape is resized
  // The super handles the resizing of the shape segments
  // We need to resize the eraser paths ourselves
  override onResize(shape: TLTextShape, info: TLResizeInfo<TLTextShape>) {
    // Super
    const { id, type, x, y, props } = super.onResize(shape, info);
    const superProps = props as {
      autoSize: boolean;
      w: number;
      scale: number;
    };

    // Casting the shape to our custom shape
    // const customShape = shape as unknown as TLTextShapeCustom;

    // const { scaleX, scaleY } = info;

    // const newEraserSegments: TLDrawShapeSegment[] = [];
    // // TODO: mutualize this code common to other custom shapes
    // // Resizing each eraser path
    // for (const segment of customShape.props.eraserPaths) {
    // 	//Resizing each segment
    // 	newEraserSegments.push({
    // 		...segment, // Copying segment infos other than points
    // 		points: segment.points.map(({ x, y, z }) => {
    // 			return {
    // 				x: toFixed(scaleX * x),
    // 				y: toFixed(scaleY * y),
    // 				z,
    // 			};
    // 		}),
    // 	});
    // }

    return {
      id: id,
      type: type,
      x,
      y,
      props: {
        ...superProps,
        //eraserPaths: newEraserSegments
      },
    };
  } */
}

/// Copied/pasted from tldraw as used in component function
/// Unchanged code
function useTextShapeKeydownHandler(id: TLShapeId) {
  const editor = useEditor();

  return useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (editor.getEditingShapeId() !== id) return;

      switch (e.key) {
        case 'Enter': {
          if (e.ctrlKey || e.metaKey) {
            editor.complete();
          }
          break;
        }
        case 'Tab': {
          preventDefault(e);
          if (e.shiftKey) {
            TextHelpers.unindent(e.currentTarget);
          } else {
            TextHelpers.indent(e.currentTarget);
          }
          break;
        }
      }
    },
    [editor, id]
  );
}
