import { usePanelContext } from '@/components/Creator/Panel/context/usePanelContext';
import { useQuery, useQueryClient } from 'react-query';
import useGenerate from './useGenerate';
import { useEffect } from 'react';

const usePollCurrentGeneration = () => {
  const { inProgressGeneration, setInProgressGeneration, setLoading } =
    usePanelContext();
  const { getGeneration, getInProgressGeneration } = useGenerate();

  // Get current in progress generation if any
  useEffect(() => {
    getInProgressGeneration().then((generation) => {
      if (generation) setInProgressGeneration(generation);
    });
  }, []);
  const queryClient = useQueryClient();

  const inProgressGenerationQuery = useQuery({
    queryKey: ['inProgressGeneration'],

    queryFn: async () => {
      return await getGeneration(inProgressGeneration?.id as string);
    },
    enabled: inProgressGeneration !== null,
    refetchInterval: 1000,
    retry: true,
    onError: (error) => {
      console.log('Error fetching in progress generation');
      console.error(error);
      setLoading(false);
    },
    onSuccess: (data) => {
      console.log('Successfully fetched in progress generation', data);
      queryClient.invalidateQueries(['creations']);
      queryClient.invalidateQueries(['creation', data.id]);
      queryClient.invalidateQueries(['project']);

      setInProgressGeneration(null);
      setLoading(false);
    },
  });

  return {
    data: inProgressGenerationQuery.data,
    isLoading: inProgressGenerationQuery.isLoading,
    isError: inProgressGenerationQuery.isError,
  };
};

export default usePollCurrentGeneration;
