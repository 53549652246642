import { useSearch } from '@/components/lib/Dropdown/hooks/useSearch';

import useGetAlbums from '@/hooks/albums/useGetAlbums';
import useGetProjects from '@/hooks/projects/useGetProjects';

import { useGetWhiteboards } from '@/hooks/whiteboard/useGetWhiteboards';

import {
  faBolt,
  faBookOpen,
  faChalkboard,
  faFolder,
  faImages,
  faPlus,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useMemo, useState, useEffect, KeyboardEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentAlbumId } from '../store/currentAlbumIdStore';
import { useCurrentCreationId } from '../store/currentCreationIdStore';
import { allRoutes } from '@/pages/router';

import { useCreateWhiteboard } from '@/hooks/whiteboard/useCreateWhiteboard';
import useCreateProject from '@/hooks/projects/useCreateProject';
import { UserRole } from '@/types';

import { useHasRole } from '@/hooks/useHasRole';
import useGetAllCreations from '@/hooks/creations/useGetAllCreations';
import { useTranslation } from 'react-i18next';

type Action = {
  id: string;
  title: string;
  searchLabel: string;
  type: ActionType['type'];
  action: () => void;
};

type ActionType = {
  type:
    | 'creation'
    | 'album'
    | 'project'
    | 'whiteboard'
    | 'page'
    | 'create'
    | 'lastActions';
  icon: IconDefinition;
  title: string;
};

const Combobox = () => {
  const hasRole = useHasRole();
  const { t } = useTranslation('dashboard');

  const actionTypes: ActionType[] = [
    {
      type: 'creation',
      icon: faImages,
      title: t('pageNames.creations'),
    },
    {
      type: 'album',
      icon: faBookOpen,
      title: t('pageNames.albums'),
    },
    {
      type: 'project',
      icon: faFolder,
      title: t('pageNames.projects'),
    },
    {
      type: 'whiteboard',
      icon: faChalkboard,
      title: t('pageNames.whiteboards'),
    },
    {
      type: 'page',
      icon: faBolt,
      title: 'Actions',
    },
    { type: 'create', icon: faPlus, title: t('header.create') },
  ] as const;

  //Inspire role
  const hasInspireRole = hasRole(UserRole.Inspire);

  //Projects role
  const hasProjectsRole = hasRole(UserRole.Projects);

  //Get the items
  const { albums } = useGetAlbums('visible');
  const { projects } = useGetProjects();
  const { whiteboards } = useGetWhiteboards('visible');
  const { creations } = useGetAllCreations();

  const [isFocused, setIsFocused] = useState(false);

  //Actions functions
  const { setCurrentAlbumId } = useCurrentAlbumId();
  const { setCreationId } = useCurrentCreationId();
  const { createNewWhiteboard } = useCreateWhiteboard();
  const { createProject } = useCreateProject();

  const [selectedId, setSelectedId] = useState<string | null>(null);
  const navigate = useNavigate();

  const actions = useMemo<Action[]>(() => {
    let actions: Action[] = [];

    if (creations) {
      const creationActions: Action[] = creations.map((creation) => ({
        id: creation.id,
        title: creation.input,
        searchLabel: creation.input,
        type: 'creation',
        icon: faImages,
        action: () => {
          setIsFocused(false);
          setCreationId(creation.id);
        },
      }));

      actions = [...actions, ...creationActions];
    }

    if (albums && hasInspireRole) {
      const albumActions: Action[] =
        hasInspireRole && albums
          ? albums.map((album) => ({
              id: album.id,
              title: album.name,
              searchLabel: `${album.name}`,
              type: 'album',
              icon: faImages,
              action: () => {
                setIsFocused(false);
                setCurrentAlbumId(album.id);
              },
            }))
          : [];

      actions = [...actions, ...albumActions];
    }

    if (projects && hasProjectsRole) {
      const projectActions: Action[] = projects.map((project) => ({
        id: project.id,
        title: project.name,
        searchLabel: `${project.name}`,
        type: 'project',
        icon: faImages,
        action: () => {
          setIsFocused(false);
          navigate(`/projects/${project.id}`);
        },
      }));

      actions = [...actions, ...projectActions];
    }

    if (whiteboards) {
      const whiteboardActions: Action[] = whiteboards.map((whiteboard) => ({
        id: whiteboard.id,
        title: whiteboard.name,
        searchLabel: `${whiteboard.name}`,
        type: 'whiteboard',
        icon: faImages,
        action: () => {
          navigate(`/creator/${whiteboard.id}`);
        },
      }));
      actions = [...actions, ...whiteboardActions];
    }

    const navigateActions: Action[] = allRoutes
      .map((route) => {
        if (!hasRole(route.necessaryRoles)) return null;
        return {
          id: route.path,
          title: `${t('combobox.goTo')} ${t(route.label)}`,
          searchLabel: `${route.label} ${t(route.label)}`,
          type: 'page',
          icon: route.icon,
          action: () => {
            setIsFocused(false);
            navigate(route.path);
          },
        };
      })
      .filter(Boolean) as Action[];

    const createActions: Action[] = [
      {
        id: 'create-project',
        title: t('combobox.createProject'),
        searchLabel: t('combobox.createProject'),
        type: 'create',

        action: () => {
          setIsFocused(false);
          navigate('/projects');
          createProject();
        },
      },
      {
        id: 'create-whiteboard',
        title: t('combobox.createWhiteboard'),
        searchLabel: t('combobox.createWhiteboard'),
        type: 'create',

        action: () => {
          setIsFocused(false);
          navigate('/whiteboards');
          createNewWhiteboard();
        },
      },
    ];

    if (hasProjectsRole) actions = [...actions, ...createActions];

    actions = [...actions, ...navigateActions];

    return actions;
  }, [albums, projects, whiteboards]);

  const { filteredItems, searchValue, setSearchValue } = useSearch(actions, {
    keys: ['searchLabel'],
    threshold: 0.3,
  });

  const filteredItemsByType = useMemo(() => {
    return actionTypes.map((actionType) => {
      const items = filteredItems?.filter(
        (item) => item.type === actionType.type
      );
      if (!items || items.length === 0) return null;
      return {
        title: actionType.title,
        type: actionType.type,
        icon: actionType.icon,
        items,
      };
    });
  }, [filteredItems]);

  let allItems = useMemo(() => {
    return filteredItemsByType
      .filter(Boolean)
      .flatMap((category) => category!.items);
  }, [filteredItemsByType]);

  useEffect(() => {
    setSelectedId(null);
  }, [searchValue]);

  //Save executed action onto local storage array, the array is limited to 5 items
  const saveExecutedAction = (action: string) => {
    const executedActions = JSON.parse(
      localStorage.getItem('executedActions') || '[]'
    );
    //No need to save the same action twice
    if (executedActions.includes(action)) return;

    const newExecutedActions = [action, ...executedActions].slice(0, 5);
    localStorage.setItem('executedActions', JSON.stringify(newExecutedActions));
    setLastActions(newExecutedActions);
  };

  const [lastActions, setLastActions] = useState<string[]>(
    localStorage.getItem('executedActions')
      ? JSON.parse(localStorage.getItem('executedActions') || '[]')
      : []
  );

  const lastActionsItems = lastActions
    .map((action) => actions.find((a) => a.id === action))
    .filter(Boolean) as Action[];

  allItems = [...lastActionsItems, ...allItems];

  filteredItemsByType.unshift({
    title: t('combobox.recents'),
    type: 'lastActions',
    icon: faBolt,
    items: lastActionsItems,
  });

  //On pressing ctrl + k, focus the combobox
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shorcut = (e: any) => {
      if (e.ctrlKey && e.key === 'k') {
        e.preventDefault();
        setIsFocused((prev) => !prev);
        if (inputRef.current) inputRef.current.focus();
      }
    };

    window.addEventListener('keydown', shorcut);

    return () => {
      window.removeEventListener('keydown', shorcut);
    };
  }, []);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!isFocused || allItems.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!selectedId) {
          setSelectedId(allItems[0].id);
        } else {
          const currentIndex = allItems.findIndex(
            (item) => item.id === selectedId
          );
          const nextIndex = (currentIndex + 1) % allItems.length;
          setSelectedId(allItems[nextIndex].id);
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (!selectedId) {
          setSelectedId(allItems[allItems.length - 1].id);
        } else {
          const currentIndex = allItems.findIndex(
            (item) => item.id === selectedId
          );
          const nextIndex =
            (currentIndex - 1 + allItems.length) % allItems.length;
          setSelectedId(allItems[nextIndex].id);
        }
        break;
      case 'Enter':
        if (selectedId) {
          const selectedItem = allItems.find((item) => item.id === selectedId);
          saveExecutedAction(selectedItem!.id);
          selectedItem?.action();
        }
        break;
    }
  };

  return (
    <div className='relative w-full bg-surface-secondary text-white md:w-[30vw]'>
      <input
        ref={inputRef}
        className='w-full rounded-2xl bg-surface-primary px-4 py-2 text-sm'
        type='text'
        placeholder={t('combobox.placeholder')}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          setIsFocused(true);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          const timeout = setTimeout(() => {
            setIsFocused(false);
          }, 100);
          return () => clearTimeout(timeout);
        }}
        onKeyDown={handleKeyDown}
      />
      {isFocused && allItems.length > 0 && (
        <div className='backdrop-brightness-20 absolute z-100 mt-4 flex max-h-[50vh] w-full flex-col gap-3 overflow-y-auto rounded-xl p-4 text-sm text-white backdrop-blur-lg'>
          {filteredItemsByType.map((category) => {
            if (!category) return null;
            const { icon, items, title } = category;
            return (
              <div key={title} className='flex flex-col gap-2'>
                <h1 className='flex items-center gap-2 text-white'>
                  {title} ({items.length})
                </h1>
                <div className='flex flex-col gap-1 rounded-xl bg-surface-secondary p-2'>
                  {items?.map((item) => {
                    const isSelected = item.id === selectedId;
                    return (
                      <div
                        key={item.id}
                        className={`flex items-center gap-2 rounded p-2 ${
                          isSelected ? 'bg-surface-primary' : ''
                        }`}
                        onMouseEnter={() => {
                          setSelectedId(item.id);
                        }}
                        onClick={() => {
                          saveExecutedAction(item.id);
                          item.action();
                        }}>
                        <FontAwesomeIcon
                          icon={icon}
                          className={`text-white ${
                            isSelected ? '' : 'text-opacity-30'
                          }`}
                        />
                        <div className='flex cursor-pointer items-center gap-2'>
                          <span>{item.title}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Combobox;
