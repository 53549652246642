import {
  faAnglesRight,
  faHouse,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import SimpleButton from '../../lib/SimpleButton';
import { usePanelContext } from './context/usePanelContext';
import { BeinkPhase } from '../../../types';
import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';

import { useNavigate, useParams } from 'react-router-dom';

import RenamableWhiteboard from '@/components/Dashboard/Whiteboards/RenamableWhiteboard';

const PanelHeader = () => {
  const { isPanelOpen, setIsPanelOpen, setPhase } = usePanelContext();
  const { whiteboardId } = useParams();

  const navigate = useNavigate();

  if (!whiteboardId) return null;

  return (
    <motion.div
      layout
      className={twMerge(
        `pointer-events-auto sticky top-0 z-[1000] grid w-full grid-cols-[min-content_1fr_min-content] items-center justify-center gap-4 py-4 pb-6`,
        !isPanelOpen && 'grid-cols-1 justify-center'
      )}>
      {/* REDUCE BUTTON */}
      <motion.div
        layout
        className='items-centers flex justify-center'
        animate={{ rotate: isPanelOpen ? 0 : 180 }}>
        <SimpleButton
          round
          theme='flat'
          variant='tertiary'
          size='squareMD'
          iconSize='16px'
          leftIcon={isPanelOpen ? faTimes : faAnglesRight}
          onClick={() => {
            setIsPanelOpen((prev) => !prev);

            setPhase(BeinkPhase.Generate);
          }}
        />
      </motion.div>

      {isPanelOpen && (
        <RenamableWhiteboard
          className='justify-self-center'
          whiteboardId={whiteboardId}
        />
      )}

      {/* HOME BUTTON */}
      {isPanelOpen && (
        <motion.div
          layout
          onClick={() => {
            navigate('/');
          }}>
          <SimpleButton
            variant='tertiary'
            round
            iconSize='16px'
            size='squareMD'
            leftIcon={faHouse}
          />
        </motion.div>
      )}
    </motion.div>
  );
};

export default PanelHeader;
