import {
  GeoShapeGeoStyle,
  TLUiAssetUrlOverrides,
  TLUiOverrides,
  TLUiTranslationKey,
} from 'tldraw';

export const uiOverrides: TLUiOverrides = {
  tools(editor, tools) {
    tools.drawToolCustom = {
      id: 'drawToolCustom',
      icon: 'tool-pencil',
      label: 'Pencil',
      kbd: 'p',
      onSelect: () => {
        editor.setCurrentTool('drawToolCustom');
      },
    };
    tools.rectangleToolCustom = {
      id: 'rectangleToolCustom',
      icon: 'geo-rectangle',
      label: `tool.rectangle` as TLUiTranslationKey,
      meta: {
        geo: 'rectangle',
      },
      kbd: 'r',
      onSelect() {
        editor.run(() => {
          editor.setStyleForNextShapes(GeoShapeGeoStyle, 'rectangle');
          editor.setCurrentTool('rectangleToolCustom');
          //trackEvent('select-tool', { source, id: `geo-${id}` })
        });
      },
    };
    tools.arrowToolCustom = {
      id: 'arrowToolCustom',
      icon: 'geo-arrow',
      label: `tool.arrow` as TLUiTranslationKey,
      kbd: 'a',
      onSelect() {
        editor.run(() => {
          editor.setCurrentTool('arrowToolCustom');
        });
      },
    };
    tools.ellipseToolCustom = {
      id: 'ellipseToolCustom',
      icon: 'geo-ellipse',
      label: `tool.ellipse` as TLUiTranslationKey,
      meta: {
        geo: 'ellipse',
      },
      kbd: 'o',
      onSelect() {
        editor.run(() => {
          editor.setStyleForNextShapes(GeoShapeGeoStyle, 'ellipse');
          editor.setCurrentTool('ellipseToolCustom');
          //trackEvent('select-tool', { source, id: `geo-${id}` })
        });
      },
    };
    tools.customEraser = {
      id: 'customEraser',
      icon: 'tool-eraser',
      label: 'Eraser',
      kbd: 'e',
      onSelect: () => {
        editor.setCurrentTool('customEraser');
      },
    };
    tools.textToolCustom = {
      id: 'textToolCustom',
      icon: 'tool-text',
      label: 'Text',
      kbd: 't',
      onSelect: () => {
        editor.setCurrentTool('textToolCustom');
      },
    };
    tools.lineToolCustom = {
      id: 'lineToolCustom',
      icon: 'tool-line',
      label: 'Line',
      kbd: 'l',
      onSelect: () => {
        editor.setCurrentTool('lineToolCustom');
      },
    };
    tools.colorSampler = {
      id: 'colorSampler',
      icon: 'tool-colorSampler',
      label: 'Color Sampler',
      kbd: 'c',
      onSelect: () => {
        editor.setCurrentTool('colorSampler');
      },
    };

    return tools;
  },
};

export const customAssetUrls: TLUiAssetUrlOverrides = {
  icons: {
    'tool-colorSampler': '/toolIcons/eye-dropper.svg',
  },
};
