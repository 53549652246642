import {
  useRef,
  useLayoutEffect,
  useDebugValue,
  useCallback,
  useMemo,
} from 'react';
import {
  TLOnMountHandler,
  useEditor,
  Editor,
  TldrawUi,
  DEFAULT_SUPPORT_VIDEO_TYPES,
  DEFAULT_SUPPORTED_IMAGE_TYPES,
  defaultBindingUtils,
  defaultShapeTools,
  defaultShapeUtils,
  defaultTools,
  TldrawHandles,
  TldrawScribble,
  TldrawSelectionBackground,
  TldrawSelectionForeground,
  TldrawShapeIndicators,
  registerDefaultExternalContentHandlers,
  registerDefaultSideEffects,
  TLExternalContentProps,
  useEditorComponents,
  useTldrawUiComponents,
  useToasts,
  useTranslation,
  TldrawProps,
} from 'tldraw';

//This is a barebones version of the editor that allows for more customisation, different than TldrawEditor from "tldraw"
import { TldrawEditor } from '@tldraw/editor';

import { customAssetUrls, uiOverrides } from './CustomTools/UiOverrides';
import { customComponents } from './CustomComponents/CustomComponents';
import {
  customShapes,
  UNWANTED_DEFAULT_SHAPE_TYPES,
} from './CustomTools/CustomShapes';
import { customTools } from './CustomTools/CustomTools';
import { assert } from './helpers/svgInk';

type TldrawCustomEditorProps = TldrawProps;

//Exploded editor for more customisation
//We can select which default shapes/tools/etc are included
//See https://tldraw.dev/examples/editor-api/exploded
//See the Tldraw component from the library for more info
export const TldrawCustomEditor = ({
  onMount,
  store,
  initialState,
  children,
}: TldrawCustomEditorProps) => {
  const componentsWithDefault = useMemo(
    () => ({
      Scribble: TldrawScribble,
      ShapeIndicators: TldrawShapeIndicators,
      CollaboratorScribble: TldrawScribble,
      SelectionForeground: TldrawSelectionForeground,
      SelectionBackground: TldrawSelectionBackground,
      Handles: TldrawHandles,
      ...customComponents,
    }),
    []
  );

  const defaultShapeUtilsWithoutUnwanted = useMemo(
    () =>
      defaultShapeUtils.filter(
        (shapeUtil) => !UNWANTED_DEFAULT_SHAPE_TYPES.includes(shapeUtil.type)
      ),
    []
  );

  const shapeUtilsWithDefault = useMemo(
    () => [...defaultShapeUtilsWithoutUnwanted, ...customShapes],
    []
  );

  const bindingUtilsWithDefaults = useMemo(() => [...defaultBindingUtils], []);

  const toolsWithDefaults = useMemo(
    () => [...defaultTools, ...defaultShapeTools, ...customTools],
    []
  );

  const _imageMimeTypes = useMemo(() => DEFAULT_SUPPORTED_IMAGE_TYPES, []);

  const _videoMimeTypes = useMemo(() => DEFAULT_SUPPORT_VIDEO_TYPES, []);

  const mediaMimeTypes = useMemo(
    () => [..._imageMimeTypes, ..._videoMimeTypes],
    []
  );

  return (
    <TldrawEditor
      options={{
        maxPages: 1,
        maxPointsPerDrawShape: 5000,
        createTextOnCanvasDoubleClick: false,
      }}
      initialState={initialState}
      tools={toolsWithDefaults}
      shapeUtils={shapeUtilsWithDefault}
      store={store}
      bindingUtils={bindingUtilsWithDefaults}
      components={componentsWithDefault}>
      <TldrawUi
        assetUrls={customAssetUrls}
        overrides={uiOverrides}
        components={componentsWithDefault}
        mediaMimeTypes={mediaMimeTypes}>
        <InsideOfEditorAndUiContext
          maxImageDimension={5000}
          onMount={onMount}
          maxAssetSize={10 * 1024 * 1024}
          acceptedImageMimeTypes={_imageMimeTypes}
          acceptedVideoMimeTypes={_videoMimeTypes}
        />
        {children}
      </TldrawUi>
    </TldrawEditor>
  );
};

function useOnMount(onMount?: TLOnMountHandler) {
  const editor = useEditor();

  const onMountEvent = useEvent((editor: Editor) => {
    let teardown: (() => void) | void = undefined;
    // If the user wants to do something when the editor mounts, we make sure it doesn't effect the history.
    // todo: is this reeeeally what we want to do, or should we leave it up to the caller?
    editor.run(
      () => {
        teardown = onMount?.(editor);
        editor.emit('mount');
      },
      { history: 'ignore' }
    );
    window.tldrawReady = true;
    return teardown;
  });

  useLayoutEffect(() => {
    if (editor) return onMountEvent?.(editor);
  }, [editor, onMountEvent]);
}

function useEvent<Args extends Array<unknown>, Result>(
  handler: (...args: Args) => Result
): (...args: Args) => Result {
  const handlerRef = useRef<(...args: Args) => Result>();

  // In a real implementation, this would run before layout effects
  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  useDebugValue(handler);

  return useCallback((...args: Args) => {
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current;
    assert(fn, 'fn does not exist');
    return fn(...args);
  }, []);
}

// We put these hooks into a component here so that they can run inside of the context provided by TldrawEditor and TldrawUi.
function InsideOfEditorAndUiContext({
  maxImageDimension = 5000,
  maxAssetSize = 10 * 1024 * 1024, // 10mb
  acceptedImageMimeTypes = DEFAULT_SUPPORTED_IMAGE_TYPES,
  acceptedVideoMimeTypes = DEFAULT_SUPPORT_VIDEO_TYPES,
  onMount,
}: TLExternalContentProps & {
  onMount?: TLOnMountHandler;
}) {
  const editor = useEditor();
  const toasts = useToasts();
  const msg = useTranslation();

  useOnMount(() => {
    const unsubs: (void | (() => void) | undefined)[] = [];

    unsubs.push(registerDefaultSideEffects(editor));

    // for content handling, first we register the default handlers...
    registerDefaultExternalContentHandlers(
      editor,
      {
        maxImageDimension,
        maxAssetSize,
        acceptedImageMimeTypes,
        acceptedVideoMimeTypes,
      },
      {
        toasts,
        msg,
      }
    );

    // ...then we call the store's on mount which may override them...
    unsubs.push(editor.store.props.onMount(editor));

    // ...then we run the user's onMount prop, which may override things again.
    unsubs.push(onMount?.(editor));

    return () => {
      unsubs.forEach((fn) => fn?.());
    };
  });

  const { Canvas } = useEditorComponents();
  const { ContextMenu } = useTldrawUiComponents();

  if (ContextMenu) {
    // should wrap canvas
    return <ContextMenu />;
  }

  if (Canvas) {
    return <Canvas />;
  }

  return null;
}
