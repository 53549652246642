import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImages,
  faCalendar,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../../lib/Button';
import InfoTag from '../../lib/InfoTag';
import useGetCreation from '../../../hooks/creations/useGetCreation';
import { useCurrentCreationId } from '../store/currentCreationIdStore';
import { CreationImage } from './CreationImage';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
type CreationCardProps = {
  creationId: string;
};

export const CreationCard = memo(({ creationId }: CreationCardProps) => {
  const { creation, isLoading } = useGetCreation(creationId);
  const { setCreationId } = useCurrentCreationId();

  const { t } = useTranslation('dashboard');

  const imagesIds = ['0', '1', '2', '3'];

  if (isLoading) return;
  if (!creation) {
    return <div>{t('notFound.creation')}</div>;
  }

  return (
    <div className='flex flex-col gap-4 rounded-xl bg-surface-primary p-4'>
      {/* IMAGES */}

      <div className='grid grid-cols-4 gap-2'>
        {imagesIds.map((imageId, index) => {
          return (
            <CreationImage
              key={index + creationId}
              imageId={imageId}
              creationId={creationId}
            />
          );
        })}
      </div>

      {/* TITLE */}
      <div className='flex items-center gap-3'>
        <FontAwesomeIcon icon={faImages} />
        <div className=' '>{creation.input}</div>
      </div>

      <div className='flex items-center justify-between'>
        {/* DATE */}
        <div className='flex items-center gap-3 text-xs'>
          <TimeTags createdAt={creation.createdAt} />
        </div>
        <Button
          variant='primary'
          size='xs'
          className='text-xs'
          round
          onClick={() => setCreationId(creationId)}>
          {t('common.details')}
        </Button>
      </div>
    </div>
  );
});

export const TimeTags = ({ createdAt }: { createdAt: string }) => {
  const day = new Date(createdAt).toLocaleDateString(undefined, {
    month: 'numeric',
    day: 'numeric',
  });

  const hour = new Date(createdAt).toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <div className='flex items-center gap-1 text-xs'>
      <InfoTag>
        <FontAwesomeIcon icon={faCalendar} />
        {day}
      </InfoTag>
      <InfoTag>
        <FontAwesomeIcon icon={faClock} />
        {hour}
      </InfoTag>
    </div>
  );
};
