import { CreatorContext } from '../../pages/Creator';
import { motion } from 'framer-motion';
import { useCallback, useRef, useState } from 'react';

import 'tldraw/tldraw.css';

import React from 'react';

//Custom tools
import useEditorIO from '../TlDraw/hooks/useEditorIO';
import { useCollabStore } from '../TlDraw/hooks/useCollabStore';
import { setDefaultPalette } from '../TlDraw/tldrawConfig';
import { useSelectionWatcher } from '../TlDraw/hooks/useSelectionWatcher';
import { TldrawCustomEditor } from '../TlDraw/TlDrawCustomEditor';
import { Editor, useReactor } from 'tldraw';

declare global {
  interface Window {
    tldrawReady: boolean;
  }
}

setDefaultPalette();

export default function TldrawWhiteboard({
  children,
  whiteboardId,
}: {
  children: React.ReactNode;
  whiteboardId: string;
}) {
  //Editor instance (do we need both?)
  const [editor, setEditor] = useState<Editor | null>(null);
  const editorRef = useRef<Editor | null>(null); // Ref to store the latest editor instance

  //Store
  const { store, multiplayerAssets } = useCollabStore(editorRef, whiteboardId);

  //Editor IO
  const {
    exportForImagine,
    exportForInpainting,
    exportPreviewImage,
    importImageFromDOMElement,
  } = useEditorIO(editorRef, editor, whiteboardId, multiplayerAssets);

  //Selection Watcher
  const { hasImageSelected, hasDrawingSelected, hasObjectSelected } =
    useSelectionWatcher(editor);

  //TODO: refactor this
  const onChange = useCallback(() => {
    if (!editorRef.current) {
      console.debug('No editor available');
      return;
    }

    exportPreviewImage();
  }, [exportPreviewImage]);

  //save camera position to local storage
  useReactor(
    'save camera position',
    () => {
      if (!editor) return;
      const cameraPos = editor.getCamera();
      /*       console.log('cameraPos', cameraPos); */

      localStorage.setItem(
        'cameraPos' + whiteboardId,
        JSON.stringify(cameraPos)
      );
    },
    [editor]
  );

  return (
    <div className='relative grid h-dvh w-screen overflow-hidden md:grid-rows-1'>
      <TldrawCustomEditor
        initialState='select'
        store={store}
        onMount={(editorInstance) => {
          setEditor(editorInstance);
          editorRef.current = editorInstance;

          // Converting shapes to corresponding custom shapes
          const shapes = editorInstance.getCurrentPageShapesSorted();
          shapes.forEach((shape) => {
            switch (shape.type) {
              case 'draw':
                editorInstance.createShape({
                  ...shape,
                  type: 'drawCustom',
                });
                break;
              case 'geo':
                editorInstance.createShape({
                  ...shape,
                  type: 'geoCustom',
                });
                break;
              case 'text':
                editorInstance.createShape({
                  ...shape,
                  type: 'textCustom',
                });
                break;
              default:
                break;
            }
          });

          editorInstance.on('event', () => {
            onChange();
          });

          //Set to previous camera position
          const cameraPos = localStorage.getItem('cameraPos' + whiteboardId);
          if (cameraPos) {
            const cameraPosObj = JSON.parse(cameraPos);

            editorInstance.setCamera(cameraPosObj);
          }
        }}></TldrawCustomEditor>

      <motion.div
        layout
        className='pointer-events-none absolute z-[500] flex h-full w-full items-end justify-end overflow-hidden'>
        <CreatorContext.Provider
          value={{
            hasObjectSelected,
            hasImageSelected,
            hasDrawingSelected,
            importImageFromDOMElement,
            exportForImagine,
            exportForInpainting,
          }}>
          {children}
        </CreatorContext.Provider>
      </motion.div>
    </div>
  );
}
