import { StateNode, TLStateNodeConstructor } from '@tldraw/editor';

import { Pointing } from './toolStates/Pointing';
import { LineToolIdle } from 'tldraw';

/** @public */
export class CustomLineShapeTool extends StateNode {
  static override id = 'lineToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [LineToolIdle, Pointing];
  }

  override shapeType = 'lineCustom';
}
