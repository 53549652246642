import { useKeycloak } from '@react-keycloak/web';
import useAppContext from '../useAppContext';
import { dataURItoBlob } from '../../helpers/ImageManipulation';
import { usePanelContext } from '../../components/Creator/Panel/context/usePanelContext';
import { useParams } from 'react-router-dom';

const useInpaint = () => {
  const { generationsLeft } = useAppContext();
  const { keycloak } = useKeycloak();
  const { whiteboardId } = useParams<{ whiteboardId: string }>();

  const { setInProgressGeneration, setError, setLoading } = usePanelContext();

  const inpaint = async ({
    prompt,
    scribble,
    mask,
  }: {
    prompt: string;
    scribble: string;
    mask: string;
  }) => {
    if (!scribble.startsWith('data:image/png;base64,')) {
      throw new Error('Invalid scribble format');
    }

    if (!mask.startsWith('data:image/png;base64,')) {
      throw new Error('Invalid mask format');
    }

    if (!keycloak.token) {
      return setError('wrongKey');
    }

    if (generationsLeft < 1) {
      return setError('noAttemptsLeft');
    }

    setError(null);
    setLoading(true);

    const formData = new FormData();
    formData.append('prompt', prompt);
    formData.append('source', 'Webapp');
    formData.append('scribble', dataURItoBlob(scribble), 'scribble.png');
    formData.append('mask', dataURItoBlob(mask), 'mask.png');
    if (whiteboardId) formData.append('whiteboardId', whiteboardId);

    const url = new URL('/ai/inpaint', import.meta.env.VITE_API_URL);

    fetch(url, {
      body: formData,
      method: 'POST',
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then(async (res) => {
        if (!res.ok) throw res;

        const data = await res.json();
        if (!data) throw new Error('no data received from inpaint');

        // Fill with fake URLs (ExpandableCard component expects 4 URLs)
        // TODO: maybe rework ExpandableCard component in order to not need this
        data.urls = [
          'in-progress-0',
          'in-progress-1',
          'in-progress-2',
          'in-progress-3',
        ];

        setInProgressGeneration(data);
      })
      .catch((err) => {
        console.error(err);
        setError('default');
        setLoading(false);
      });
  };

  return {
    inpaint,
  };
};

export default useInpaint;
