import { CustomEraser } from './CustomEraser/CustomEraserTool';
import { CustomDrawShapeTool } from './CustomDraw/CustomDrawShapeTool';
import {
  CustomEllipseShapeTool,
  CustomRectangleShapeTool,
} from './CustomGeo/CustomGeoShapeTool';
import { CustomTextShapeTool } from './CustomText/CustomTextShapeTool';
import { ColorSampler } from './ColorSampler/ColorSampler';
import { CustomLineShapeTool } from './CustomLineTool/CustomLineShapeTool';

export const customTools = [
  CustomDrawShapeTool,
  CustomRectangleShapeTool,
  CustomEllipseShapeTool,
  CustomEraser,
  CustomTextShapeTool,
  ColorSampler,
  CustomLineShapeTool,
  /*   CustomArrowShapeTool, */
];
