import { useQuery } from 'react-query';
import { useCreationsAPI } from './useCreationsAPI';
import { Creation } from '@/types/api';

const useGetAllCreations = () => {
  const { getCreations } = useCreationsAPI();

  const { data, isLoading, isError, error } = useQuery<Creation[], Error>({
    queryKey: ['allCreations'],
    queryFn: async () => (await getCreations(1, undefined)).creations,
  });

  return {
    creations: data,

    status,
    isLoading,
    isError,
    error,
  };
};

export default useGetAllCreations;
