import {
  TLUiContextMenuProps,
  DefaultContextMenu,
  TldrawUiMenuGroup,
  EditMenuSubmenu,
  ArrangeMenuSubmenu,
  ReorderMenuSubmenu,
  ClipboardMenuGroup,
  SelectAllMenuItem,
  ConversionsMenuGroup,

} from 'tldraw';

function CustomContextMenu(props: TLUiContextMenuProps) {

  return (
    <DefaultContextMenu {...props}>
      <TldrawUiMenuGroup id="modify">
				<EditMenuSubmenu />
				<ArrangeMenuSubmenu />
				<ReorderMenuSubmenu />
			</TldrawUiMenuGroup>
      <ClipboardMenuGroup />
      <ConversionsMenuGroup />
			<TldrawUiMenuGroup id="select-all">
				<SelectAllMenuItem />
			</TldrawUiMenuGroup>
    </DefaultContextMenu>
  );
}

export default CustomContextMenu;
