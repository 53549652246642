import {
  faAngleRight,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type SidebarLinkProps = {
  to: string;
  children: React.ReactNode;
  icon: IconDefinition;
  activeSelectorId?: string;
  className?: string;
  showLabel?: boolean;
};

const SidebarLink = ({
  to,
  children,
  icon,
  activeSelectorId,
  showLabel = true,

  className,
}: SidebarLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <NavLink
      to={to}
      className={twMerge(
        'relative flex items-center justify-between gap-3 overflow-hidden rounded-xl p-3 text-sm font-medium tracking-tighter',
        className,
        !showLabel && 'justify-center'
      )}>
      <div className='flex items-center justify-center gap-3 overflow-hidden'>
        <FontAwesomeIcon icon={icon} className='z-10' />
        {showLabel && (
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: -0 }}
            exit={{ opacity: 0, x: -10 }}
            className='z-10 max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-nowrap hover:opacity-75'>
            {children}
          </motion.div>
        )}
      </div>

      {isActive && showLabel && (
        <motion.div
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: -0 }}
          exit={{ opacity: 0, x: -10 }}
          className='z-10'>
          <FontAwesomeIcon icon={faAngleRight} />
        </motion.div>
      )}

      {isActive && (
        <motion.div
          layoutId={activeSelectorId}
          style={{ borderRadius: '12px' }}
          className='absolute left-0 top-0 h-full w-full bg-surface-secondary'></motion.div>
      )}
    </NavLink>
  );
};

export default SidebarLink;
