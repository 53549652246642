import { StateNode, TLStateNodeConstructor } from '@tldraw/editor';

import { CustomDrawing } from './toolStates/Drawing';
import { CustomIdle } from './toolStates/Idle';

/** @public */
export class CustomDrawShapeTool extends StateNode {
  static override id = 'drawToolCustom';
  static override initial = 'idle';
  static override isLockable = false;
  static override children(): TLStateNodeConstructor[] {
    return [CustomIdle, CustomDrawing];
  }

  override shapeType = 'drawCustom';

  override onExit() {
    const drawingState = this.children!['customDrawing'] as CustomDrawing;
    drawingState.initialShape = undefined;
  }
}
