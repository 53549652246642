import { useQuery } from 'react-query';
import { useWhiteboardAPI } from './useWhiteboardAPI';
import { Whiteboard } from '../../types/api';

export const useGetWhiteboard = (whiteboardId: string) => {
  const { getWhiteboard } = useWhiteboardAPI();

  const { data, isLoading, isError, error, status } = useQuery<
    Whiteboard,
    Error
  >({
    queryKey: ['whiteboard', whiteboardId],
    queryFn: async () => await getWhiteboard(whiteboardId),
  });

  return {
    whiteboard: data,
    status,
    isLoading,
    isError,
    error,
  };
};
