import { uniqueId } from "tldraw";

const canvasToFile = async (
  canvas: HTMLCanvasElement,
  fileName: string = uniqueId(),
  mimeType = 'image/png'
): Promise<File> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], fileName, { type: mimeType });
        resolve(file);
      } else {
        reject(new Error('Failed to convert canvas to Blob.'));
      }
    }, mimeType);
  });
};

export default canvasToFile;
