import { ReactNode, useState } from 'react';
import Button from '../Button';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import { capitalizeFirstLetter } from '@/helpers/string';

interface TabsProps<T extends string> {
  tabs: (Tab<T> | null)[];
  className?: string;
}

export type Tab<TabId extends string> = {
  id: TabId;
  title: string;
  icon: IconDefinition;
  content: ReactNode;
};

const Tabs = <T extends string>({ tabs, className }: TabsProps<T>) => {
  const [activeTab, setActiveTab] = useState<T>(tabs[0]?.id || ('' as T));
  const currentTab = tabs.find((tab) => tab?.id === activeTab);

  return (
    <div className={twMerge('flex flex-col gap-10 overflow-hidden', className)}>
      <div className='flex gap-4'>
        {tabs.map((tab) => {
          if (!tab) return null;

          return (
            <Button
              key={tab.id}
              variant={activeTab === tab.id ? 'primary' : 'tertiary'}
              state={activeTab === tab.id ? 'active' : 'default'}
              round={true}
              size='small'
              className='text-xs'
              showLabel={activeTab === tab.id}
              onClick={() => setActiveTab(tab.id)}
              leftIcon={tab.icon}>
              {capitalizeFirstLetter(tab.title)}
            </Button>
          );
        })}
      </div>
      <div className='h-full overflow-hidden'>{currentTab?.content}</div>
    </div>
  );
};

export default Tabs;
