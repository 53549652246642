import { useQuery } from 'react-query';

import { useAlbumAPI } from './useAlbumAPI';
import { AlbumShort } from '../../types/api';
import { UserRole } from '@/types';
import { useHasRole } from '../useHasRole';

const useGetAlbums = (status: 'archived' | 'visible', projectId?: string) => {
  const { getAlbums } = useAlbumAPI();
  const hasRole = useHasRole();

  const { data, isLoading, isError, error } = useQuery<AlbumShort[], Error>({
    queryKey: ['albums', status, projectId],
    queryFn: async () => getAlbums(status, projectId),
    enabled: hasRole(UserRole.Inspire),
  });

  return {
    albums: data,
    isLoading,
    isError,
    error,
  };
};

export default useGetAlbums;
