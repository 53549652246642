import { AlreadyExistsError, useWhiteboardAPI } from "@/hooks/whiteboard/useWhiteboardAPI";
import { MutableRefObject, useMemo } from "react";
import { Editor, TLAsset, TLAssetStore } from "tldraw";

const useMultiplayerAssets = (editorRef: MutableRefObject<Editor | null>, whiteboardId: string) => {
  const { addWhiteboardImage, getWhiteboardImage } = useWhiteboardAPI();

  const uploadAsset = async (asset: TLAsset, file: File): Promise<string> => {
    let assetUuid;

    // Image import: keep the given UUID
    if (asset.meta.uuid && whiteboardId === asset.meta.whiteboardId) {
      assetUuid = asset.meta.uuid.toString();
    } else assetUuid = crypto.randomUUID();

    try {
      console.debug("upload image", assetUuid, "to wb", whiteboardId);
      await addWhiteboardImage(whiteboardId, assetUuid, file);
    } catch (err) {
      if (err instanceof AlreadyExistsError) {
        console.debug('image already exists on whiteboard');
      } else {
        throw new Error(`failed to upload image asset: ${err}`);
      }
    }

    // In case the asset already exists, use the editor to edit
    // its props
    let existingAsset = null;
    if (editorRef.current) {
      existingAsset = editorRef.current.getAsset(asset.id);
      if (existingAsset) {
        editorRef.current.updateAssets([{
          id: asset.id,
          type: asset.type,
          meta: {
            uuid: assetUuid,
            whiteboardId: whiteboardId,
          }
        }]);
      }
    }

    // In case the asset does not exist yet, directly edit
    // its props
    try {
      asset.meta.uuid = assetUuid;
      asset.meta.whiteboardId = whiteboardId;
    } catch (err) { /* ignore readonly error */ }

    // Only a placeholder as resolveAsset is used instead of this URL
    return 'http://placeholder';
  };

  const resolveAsset = async (asset: TLAsset): Promise<string> => {
    if (asset.props.src?.startsWith('data:')) {
      console.debug("data: found in props.src");
      return asset.props.src;
    }

    if (!asset.meta.uuid) {
      console.debug("no uuid found in meta");
      return '';
    }

    try {
      console.debug("download image", asset.meta.uuid, "from wb", asset.meta.whiteboardId as string || whiteboardId);
      const blob = await getWhiteboardImage(
        asset.meta.whiteboardId as string || whiteboardId,
        asset.meta.uuid as string
      );

      // Image coming from another whiteboard (copy/pasted):
      // upload it under this whiteboard
      if (asset.meta.whiteboardId?.toString() !== whiteboardId) {
        uploadAsset(asset, new File([blob], asset.meta.uuid.toString(), { type: blob.type }));
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const dataImg = reader.result?.toString() || '';
            resolve(dataImg);
          } catch (err) {
            reject(err);
          }
        };

        reader.readAsDataURL(blob);
      });
    } catch (err) {
      throw new Error(`failed to fetch image asset: ${err}`);
    }
  };

  // How does our server handle assets like images and videos?
  return useMemo<TLAssetStore>(
    () => ({
      upload: uploadAsset,
      resolve: resolveAsset,
    }),
    []
  )
};

export default useMultiplayerAssets;