import { useQueryClient, useMutation } from 'react-query';

import { useAlbumAPI } from './useAlbumAPI';

const useAddAlbumToProjects = () => {
  const queryClient = useQueryClient();
  const { addAlbumToProjects: addAlbumToProjectsAPI } = useAlbumAPI();

  const addAlbumToProjectsMutation = useMutation({
    mutationFn: async ({
      albumId,
      projectIds,
    }: {
      albumId: string;
      projectIds: string[];
    }) => {
      return await addAlbumToProjectsAPI(albumId, projectIds);
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries(['album', variables.albumId]);
      queryClient.invalidateQueries(['album', variables.albumId]);

      // Invalidate project queries
      variables.projectIds.forEach((projectId) => {
        queryClient.invalidateQueries(['project', projectId]);
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['projects']);
      queryClient.invalidateQueries(['albums']);
    },
  });

  return {
    addAlbumToProjects: (albumId: string, projectIds: string[]) =>
      addAlbumToProjectsMutation.mutate({ albumId, projectIds }),
    isLoading: addAlbumToProjectsMutation.isLoading,
    isError: addAlbumToProjectsMutation.isError,
    error: addAlbumToProjectsMutation.error,
  };
};

export default useAddAlbumToProjects;
