import { useInfiniteQuery } from 'react-query';
import { useCreationsAPI } from './useCreationsAPI';
import { Creation } from '../../types/api';

const useGetCreations = (whiteboardId?: string, limit?: number) => {
  const { getCreations } = useCreationsAPI();

  const {
    data,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<{ creations: Creation[]; nextPage: number | null }>({
    queryKey: ['creations', { whiteboardId, limit }],
    queryFn: async ({ pageParam = 1 }) =>
      await getCreations(pageParam, whiteboardId, limit),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  return {
    creations: data?.pages.flatMap((page) => page.creations) ?? [],
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useGetCreations;
