import {
  faAngleRight,
  faBookBookmark,
  faChalkboard,
  faCirclePlus,
} from '@fortawesome/free-solid-svg-icons';

import SidebarLink from '../Sidebar/SidebarLink';

import { RouteType } from '../../../pages/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { allRoutes } from '../../../pages/router';
import { Link, useLocation, useParams } from 'react-router-dom';
import useGetAlbum from '../../../hooks/albums/useGetAlbum';
import Combobox from '../Combobox/Combobox';
import Button from '@/components/lib/Button';
import useGetProject from '@/hooks/projects/useGetProject';
import { useGetWhiteboard } from '@/hooks/whiteboard/useGetWhiteboard';
import { useTranslation } from 'react-i18next';
import { ParseKeys } from 'i18next';

const Breadcrumbs = () => {
  const location = useLocation();
  const params = useParams();

  const { t } = useTranslation('dashboard');

  //Split the location along "/"
  const locationArray =
    location.pathname === '/' ? [''] : location.pathname.split('/');

  //and add back "/" to each item
  const paths = locationArray.map((item) => '/' + item);

  const computedRoutes = paths
    .map((path) => {
      return allRoutes.find((route) => route.path === path);
    })
    .filter((route) => route !== undefined) as BreadcrumbRoute[];

  //check if the page is  detail page
  const albumId = params['albumId'] ?? null;
  const projectId = params['projectId'] ?? null;
  const whiteboardId = params['whiteboardId'] ?? null;

  return (
    <div
      className={
        'flex flex-col justify-between gap-2 py-4 md:flex-row md:items-center'
      }>
      <div className=':items-center flex'>
        {computedRoutes.map((route, index) => {
          if (!route) return null;
          return (
            <Breadcrumb
              key={route.path}
              route={route}
              index={index}
              length={computedRoutes.length}
            />
          );
        })}
        {albumId && <AlbumPageBreadcrumb albumId={albumId} />}
        {projectId && <ProjectPageBreadcrumb projectId={projectId} />}
        {whiteboardId && <WhiteboardBreadcrumb whiteboardId={whiteboardId} />}
      </div>
      <Combobox />
      <Link to={'/creator'} className='hidden md:block'>
        <Button variant='accent' round={true} leftIcon={faCirclePlus}>
          {t('header.create')}
        </Button>
      </Link>
    </div>
  );
};

type BreadcrumbProps = {
  route: BreadcrumbRoute;
  index: number;
  length: number;
};

type BreadcrumbRoute =
  | (Omit<RouteType, 'component' | 'hideInSidebar' | 'index' | 'label'> & {
      label: string;
    })
  | undefined;

const Breadcrumb = ({ route, index, length }: BreadcrumbProps) => {
  const { t } = useTranslation('dashboard');
  if (!route) return null;
  return (
    <div className='flex items-center justify-center gap-1'>
      <SidebarLink
        to={route.path}
        activeSelectorId={'breadcrumb-selector'}
        icon={route.icon}
        className='max-w-[160px] gap-2 overflow-hidden text-xs'>
        {t(route.label as ParseKeys<'dashboard'>)}
      </SidebarLink>
      {index < length - 1 && length > 0 && (
        <FontAwesomeIcon icon={faAngleRight} className='text-slate-200/50' />
      )}
    </div>
  );
};

const AlbumPageBreadcrumb = ({ albumId }: { albumId: string }) => {
  const { album } = useGetAlbum(albumId);

  if (!album) return null;
  const { name } = album;

  return (
    <div className='flex items-center'>
      <FontAwesomeIcon icon={faAngleRight} className='text-slate-200/50' />
      <Breadcrumb
        route={{
          path: `/albums/${albumId}`,
          icon: faBookBookmark,
          label: name,
        }}
        index={0}
        length={1}
      />
    </div>
  );
};

const ProjectPageBreadcrumb = ({ projectId }: { projectId: string }) => {
  const { project } = useGetProject(projectId);

  if (!project) return null;
  const { name } = project;

  return (
    <div className='flex items-center'>
      <FontAwesomeIcon icon={faAngleRight} className='text-slate-200/50' />
      <Breadcrumb
        route={{
          path: `/projects/${projectId}`,
          icon: faBookBookmark,
          label: name,
        }}
        index={0}
        length={1}
      />
    </div>
  );
};

const WhiteboardBreadcrumb = ({ whiteboardId }: { whiteboardId: string }) => {
  const { whiteboard } = useGetWhiteboard(whiteboardId);

  if (!whiteboard) return null;
  const { name } = whiteboard;

  return (
    <div className='flex items-center'>
      <FontAwesomeIcon icon={faAngleRight} className='text-slate-200/50' />
      <Breadcrumb
        route={{
          path: '/whiteboards/' + whiteboardId,
          icon: faChalkboard,
          label: name,
        }}
        index={0}
        length={1}
      />
    </div>
  );
};

export default Breadcrumbs;
