import {
  faImages,
  faList,
  faTh,
  faTrashCan,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

import { useState } from 'react';
import Button from '@/lib/Button';

import AlbumGrid from './AlbumGrid';
import AlbumList from './AlbumList';
import { useSearch } from '@/lib/Dropdown/hooks/useSearch';

import useGetAlbums from '@/hooks/albums/useGetAlbums';
import PageTitle from '../Layout/PageTitle';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '@/helpers/string';

type TabId = 'visible' | 'archived';

type Tab = {
  id: TabId;
  title: string;
  icon: IconDefinition;
};

const Albums = () => {
  const [presentation, setPresentation] = useState<'grid' | 'list'>('grid');

  const [activeTab, setActiveTab] = useState<TabId>('visible');

  const { albums, error, isLoading } = useGetAlbums(activeTab);

  const { t } = useTranslation('dashboard');

  const tabs: Tab[] = [
    {
      id: 'visible',
      title: t('pageNames.albums'),
      icon: faImages,
    },

    {
      id: 'archived',
      title: capitalizeFirstLetter(t('common.archived_other')),
      icon: faTrashCan,
    },
  ];

  const { searchValue, setSearchValue, filteredItems } = useSearch(albums, {
    keys: ['name'],
    showAllOnEmptySearch: true,
  });

  if (isLoading) {
    return <p>{t('state.loading')}</p>;
  }

  if (error) {
    return <p>{t('state.error')}</p>;
  }

  if (!filteredItems) {
    return <p>{t('noItems.noAlbums')}</p>;
  }

  return (
    <div className='flex h-full flex-col gap-6 overflow-hidden text-sm'>
      <PageTitle title={t('pageNames.albums')} />
      <div className='flex flex-col justify-between gap-2 xs:flex-row'>
        {/* HEADER */}
        <div className='flex h-full basis-full gap-2 overflow-hidden text-xs'>
          {/* TABS */}
          {tabs.map((tab) => (
            <Button
              size='small'
              key={tab.id}
              variant={activeTab === tab.id ? 'primary' : 'tertiary'}
              state={activeTab === tab.id ? 'active' : 'default'}
              round={true}
              onClick={() => setActiveTab(tab.id)}
              leftIcon={tab.icon}>
              {tab.title}
            </Button>
          ))}
        </div>

        {/* PRESENTATION AND SEARCH */}
        <div className='flex items-center gap-4'>
          <div className='flex items-center gap-1'>
            <Button
              size='small'
              variant={presentation === 'grid' ? 'primary' : 'tertiary'}
              state={presentation === 'grid' ? 'active' : 'default'}
              round={true}
              onClick={() => setPresentation('grid')}
              leftIcon={faTh}></Button>
            <Button
              size='small'
              variant={presentation === 'list' ? 'primary' : 'tertiary'}
              state={presentation === 'list' ? 'active' : 'default'}
              round={true}
              onClick={() => setPresentation('list')}
              leftIcon={faList}></Button>
          </div>
          {/* SEARCH */}
          <input
            type='text'
            placeholder={t('search')}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className='h-10 w-full rounded-full bg-surface-primary px-4 text-slate-200/60'
          />
        </div>
      </div>

      {/* ALBUMS */}
      <div className='overflow-y-auto overflow-x-hidden'>
        {presentation === 'grid' && <AlbumGrid albums={filteredItems} />}
        {presentation === 'list' && <AlbumList albums={filteredItems} />}
      </div>
    </div>
  );
};

export default Albums;
