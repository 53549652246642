import { brushSize, BrushSizeStyle } from '../../CustomStyles/BrushSize';

export type FreeBrushSizeShapeProps = {
  brushSize: BrushSizeStyle;
};

export const freeBrushSizeShapeProps = {
  brushSize: brushSize,
};

export const freeBrushSizeShapeDefaultProps = {
  brushSize: 10,
};
