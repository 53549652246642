import {
  debounce,
  Editor,
  exportToBlob,
  StateNode,
  TLPointerEventInfo,
  VecLike,
} from 'tldraw';

import { hexColor } from '../../CustomStyles/HexColor';

/**
 * Draw the editor viewport to the provided canvas
 * @param ctx - The canvas context
 * @param editor - The editor instance
 * @param canvas - The canvas element
 * @returns {void}
 * */
const drawViewportToCanvas = debounce(
  async (
    ctx: CanvasRenderingContext2D | null,
    editor: Editor,
    canvas: HTMLCanvasElement
  ) => {
    console.log('drawViewportToCanvas');
    if (ctx === null) return;
    //Export all shapes in the viewport to an image, the size of the viewport
    const shapesInViewPort = editor.getRenderingShapes(); //export only the shapes in the viewport
    const pageBounds = editor.getViewportPageBounds(); //The bounds of the viewport in page space (so it crops the image to the viewport)

    console.log('bounds', shapesInViewPort);

    const blob = await exportToBlob({
      editor: editor,
      ids: shapesInViewPort.map((shape) => shape.id),
      format: 'jpeg',
      opts: {
        bounds: pageBounds,
        padding: 0,
        pixelRatio: 1,
        scale: 0.5,
      },
    });

    const image = await createImageBitmap(blob);

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);
  },
  100
);

/**
 *  A tool that samples the color of the viewport at the pointer position
 *
 * @category Custom Tools
 * @description This tool renders the whole viewport to a canvas and samples the color at the pointer's position (transformed from view space to render space). The render is updated when the viewport changes. *
 */
export class ColorSampler extends StateNode {
  static override id = 'colorSampler';
  static shapeType: 'drawCustom';

  samplerPreview = document.createElement('div'); //The sampler indicator
  canvas = document.createElement('canvas');
  ctx = this.canvas.getContext('2d', { willReadFrequently: true }); //willReadFrequently uses the cpu instead of the gpu, to avoid the lag of reading from gpu
  lastviewportPageBounds = this.editor.getViewportPageBounds(); //Initial viewport bounds

  override onCancel(): void {
    this.cleanUp();
  }

  override async onEnter() {
    console.log('ColorSampler entered');

    //TODO: use InFrontOfCanvas component to render this?
    //Like in this example https://tldraw.dev/examples/shapes/tools/screenshot-tool
    const samplerPreview = this.samplerPreview;

    samplerPreview.style.height = '30px';
    samplerPreview.style.width = '30px';
    samplerPreview.style.backgroundColor = '#000000';
    samplerPreview.style.position = 'fixed';
    samplerPreview.style.top = '0';
    samplerPreview.style.pointerEvents = 'none';
    samplerPreview.style.border = '2px solid white';
    samplerPreview.style.borderRadius = '50%';
    samplerPreview.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.5)';
    /*     samplerPreview.style.backgroundColor = '#FFFFFF'; */

    document.body.appendChild(this.samplerPreview);

    //Initial render of the viewport
    await drawViewportToCanvas(this.ctx, this.editor, this.canvas);
  }

  //Check if the viewport has moved, if so, redraw the viewport on the canvas
  override async onTick() {
    if (this.lastviewportPageBounds.equals(this.editor.getViewportPageBounds()))
      return;

    console.log('viewport changed');

    this.lastviewportPageBounds = this.editor.getViewportPageBounds();

    await drawViewportToCanvas(this.ctx, this.editor, this.canvas);
    this.updateColorPreview(this.editor.inputs.currentScreenPoint);
  }

  override async onPointerMove(info: TLPointerEventInfo): Promise<void> {
    this.updateColorPreview(info.point);
  }

  updateColorPreview(point: VecLike) {
    //Get normalized coordinates
    const normalizedX = point.x / this.editor.getContainer().clientWidth;
    const normalizedY = point.y / this.editor.getContainer().clientHeight;

    //Get the coordinates in canvas space
    const canvasX = normalizedX * this.canvas.width;
    const canvasY = normalizedY * this.canvas.height;

    this.samplerPreview.style.left = '0px';
    this.samplerPreview.style.top = '0px';

    this.samplerPreview.style.transform = `translate(${point.x}px, ${point.y}px)`; //Move the sampler to the pointer

    if (!this.ctx) return;
    const color = this.ctx.getImageData(canvasX, canvasY, 1, 1).data;
    this.samplerPreview.style.backgroundColor = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
  }

  override onPointerDown(): void {
    this.updateColorPreview(this.editor.inputs.currentScreenPoint);

    this.editor.setSelectedShapes([]); //We need to deselect all shapes to set the next color, because it interferes (the color picker looks at the color of the selected shape)

    //Setting the next color
    this.editor.setStyleForNextShapes(
      hexColor,
      this.samplerPreview.style.backgroundColor
    );

    this.cleanUp();
    this.editor.setCurrentTool('drawToolCustom');
  }

  override onExit() {
    this.cleanUp();
  }

  cleanUp() {
    //Clean up
    this.canvas.remove();
    this.samplerPreview.remove();
  }
}
