const fileToDataURL = async (file: File): Promise<string | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
  
        // When the read is complete, resolve the promise with the dataURL
        reader.onloadend = () => resolve(reader.result as string);
  
        // Handle errors
        reader.onerror = reject;
  
        // Read the file as a dataURL
        reader.readAsDataURL(file);
    });
}

export default fileToDataURL;