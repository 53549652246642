import { useMediaQuery } from '@mui/material';
import MobilePanel from './mobile/MobilePanel';

import PanelContent from './PanelContent';

import usePollCurrentGeneration from '@/hooks/generation/usePollCurrentGeneration';
import { LayoutGroup } from 'framer-motion';

function Panel() {
  const isMobile = useMediaQuery('(max-width: 729px)');

  // Fetch the generation periodically when one is in progress
  usePollCurrentGeneration();

  return isMobile ? (
    <MobilePanel />
  ) : (
    <LayoutGroup>
      <PanelContent />
    </LayoutGroup>
  );
}

export default Panel;
