import Dropdown from '../../../../lib/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { DesignStyle } from '../../../../../types';
import { usePanelInputContext } from '../../context/usePanelInputContext';
import { faPalette, faTimes } from '@fortawesome/free-solid-svg-icons';

import { DropdownElementProps, Option } from '../../../../lib/Dropdown/types/dropdown.types';
import SimpleButton from '../../../../lib/SimpleButton';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const GraphicStyleElement = ({
  isSelected,
  reset,
  value,
}: DropdownElementProps & { value: DesignStyle }) => {
  const { setGraphicStyle } = usePanelInputContext();
  const { t } = useTranslation(['panel']);
  return (
    <div className='flex items-center gap-2'>
      <div className='aspect-square rounded-md bg-white p-2'>
        <SimpleButton
          className='text-text-negative'
          size='squareXXS'
          leftIcon={isSelected ? faTimes : faPalette}
          onClick={() => {
            if (isSelected && reset) {
              setGraphicStyle(null);
              reset();
            }
          }}
        />
      </div>
      <div className=''>{t(`panel:Imagine.DesignStyle.${value}`)}</div>
    </div>
  );
};

const GraphicStyleDropdown = () => {
  const { t } = useTranslation(['panel']);
  const { whiteboardId } = useParams();
  const { setGraphicStyle } = usePanelInputContext();

  const SUGGESTIONS: Record<DesignStyle, string> = {
    [DesignStyle.Industrial]: t('panel:Imagine.DesignStyle.Industrial'),
    [DesignStyle.Line]: t('panel:Imagine.DesignStyle.Line'),
    [DesignStyle.Davinci]: t('panel:Imagine.DesignStyle.Davinci'),
    [DesignStyle.HandSketch]: t('panel:Imagine.DesignStyle.HandSketch'),
    [DesignStyle.FashionDrawing]: t('panel:Imagine.DesignStyle.FashionDrawing'),
    [DesignStyle.Scientific]: t('panel:Imagine.DesignStyle.Scientific'),
    [DesignStyle.Photorealistic]: t('panel:Imagine.DesignStyle.Photorealistic'),
  };

  const [initialStyle, setInitialStyle] = useState<Option | null>(null);
  const [styleOptions, setStyleOptions] = useState<Option[]>([]);

  useEffect(() => {
      const selectedStyleFromStorage = localStorage.getItem(`imagineSelectedStyle-${whiteboardId}`);
      if (selectedStyleFromStorage) {
        const style = selectedStyleFromStorage as DesignStyle;
        setInitialStyle({
          id: selectedStyleFromStorage,
          label: t(`panel:Imagine.DesignStyle.${style}`),

          element: (props: DropdownElementProps) => (
            <GraphicStyleElement {...props} value={style} />
          ),
        });
        setGraphicStyle(style);
      }

      setStyleOptions(Object.entries(SUGGESTIONS).map(([key, value]) => ({
        id: key,
        label: value,
        element: (props: DropdownElementProps) => (
          <GraphicStyleElement {...props} value={key as DesignStyle} />
        ),
      })));
    }, [setGraphicStyle]);

  const handleOnChange = (value: DesignStyle | null) => {
    setGraphicStyle(value);
    localStorage.setItem(`imagineSelectedStyle-${whiteboardId}`, value || '');
  }

  return (
    <div className='flex w-full flex-col gap-2'>
      <Dropdown
        initialValue={initialStyle}
        Header={() => (
          <div className='p-2 px-4 font-semibold'>
            {t('panel:Generate.graphicStyleLabel')}
          </div>
        )}
        options={styleOptions}
        icon={faPalette}
        isContentContained={false}
        placeholder={t('panel:Generate.graphicStyleLabel')}
        showSelectedWithinList={true}
        onChange={(option) => {
          handleOnChange(option ? (option.id as DesignStyle) : null)
        }}
      />
    </div>
  );
};

export default GraphicStyleDropdown;
