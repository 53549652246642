import { useCallback } from 'react';

import {
  DefaultDashStyle,
  DefaultFillStyle,
  DefaultFontStyle,
  DefaultSizeStyle,
  DefaultStylePanelContent,
  GeoStylePickerSet,
  getDefaultColorTheme,
  ReadonlySharedStyleMap,
  SplineStylePickerSet,
  TldrawUiButtonPicker,
  TldrawUiSlider,
  TLUiStylePanelProps,
  useEditor,
  useIsDarkMode,
  useRelevantStyles,
  useValue,
} from 'tldraw';
import STYLES from '../styles';
import useStyleChangeCallback from '../hooks/useStyleChangeCallback';

import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import ColorPicker from './ColorPicker';
import { Label } from 'react-aria-components';
import { hexColor } from '../CustomStyles/HexColor';
import { brushSize } from '../CustomStyles/BrushSize';

type CustomStylePanelProps = TLUiStylePanelProps;

function CustomStylePanel(props: CustomStylePanelProps) {
  if (props.isMobile) {
    return <StylePanel {...props}></StylePanel>;
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 200,
        left: 0,
      }}>
      <StylePanel {...props}></StylePanel>
    </div>
  );
}

//Coming from https://github.com/tldraw/tldraw/blob/bc462846819a33006558921b016a9e44c4a18596/packages/tldraw/src/lib/ui/components/StylePanel/DefaultStylePanel.tsx
const StylePanel = (props: CustomStylePanelProps) => {
  const styles = useRelevantStyles();
  const editor = useEditor();

  if (!styles) return null;
  if (!styles.get) return null;

  const hexColorStyle = styles.get(hexColor);

  return (
    <div
      className={classNames(
        'tlui-style-panel',
        {
          'tlui-style-panel__wrapper': !props.isMobile,
        },
        twMerge('w-auto max-w-[none]')
      )}>
      <div
        className='tlui-style-panel__section w-max'
        aria-label='style panel styles'>
        {/* If custom color styles are defined, or there's nothing selected, show our custom style panel */}
        {hexColorStyle || editor.getSelectedShapes().length === 0 ? (
          <HexColorStylePanelContent styles={styles} {...props} />
        ) : (
          <div className='w-[148px]'>
            <DefaultStylePanelContent
              styles={styles}
              {...props}></DefaultStylePanelContent>
          </div>
        )}
      </div>
    </div>
  );
};

type HexColorStylePanelContentProps = {
  styles: ReadonlySharedStyleMap | null;
} & CustomStylePanelProps;

const HexColorStylePanelContent = ({
  styles,
}: HexColorStylePanelContentProps) => {
  const handleValueChange = useStyleChangeCallback();
  const isDarkMode = useIsDarkMode();

  const editor = useEditor();

  const onHistoryMark = useCallback(
    (id: string) => editor.markHistoryStoppingPoint(id),
    [editor]
  );

  const isEraserSelected = useValue(
    'is erasor selected',
    () => editor.isIn('customEraser'),
    [editor]
  );

  if (!styles) return null;
  const fill = styles.get(DefaultFillStyle);

  const size = styles.get(DefaultSizeStyle);

  const dash = styles.get(DefaultDashStyle);

  const font = styles.get(DefaultFontStyle);

  const brushSizeStyle = styles.get(brushSize);

  const theme = getDefaultColorTheme({ isDarkMode: isDarkMode });

  const isDrawCustomShapeSelected = editor.getSelectedShapes().some((shape) => {
    return shape.type === 'drawCustom';
  });

  return (
    <>
      <ColorPicker></ColorPicker>

      <div className='flex flex-col gap-2'>
        <Label className='p-2 text-xs font-semibold opacity-50'>Style</Label>

        {fill === undefined ? null : (
          <TldrawUiButtonPicker
            title='test'
            uiType='fill'
            style={DefaultFillStyle}
            items={[STYLES.fill[0], STYLES.fill[2]]}
            value={fill}
            onValueChange={handleValueChange}
            theme={theme}
            onHistoryMark={onHistoryMark}
          />
        )}

        {brushSizeStyle === undefined ? null : (
          <div>
            <Label className='p-2 text-xs font-semibold opacity-50'>
              {isEraserSelected ? 'Taille de la gomme' : 'Taille de la brosse'}
            </Label>
            <TldrawUiSlider
              steps={40}
              value={editor.getStyleForNextShape(brushSize)}
              label={''}
              title={''}
              onValueChange={(value) => {
                handleValueChange(brushSize, value);
              }}
              onHistoryMark={onHistoryMark}
            />
          </div>
        )}

        {size === undefined || brushSizeStyle ? null : (
          <TldrawUiButtonPicker
            title='test'
            uiType='size'
            style={DefaultSizeStyle}
            items={STYLES.size}
            value={size}
            onValueChange={(style, value) => {
              handleValueChange(style, value);
            }}
            theme={theme}
            onHistoryMark={onHistoryMark}
          />
        )}

        {dash === undefined || isDrawCustomShapeSelected ? null : (
          <TldrawUiButtonPicker
            title='test'
            uiType='dash'
            style={DefaultDashStyle}
            items={STYLES.dash}
            value={dash}
            onValueChange={(style, value) => {
              handleValueChange(style, value);
            }}
            theme={theme}
            onHistoryMark={onHistoryMark}
          />
        )}

        {font === undefined ? null : (
          <TldrawUiButtonPicker
            title='test'
            uiType='font'
            style={DefaultFontStyle}
            items={STYLES.font}
            value={font}
            onValueChange={(style, value) => {
              handleValueChange(style, value);
            }}
            theme={theme}
            onHistoryMark={onHistoryMark}
          />
        )}

        <GeoStylePickerSet styles={styles}></GeoStylePickerSet>
        <SplineStylePickerSet styles={styles}></SplineStylePickerSet>
      </div>
    </>
  );
};

export default CustomStylePanel;
