import { useKeycloak } from '@react-keycloak/web';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { $generationsLeft } from '../store/generations-left';
import timeStampParam from './api';
import { Loader2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';


import Keycloak from 'keycloak-js';

import axios from 'axios';

export const useAxios = () => {
  const axiosInstance = useContext(AxiosContext);

  if (!axiosInstance) {
    throw new Error('useAxios must be used within an AxiosProvider');
  }

  return axiosInstance;
};

const createAxiosInstance = (keycloak: Keycloak) => {
  const apiInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
  });

  const collabServerInstance = axios.create({
    baseURL: import.meta.env.VITE_COLLAB_SERVER_URL,
  });

  apiInstance.interceptors.request.use(
    (config) => {
      if (keycloak.token) {
        config.headers['Authorization'] = `Bearer ${keycloak.token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  collabServerInstance.interceptors.request.use(
    (config) => {
      if (keycloak.token) {
        config.headers['Authorization'] = `Bearer ${keycloak.token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  apiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If unauthorized and not already retried, attempt to refresh token
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          await keycloak.updateToken(30); // Refresh token if it's expiring in less than 30 seconds
          originalRequest.headers['Authorization'] = `Bearer ${keycloak.token}`;
          return axios(originalRequest);
        } catch (refreshError) {
          // Token refresh failed, log out user
          keycloak.logout();
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  collabServerInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If unauthorized and not already retried, attempt to refresh token
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          await keycloak.updateToken(30); // Refresh token if it's expiring in less than 30 seconds
          originalRequest.headers['Authorization'] = `Bearer ${keycloak.token}`;
          return axios(originalRequest);
        } catch (refreshError) {
          // Token refresh failed, log out user
          keycloak.logout();
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  return {
    apiInstance,
    collabServerInstance
  }
};

const AxiosContext = createContext<
  ReturnType<typeof createAxiosInstance> | undefined
>(undefined);

export const ProtectedLayout = () => {
  // Create Axios instance with Keycloak token management

  const location = useLocation();

  const currentLocationState = location.state || { from: { pathname: '/' } };

  const { initialized, keycloak } = useKeycloak();

  const axiosInstances = useMemo(
    () => createAxiosInstance(keycloak),
    [keycloak]
  );

  const { t } = useTranslation(['panel']);

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!keycloak?.authenticated) return;

    fetch(`${import.meta.env.VITE_API_URL}/user-info${timeStampParam}`, {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return setIsError(true);
        }
        $generationsLeft.set(data.generationsLeft);

      })
      .catch(() => setIsError(true));
  }, [initialized, keycloak]);

  if (isError) {
    console.error('An error has occurred while retrieving user infos.');
  }

  if (!initialized) {
    return (
      <div className='flex h-dvh w-dvw flex-col items-center justify-center bg-grey'>
        <div className='mb-4 w-full'>
          <Loader2 className='text-primary mx-auto animate-spin' size={40} />
        </div>
        <p className='text-center text-xl'>{t('panel:Loading.title')}</p>
      </div>
    );
  }

  if (!keycloak?.authenticated) {
    keycloak?.login();
    return <Navigate to={currentLocationState.from} />;
  }

  return (
    <AxiosContext.Provider value={axiosInstances}>
      <Outlet />
    </AxiosContext.Provider>
  );
};
