import {
  CubicSpline2d,
  Polyline2d,
  SVGContainer,
  TLBaseShape,
  TLLineShape,
  TLLineShapePoint,
  TLLineShapeProps,
  Vec,
  ZERO_INDEX_KEY,
  getIndexAbove,
  getPerfectDashProps,
  lerp,
  lineShapeMigrations,
  lineShapeProps,
  sortByIndex,
} from '@tldraw/editor';

import {
  getDrawLinePathData,
  getLineDrawPath,
  LineShapeUtil,
  STROKE_SIZES,
} from 'tldraw';

import {
  colorableShapeDefaultProps,
  colorableShapeProps,
  ColorableShapeProps,
} from '../sharedFeatures/HexColorFeature';

type TLCustomLineShapeProps = TLLineShapeProps & ColorableShapeProps;
// Defining the custom geo shape
export type TLLineShapeCustom = TLBaseShape<
  'lineCustom',
  TLCustomLineShapeProps
>;

/** @public */
export class CustomLineShapeUtil extends LineShapeUtil {
  static override type = 'lineCustom' as 'line';
  static override props = { ...lineShapeProps, ...colorableShapeProps };
  static override migrations = null as unknown as typeof lineShapeMigrations;

  override getDefaultProps(): TLLineShapeCustom['props'] {
    const superProps = super.getDefaultProps();

    return {
      ...superProps,
      ...colorableShapeDefaultProps,
    };
  }

  override component(shape: TLLineShape) {
    return (
      <SVGContainer>
        <LineShapeSvg shape={shape as unknown as TLLineShapeCustom} />
      </SVGContainer>
    );
  }

  override toSvg(shape: TLLineShape) {
    return (
      <LineShapeSvg shouldScale shape={shape as unknown as TLLineShapeCustom} />
    );
  }

  override getInterpolatedProps(
    startShape: TLLineShape,
    endShape: TLLineShape,
    t: number
  ): TLLineShape['props'] {
    const startPoints = linePointsToArray(startShape);
    const endPoints = linePointsToArray(endShape);

    const pointsToUseStart: TLLineShapePoint[] = [];
    const pointsToUseEnd: TLLineShapePoint[] = [];

    let index = ZERO_INDEX_KEY;

    if (startPoints.length > endPoints.length) {
      // we'll need to expand points
      for (let i = 0; i < startPoints.length; i++) {
        pointsToUseStart[i] = { ...startPoints[i] };
        if (endPoints[i] === undefined) {
          pointsToUseEnd[i] = { ...endPoints[endPoints.length - 1], id: index };
        } else {
          pointsToUseEnd[i] = { ...endPoints[i], id: index };
        }
        index = getIndexAbove(index);
      }
    } else if (endPoints.length > startPoints.length) {
      // we'll need to converge points
      for (let i = 0; i < endPoints.length; i++) {
        pointsToUseEnd[i] = { ...endPoints[i] };
        if (startPoints[i] === undefined) {
          pointsToUseStart[i] = {
            ...startPoints[startPoints.length - 1],
            id: index,
          };
        } else {
          pointsToUseStart[i] = { ...startPoints[i], id: index };
        }
        index = getIndexAbove(index);
      }
    } else {
      // noop, easy
      for (let i = 0; i < endPoints.length; i++) {
        pointsToUseStart[i] = startPoints[i];
        pointsToUseEnd[i] = endPoints[i];
      }
    }

    return {
      ...(t > 0.5 ? endShape.props : startShape.props),
      points: Object.fromEntries(
        pointsToUseStart.map((point, i) => {
          const endPoint = pointsToUseEnd[i];
          return [
            point.id,
            {
              ...point,
              x: lerp(point.x, endPoint.x, t),
              y: lerp(point.y, endPoint.y, t),
            },
          ];
        })
      ),
      scale: lerp(startShape.props.scale, endShape.props.scale, t),
    };
  }
}

function linePointsToArray(shape: TLLineShape) {
  return Object.values(shape.props.points).sort(sortByIndex);
}

/** @public */
export function getGeometryForLineShape(
  shape: TLLineShape
): CubicSpline2d | Polyline2d {
  const points = linePointsToArray(shape).map(Vec.From);

  switch (shape.props.spline) {
    case 'cubic': {
      return new CubicSpline2d({ points });
    }
    case 'line': {
      return new Polyline2d({ points });
    }
  }
}

function LineShapeSvg({
  shape,
  shouldScale = false,
  forceSolid = false,
}: {
  shape: TLLineShapeCustom;
  shouldScale?: boolean;
  forceSolid?: boolean;
}) {
  const spline = getGeometryForLineShape(shape as unknown as TLLineShape);
  const { dash, size } = shape.props;

  const scaleFactor = 1 / shape.props.scale;

  const scale = shouldScale ? scaleFactor : 1;

  const strokeWidth = STROKE_SIZES[size] * shape.props.scale;

  // Line style lines
  if (shape.props.spline === 'line') {
    if (dash === 'solid') {
      const outline = spline.points;
      const pathData = 'M' + outline[0] + 'L' + outline.slice(1);

      return (
        <path
          d={pathData}
          stroke={shape.props.hexColor}
          strokeWidth={strokeWidth}
          fill='none'
          transform={`scale(${scale})`}
        />
      );
    }

    if (dash === 'dashed' || dash === 'dotted') {
      return (
        <g
          stroke={shape.props.hexColor}
          strokeWidth={strokeWidth}
          transform={`scale(${scale})`}>
          {spline.segments.map((segment, i) => {
            const { strokeDasharray, strokeDashoffset } = forceSolid
              ? { strokeDasharray: 'none', strokeDashoffset: 'none' }
              : getPerfectDashProps(segment.length, strokeWidth, {
                  style: dash,
                  start: i > 0 ? 'outset' : 'none',
                  end: i < spline.segments.length - 1 ? 'outset' : 'none',
                });

            return (
              <path
                key={i}
                strokeDasharray={strokeDasharray}
                strokeDashoffset={strokeDashoffset}
                d={segment.getSvgPathData(true)}
                fill='none'
              />
            );
          })}
        </g>
      );
    }

    if (dash === 'draw') {
      const outline = spline.points;
      const [, outerPathData] = getDrawLinePathData(
        shape.id,
        outline,
        strokeWidth
      );

      return (
        <path
          d={outerPathData}
          stroke={shape.props.hexColor}
          strokeWidth={strokeWidth}
          fill='none'
          transform={`scale(${scale})`}
        />
      );
    }
  }
  // Cubic style spline
  if (shape.props.spline === 'cubic') {
    const splinePath = spline.getSvgPathData();
    if (dash === 'solid') {
      return (
        <path
          strokeWidth={strokeWidth}
          stroke={shape.props.hexColor}
          fill='none'
          d={splinePath}
          transform={`scale(${scale})`}
        />
      );
    }

    if (dash === 'dashed' || dash === 'dotted') {
      return (
        <g
          stroke={shape.props.hexColor}
          strokeWidth={strokeWidth}
          transform={`scale(${scale})`}>
          {spline.segments.map((segment, i) => {
            const { strokeDasharray, strokeDashoffset } = getPerfectDashProps(
              segment.length,
              strokeWidth,
              {
                style: dash,
                start: i > 0 ? 'outset' : 'none',
                end: i < spline.segments.length - 1 ? 'outset' : 'none',
                forceSolid,
              }
            );

            return (
              <path
                key={i}
                strokeDasharray={strokeDasharray}
                strokeDashoffset={strokeDashoffset}
                d={segment.getSvgPathData()}
                fill='none'
              />
            );
          })}
        </g>
      );
    }

    if (dash === 'draw') {
      return (
        <path
          d={getLineDrawPath(shape, spline, strokeWidth)}
          strokeWidth={1}
          stroke={shape.props.hexColor}
          fill={shape.props.hexColor}
          transform={`scale(${scale})`}
        />
      );
    }
  }
}
