import { TLComponents } from 'tldraw';
import CustomContextMenu from './CustomContextMenu';
import CustomStylePanel from './CustomStylePanel';

import CustomToolbar from './CustomToolbar';

export const customComponents: TLComponents = {
  StylePanel: CustomStylePanel,
  Toolbar: CustomToolbar,
  ContextMenu: CustomContextMenu,
};
