import { useState } from 'react';

import { ExpandableCard } from './ExpandableCard';
import { usePanelContext } from '../context/usePanelContext';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import SimpleButton from '../../../lib/SimpleButton';
import { useTranslation } from 'react-i18next';
import { BeinkPhase } from '../../../../types';
import { LayoutGroup, motion } from 'framer-motion';
import useGetCreations from '@/hooks/creations/useGetCreations';
import { useParams } from 'react-router-dom';
import usePollCurrentGeneration from '@/hooks/generation/usePollCurrentGeneration';

const AllGenerations = () => {
  const { setPhase } = usePanelContext();
  const { whiteboardId } = useParams();
  const { t } = useTranslation(['panel']);

  const { creations } = useGetCreations(whiteboardId);
  const { data: currentGeneration } = usePollCurrentGeneration();

  // Définir initialement `activeCard` comme la première carte (la plus récente)
  const [activeCard, setActiveCard] = useState<number | null>(0);

  const creationAndInProgress = currentGeneration
    ? [currentGeneration, ...creations]
    : creations;

  return (
    <motion.div
      initial={{ y: '20%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: '20%', opacity: 0 }}
      transition={{ ease: 'linear', duration: 0.2 }}
      className='flex w-full flex-col rounded-2xl bg-d-surface-bg-tertiary p-2'>
      {/* Conteneur scrollable pour les cartes */}
      <motion.div
        layout
        className='flex w-full flex-grow flex-col gap-2 overflow-hidden'>
        <LayoutGroup>
          {creationAndInProgress.slice(0, 5).map((creation, index) => (
            <ExpandableCard
              key={index} // Utilise l'index comme clé unique
              id={index} // Utilise l'index comme identifiant
              initialExpand={index === 0} // Ouvre la première carte initialement
              generationId={creation.id}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
            />
          ))}
        </LayoutGroup>
      </motion.div>

      {/* Bouton de fermeture fixe en bas */}
      <motion.div
        layout
        className='sticky bottom-0 z-[1000] flex flex-col items-center justify-center p-2'>
        <SimpleButton
          onClick={() => {
            setPhase(BeinkPhase.Generate);
          }}
          round
          leftIcon={faChevronDown}
          variant='primary'
          size='xs'
          iconSize='12'
          format='rounded'>
          {t('Results.close')}
        </SimpleButton>
      </motion.div>
    </motion.div>
  );
};

export default AllGenerations;
