import { TLShape, Box, TLImageShape } from 'tldraw';

const computeImagesBounds = (selectedImages: TLShape[]): Box => {
  // Compute bounding box
  const bounds = selectedImages.reduce(
    (acc, element) => {
      const image = element as TLImageShape;
      return {
        minX: Math.min(acc.minX, image.x),
        minY: Math.min(acc.minY, image.y),
        maxX: Math.max(acc.maxX, image.x + image.props.w),
        maxY: Math.max(acc.maxY, image.y + image.props.h),
      };
    },
    {
      minX: Infinity,
      minY: Infinity,
      maxX: -Infinity,
      maxY: -Infinity,
    }
  );

  return new Box(
    bounds.minX,
    bounds.minY,
    bounds.maxX - bounds.minX,
    bounds.maxY - bounds.minY
  );
};

export default computeImagesBounds;
