import { _ReturnNull } from 'i18next';
import React from 'react';
import { TLDefaultFillStyle } from 'tldraw';

interface ShapeFillProps {
  d: string;
  fill: TLDefaultFillStyle;
  color: string;
  mask?: string;
}

export const ShapeFill = React.memo(function ShapeFill({
  d,
  fill,
  color,
  mask,
}: ShapeFillProps) {
  if (fill === 'none') return null;
  
  if (mask)
    return <path fill={color} d={d} mask={mask} />;

  return <path fill={color} d={d} />;
});
