import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';

const buttonVariants = cva(
  'text-white flex items-center content-center overflow-hidden h-min gap-2 bg-[var(--btn-color)] justify-center transition-colors',
  {
    variants: {
      variant: {
        primary: '[--btn-color:var(--button-primary-default)]',
        secondary: '[--btn-color:var(--button-secondary-default)]',
        tertiary: '[--btn-color:var(--button-tertiary-default)]',
        accent: '[--btn-color:#22c55e] bg-green-to-violet-gradient-75',
      },
      outline: {
        true: 'bg-transparent border border-[var(--btn-color)] text-[var(--btn-color)] ',
      },
      hasIcon: {
        true: '',
        false: '',
      },
      state: {
        default: 'cursor-pointer',
        active: '',
        transparent: '[--btn-color:transparent]',
        disabled: 'cursor-not-allowed text-opacity-50',
      },
      hasChildren: {
        true: '',
        false: ' px-3',
      },
      size: {
        xs: 'px-2 py-1 text-xs',
        small: 'px-4 py-3',
        medium: 'p-4',
        large: 'p-6',
      },
    },
    compoundVariants: [
      //So it stays round when there is no text
      { size: 'xs', hasChildren: false, className: 'py-2' },
      { size: 'small', hasChildren: false, className: 'px-3' },

      // Primary variants
      {
        variant: 'primary',
        state: 'active',
        className: '[--btn-color:var(--button-primary-active)]',
      },
      {
        variant: 'primary',
        state: 'disabled',
        className: '[--btn-color:var(--button-primary-disabled)]',
      },
      // Secondary variants
      {
        variant: 'secondary',
        state: 'active',
        className: '[--btn-color:var(--button-secondary-active)]',
      },
      {
        variant: 'secondary',
        state: 'disabled',
        className: '[--btn-color:var(--button-secondary-disabled)]',
      },
      // Tertiary variants
      {
        variant: 'tertiary',
        state: 'active',
        className: '[--btn-color:var(--button-tertiary-active)]',
      },
      {
        variant: 'tertiary',
        state: 'disabled',
        className: '[--btn-color:var(--button-tertiary-disabled)]',
      },
      // Accent variants
      {
        variant: 'accent',
        state: 'active',
        className: 'bg-green-to-violet-gradient',
      },
      {
        variant: 'accent',
        state: 'disabled',
        className: 'bg-opacity-25',
      },
    ],
    defaultVariants: {
      size: 'medium',
      variant: 'primary',
      outline: false,

      state: 'default',
    },
  }
);

type ButtonProps = Omit<VariantProps<typeof buttonVariants>, 'hasChildren'> & {
  leftIcon?: IconDefinition;
  rightIcon?: IconDefinition;
  children?: string | string[] | undefined;
  className?: string;
  iconSize?: string;
  showLabel?: boolean;
  round?: boolean;
  disabled?: boolean;

  onClick?: () => void;
};

const Button = ({
  children,
  variant,
  outline,
  size,

  leftIcon,
  rightIcon,
  className,
  state,
  round = false,
  iconSize = '16px',
  showLabel = true,
  onClick,
}: ButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const hasIcon = !!leftIcon || !!rightIcon;

  return (
    <motion.button
      layout
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (state !== 'disabled' && onClick) {
          onClick();
        }
      }}
      className={twMerge(
        buttonVariants({
          size,
          variant,
          outline,
          state:
            state === 'disabled' ? 'disabled' : isHovered ? 'active' : state, //if disabled, stay disabled, if not, if hovered, active, if not, default
          hasChildren: !!children && showLabel,
          hasIcon,
        }),
        className
      )}
      style={{ borderRadius: round ? '9999px' : '12px' }} //border radius needs to be set in style for framer motion to animate it properly
    >
      <AnimatePresence mode='wait' initial={false}>
        <LayoutGroup>
          {/* LEFT ICON */}
          {leftIcon && (
            <motion.div
              layout='position'
              style={{ width: iconSize, height: iconSize }}
              className='flex aspect-square items-center justify-center'>
              <FontAwesomeIcon
                style={{ width: iconSize, height: iconSize }}
                icon={leftIcon}
              />
            </motion.div>
          )}
          {/* LABEL */}
          {children && showLabel && (
            <motion.div
              layout='position'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: 'linear' }}
              style={{ lineHeight: hasIcon ? 0 : iconSize }}
              className={`flex content-center items-center justify-center text-nowrap`}>
              {children}
            </motion.div>
          )}
          {/* RIGHT ICON */}
          {rightIcon && (
            <motion.div
              layout='position'
              style={{ width: iconSize, height: iconSize }}
              className='flex aspect-square items-center justify-center'>
              <FontAwesomeIcon
                style={{ width: iconSize, height: iconSize }}
                icon={rightIcon}
              />
            </motion.div>
          )}
        </LayoutGroup>
      </AnimatePresence>
    </motion.button>
  );
};

export default Button;
