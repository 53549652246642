import { useCallback } from 'react';
import {
  TLShape,
  TLDrawShape,
  AssetRecordType,
  Editor,
  TLAssetStore,
  createShapeId,
  TLImageAsset,
  exportToBlob,
  Box,
} from 'tldraw';
import canvasToFile from '../helpers/canvasToFile';
import computeImagesBounds from '../helpers/computeImageBounds';
import { useWhiteboardAPI } from '@/hooks/whiteboard/useWhiteboardAPI';
import fileToDataURL from '../helpers/fileToDataUrl';
import { throttle } from 'lodash';

const useEditorIO = (
  editorRef: React.MutableRefObject<Editor | null>,
  editor: Editor | null,
  whiteboardId: string,
  multiplayerAssets: TLAssetStore
) => {
  const { saveWhiteboardPreviewImage } = useWhiteboardAPI();

  const exportPreviewImage = useCallback(
    throttle(async (): Promise<void> => {
      if (!editorRef.current) {
        console.debug('no editor for export preview image');
        return;
      }

      // Get all selected element IDs
      const shapes = editorRef.current.getCurrentPageShapesSorted();
      if (!shapes.length) return;

      try {
        // Use exportToBlob to generate the image Blob
        // const blob = await exportToBlob({
        //   editor: editorRef.current,
        //   format: 'image/jpeg',
        //   shapes: shapes,
        //   bounds: editorRef.current.getViewportPageBounds(),
        //   quality: 0.6,
        // });

        const blob = await exportToBlob({
          editor: editorRef.current,
          format: 'jpeg',
          ids: shapes.map((s) => s.id),
          opts: {
            padding: 0,
            pixelRatio: 1, // 2 by default,
            quality: 0.6,
            bounds: editorRef.current.getViewportScreenBounds(),
          },
        });

        if (!blob) {
          console.error('failed to generate preview image blob');
          return;
        }

        console.log(whiteboardId, blob);

        saveWhiteboardPreviewImage(whiteboardId, blob)
          .then(() => {
            console.log('Generating preview image...');
            console.debug('preview image saved');
          })
          .catch((err) => {
            console.log('Generating preview image FAIL');
            console.error(`failed to save whiteboard preview image: ${err}`);
          });
      } catch (error) {
        console.error(`error exporting preview image: ${error}`);
        return;
      }
    }, 10000),
    [whiteboardId]
  );

  const exportForImagine = useCallback(async (): Promise<string> => {
    if (!editor) {
      console.error('Editor is not initialized');
      return '';
    }

    // Get all selected element IDs
    const selecteShapes = editor.getSelectedShapes();
    if (!selecteShapes.length) {
      console.log('No elements selected');
      return '';
    }

    const bounds = editor.getSelectionPageBounds();
    if (!bounds) {
      console.error('No bounds found for selected elements');
      return '';
    }

    try {
      // Use exportToBlob to generate the image Blob
      // const blob = await exportToBlob({
      //   editor: editorRef.current,
      //   format: 'image/png',
      //   shapes: selecteShapes,
      //   bounds: bounds,
      // });

      const blob = await exportToBlob({
        editor: editor,
        format: 'png',
        ids: selecteShapes.map((s) => s.id),
        opts: {
          padding: 0,
          pixelRatio: 1, // 2 by default,
          quality: 1,
          bounds: bounds,
        },
      });

      if (!blob) {
        console.error('Failed to generate image blob');
        return '';
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const dataImg = reader.result?.toString() || '';
            resolve(dataImg);
          } catch (err) {
            reject(err);
          }
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error exporting image:', error);
      return '';
    }
  }, [editor]);

  const exportForInpainting = useCallback(async (): Promise<string[]> => {
    if (!editor) {
      console.error('Editor is not initialized');
      return [];
    }

    // Get all selected element IDs
    const selectedIds = editor.getSelectedShapeIds();

    if (selectedIds.length === 0) {
      console.error('No elements selected');
      return [];
    }

    const selectedShapes = editor.getSelectedShapes();
    const selectedImages = selectedShapes.filter((shape) => {
      return shape.type === 'image';
    });

    if (!selectedImages.length) return [];

    const bounds = computeImagesBounds(selectedImages);

    const drawings = selectedShapes
      .filter((shape) => {
        return shape.type === 'drawCustom';
      })
      .map((shape: TLShape) => {
        const draw = shape as TLDrawShape;
        // Shape is not closed, close it
        if (!draw.props.isClosed) {
          editor.updateShape({
            id: draw.id,
            type: draw.type,
            props: { isClosed: true },
          });
        }

        return draw;
      }, []);

    // Create canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return [];

    // Set canvas size
    canvas.width = bounds.w;
    canvas.height = bounds.h;

    // Get shapes position in bounding box space
    const shapesInBounds = drawings.map((shape) => {
      const x = shape.x - bounds.x;
      const y = shape.y - bounds.y;
      return { ...shape, x, y };
    });

    // Get the svg for each shape
    const svgstoDraw: SVGElement[] = [];

    for (let i = 0; i < shapesInBounds.length; i++) {
      const shape = shapesInBounds[i];
      const fillValue = (shape as TLDrawShape).props.fill;

      // Fill the drawing
      editor.updateShape({
        id: shape.id,
        type: shape.type,
        props: { fill: 'solid' },
      });

      const svg = await editor.getSvgElement([shape.id], {
        bounds: bounds,
        padding: 0,
      });

      // Reset fill value
      editor.updateShape({
        id: shape.id,
        type: shape.type,
        props: { fill: fillValue },
      });

      if (!svg) continue;
      svgstoDraw.push(svg.svg);
    }

    svgstoDraw.forEach((svg) => {
      const paths = svg.querySelectorAll('path');
      paths.forEach((path) => {
        path.setAttribute('stroke', 'white');
        path.setAttribute('fill', 'white');
      });
    });

    // convert every svg to an image
    const images = await Promise.all(
      svgstoDraw.map((svg) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.src = `data:image/svg+xml;base64,${btoa(
            new XMLSerializer().serializeToString(svg)
          )}`;
        });
      })
    );

    // draw black background
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, bounds.w, bounds.h);

    //draw the images on the canvas
    images.forEach((img) => {
      if (!ctx) return;

      ctx.drawImage(img as CanvasImageSource, 0, 0);
    });

    try {
      // Use exportToBlob to generate the image Blob
      const blob = await exportToBlob({
        editor: editor,
        format: 'png',
        ids: selectedImages.map((s) => s.id),
        opts: {
          padding: 0,
          pixelRatio: 1, // 2 by default
        },
      });

      if (!blob) {
        console.error('Failed to generate image blob');
        return [];
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const dataImg = reader.result?.toString() || '';
            resolve([dataImg, canvas.toDataURL('image/png')]);
          } catch (err) {
            reject(err);
          }
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error exporting image:', error);
      return [];
    }
  }, [editor]);

  const importImageFromDOMElement = useCallback(
    async (elementId: string) => {
      if (!editor) return;

      const imgElement = document.getElementById(elementId) as HTMLImageElement;

      if (!imgElement || imgElement.tagName !== 'IMG') {
        console.error(`No <img> element found with ID "${elementId}"`);
        return;
      }

      const imageUrl = imgElement.src;

      const img = new Image();
      img.crossOrigin = 'anonymous'; // Handle CORS
      img.src = imageUrl;

      img.onload = async () => {
        const w = img.width;
        const h = img.height;
        const canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        const imgFile = await canvasToFile(canvas);

        const assetId = AssetRecordType.createId();
        const asset = {
          id: assetId,
          type: 'image',
          typeName: 'asset',
          props: {
            name: assetId as string,
            w: w,
            h: h,
            mimeType: 'image/png',
            isAnimated: false,
            src: await fileToDataURL(imgFile),
          },
          meta: {
            uuid: crypto.randomUUID(),
            whiteboardId: whiteboardId,
          },
        } as TLImageAsset;
        editor.createAssets([asset]);

        const viewBounds = editor.getViewportPageBounds();
        const imageRatio = img.width / img.height;
        const scaledW = viewBounds.w / 3;
        const scaledH = scaledW / imageRatio;
        const { x, y } = determineImportPosition(scaledW, scaledH, editor);

        // Create image shape
        const imageId = createShapeId();
        editor.createShape({
          id: imageId,
          type: 'image',
          x: x,
          y: y,
          props: {
            assetId,
            w: scaledW,
            h: scaledH,
          },
        });

        multiplayerAssets.upload(asset, imgFile);
        editor.setCurrentTool('select');
        editor.select(imageId);
      };

      img.onerror = () => {
        alert('Failed to load the image');
      };
    },
    [editor]
  );

  return {
    exportPreviewImage,
    exportForImagine,
    exportForInpainting,
    importImageFromDOMElement,
  };
};

const determineImportPosition = (w: number, h: number, editor: Editor) => {
  const viewBounds = editor.getViewportPageBounds();

  // Try to determine the position from the position of the current
  // selection.
  // Default position on top/right of the board.
  let x = viewBounds.x + viewBounds.w / 20;
  let y = viewBounds.y + viewBounds.h / 20;
  const selectionBounds = editor.getSelectionPageBounds();
  if (selectionBounds) {
    const leftSpace = selectionBounds.x - viewBounds.x;
    const rightSpace =
      viewBounds.x +
      viewBounds.w -
      selectionBounds.x -
      selectionBounds.width -
      76; // -76 for the panel width when folded

    // Determine a position left to the selection
    let newX;

    if (leftSpace > rightSpace) {
      newX = selectionBounds.x - w - 10;
    } else {
      // Right to the selection
      newX = selectionBounds.x + selectionBounds.w + 10;
    }

    const newY = selectionBounds.y;

    // Use this position only if in the view bounds
    const zoomLevel = editor.getZoomLevel();
    if (isInBounds(newX, newY, w * zoomLevel, h * zoomLevel, viewBounds)) {
      x = newX;
      y = newY;
    }
  }

  return { x, y };
};

const isInBounds = (
  x: number,
  y: number,
  w: number,
  h: number,
  bounds: Box
): boolean => {
  return (
    x >= bounds.x &&
    x + w <= bounds.x + bounds.w &&
    y + h >= bounds.y &&
    y <= bounds.y + bounds.h
  );
};

export default useEditorIO;
