import { PanelRightOpen } from 'lucide-react';
import { PanelRightClose } from 'lucide-react';
import { useIsSidebarOpen } from '../store/isSidebarOpenStore';

const SidebarHeader = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useIsSidebarOpen();
  return (
    <div
      className={`flex items-center p-2 ${isSidebarOpen ? 'justify-between' : 'justify-center'}`}>
      {isSidebarOpen && (
        <img
          src='logo-white.svg'
          className='object-cover brightness-200 saturate-0'
          alt='logo'
        />
      )}
      {isSidebarOpen ? (
        <PanelRightOpen
          className='my-6 cursor-pointer'
          onClick={() => {
            setIsSidebarOpen((prev) => !prev);
          }}
        />
      ) : (
        <PanelRightClose
          className='my-6 cursor-pointer'
          onClick={() => {
            setIsSidebarOpen((prev) => !prev);
          }}></PanelRightClose>
      )}
    </div>
  );
};

export default SidebarHeader;
