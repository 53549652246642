import { useState, useRef } from 'react';
import Button from '@/components/lib/Button';
import { faBookOpen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AlbumShort } from '@/types/api';
import useGetAlbums from '@/hooks/albums/useGetAlbums';
import useAddAlbumToProjects from '@/hooks/albums/useAddAlbumToProjects';
import useClickOutside from '@/hooks/useClickOutside';
import Dropdown from '@/components/lib/Dropdown/Dropdown';
import { Option, DropdownElementProps } from '@/components/lib/Dropdown/types/dropdown.types';
import SimpleButton from '@/components/lib/SimpleButton';
import AlbumGrid from '../../Albums/AlbumGrid';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface AlbumTabProps {
  projectId: string;
}

const AlbumTab = ({ projectId }: AlbumTabProps) => {
  const { albums } = useGetAlbums('visible', projectId);
  const [addingAlbum, setAddingAlbum] = useState(false);
  const { t } = useTranslation("dashboard");

  if (!albums) return null;
  return (
    <div className='flex h-full w-full flex-col gap-4 overflow-hidden'>
      <div className='relative flex w-full items-center gap-4'>
        <h1>{t('pages.project.albums.inThisProject')}</h1>
        <Button
          variant='secondary'
          outline={true}
          size='xs'
          round
          onClick={() => setAddingAlbum(true)}
          leftIcon={faPlus}
        />

        {addingAlbum && (
          <div className='fixed left-0 top-0 z-100 flex h-full w-full items-center justify-center backdrop-blur-sm'>
            <div className='relative flex flex-col gap-4 rounded-xl bg-surface-secondary p-4'>
              <h1 className='text-md'>{t('pages.project.albums.selectAlbum')}</h1>
              <AlbumDropdown albums={albums} setAddingAlbum={setAddingAlbum} />
            </div>
          </div>
        )}
      </div>

      <div className='overflow-y-auto overflow-x-hidden pr-2 pt-2'>
        {albums && <AlbumGrid albums={albums} />}
      </div>
    </div>
  );
};

export default AlbumTab;

/* ALBUM DROPDOWN */

type AlbumDropdownProps = {
  albums: AlbumShort[];
  setAddingAlbum: (value: boolean) => void;
};

const AlbumDropdown = ({
  albums: projectAlbums,
  setAddingAlbum,
}: AlbumDropdownProps) => {
  const { albums } = useGetAlbums('visible');
  const { projectId } = useParams();
  const { addAlbumToProjects } = useAddAlbumToProjects();
  const ref = useRef(null);
  const { t } = useTranslation("dashboard");

  useClickOutside(ref, () => setAddingAlbum(false));

  if (!albums) return null;
  if (!projectId) return null;

  //Remove albums that are already in the project
  const albumsFiltered = albums.filter((album) => {
    return !projectAlbums?.find((projectAlbum) => projectAlbum.id === album.id);
  });

  const albumOptions: Option[] = albumsFiltered.map((album) => {
    return {
      id: album.id,
      label: album.name,
      value: album,
      element: (props) => <AlbumDropdownElement {...props} album={album} />,
    };
  });

  return (
    <div ref={ref} className='flex w-full flex-col gap-2'>
      <div className='flex h-full w-full flex-col gap-2'>
        <Dropdown
          options={albumOptions}
          search
          triggerStyles='border'
          isContentContained={false}
          icon={faBookOpen}
          showSelectedWithinList={false}
          placeholder={t('pages.project.albums.addAlbum')}
          onChange={(option) => {
            if (!option) return;
            addAlbumToProjects(option.id, [projectId]);

            setAddingAlbum(false);
          }}
          initialValue={null}
        />
      </div>
    </div>
  );
};

const AlbumDropdownElement = ({
  album,
  reset,
}: DropdownElementProps & { album: AlbumShort }) => {
  return (
    <div
      className='flex w-full items-center justify-between gap-2 text-sm'
      onClick={() => reset && reset()}>
      <div className='flex items-center gap-2'>
        <div className='aspect-square rounded-md bg-white p-2'>
          <SimpleButton
            className='text-text-negative'
            size='squareXXS'
            leftIcon={faBookOpen}
          />
        </div>
        <div>{album.name}</div>
      </div>
    </div>
  );
};
