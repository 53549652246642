import {
  faFolder,
  faTrash,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Button from '@/lib/Button';
import { TimeTags } from '../Creations/CreationCard';

import { useCreateWhiteboard } from '@/hooks/whiteboard/useCreateWhiteboard';

import useGetProject from '@/hooks/projects/useGetProject';
import useArchiveProject from '@/hooks/projects/useArchiveProject';
import useRestoreProject from '@/hooks/projects/useRestoreProject';
import RenamableProject from './RenamableProject';

import { WhiteboardImage } from '../Whiteboards/WhiteboardImage';
import { useTranslation } from 'react-i18next';

type ProjectCardProps = {
  projectId: string;
};

export const ProjectCard = ({ projectId }: ProjectCardProps) => {
  const { project } = useGetProject(projectId);

  const { t } = useTranslation('dashboard');

  //Actions
  const { createNewWhiteboard } = useCreateWhiteboard();
  const { archiveProject } = useArchiveProject();
  const { restoreProject } = useRestoreProject();

  if (!project) return null;

  const { whiteboards, createdAt, status } = project;

  // Count number of visible and archived whiteboards
  const numberOfVisibleWhiteboards = whiteboards?.reduce((acc, current) => {
    if (current.status === 'VISIBLE') return acc + 1;
    return acc;
  }, 0);
  const numberOfArchivedWhiteboards = whiteboards?.reduce((acc, current) => {
    if (current.status === 'ARCHIVED') return acc + 1;
    return acc;
  }, 0);

  return (
    <motion.div
      layout
      className='flex flex-col gap-3 rounded-xl bg-surface-primary p-4'>
      {/* TITLE */}
      <h3 className='text-md flex w-full items-center gap-2'>
        <FontAwesomeIcon icon={faFolder} />
        <div className='overflow-hidden text-ellipsis text-nowrap'>
          <RenamableProject
            projectId={projectId}
            className='gap-2 bg-transparent p-0 text-base text-white'
          />
        </div>
      </h3>

      {/* WHITEBOARD PREVIEWS */}
      <div className='grid grid-cols-2 gap-3'>
        {whiteboards
          ?.sort((a, b) => {
            const aCreatedAt = new Date(a.createdAt).getTime();
            const bCreatedAt = new Date(b.createdAt).getTime();

            return bCreatedAt - aCreatedAt;
          })
          .slice(0, 3)
          .map((whiteboard) => {
            if (whiteboard.status === 'ARCHIVED') return null;
            return (
              <Link
                to={`/creator/${whiteboard.id}`}
                key={whiteboard.id}
                target='_blank'
                rel='noopener noreferrer'
                className='aspect-[16/11] w-full cursor-pointer rounded-xl bg-surface-secondary'>
                <WhiteboardImage whiteboardId={whiteboard.id} />
              </Link>
            );
          })}
        {/* NEW WHITEBOARD */}
        <div
          className='flex aspect-[16/11] cursor-pointer items-center justify-center rounded-xl border border-info-tag/20 text-info-tag'
          onClick={() => {
            createNewWhiteboard(undefined, projectId);
          }}>
          +
        </div>
      </div>

      {/* NUMBER OF WHITEBOARDS */}
      <p className='text-sm text-gray-500'>
        {`${numberOfVisibleWhiteboards ?? 0} ${t('items.whiteboards', { count: numberOfVisibleWhiteboards ?? 0 })} (${numberOfArchivedWhiteboards ?? 0} ${t('common.archived', { count: numberOfArchivedWhiteboards ?? 0 })})`}
      </p>

      <div className='flex basis-full items-end justify-between'>
        <TimeTags createdAt={createdAt} />
        <div className='flex items-center gap-3'>
          {/* RESTORE / ARCHIVE */}
          <Button
            variant='secondary'
            size='xs'
            iconSize='10px'
            round
            leftIcon={status === 'VISIBLE' ? faTrash : faTrashRestore}
            onClick={() =>
              status === 'VISIBLE'
                ? archiveProject(projectId)
                : restoreProject(projectId)
            }></Button>
          {/* OPEN PROJECT PAGE */}
          <Link to={`/projects/${projectId}`}>
            <Button variant='secondary' size='xs' round className='text-xs'>
              {t('common.details')}
            </Button>
          </Link>
        </div>
      </div>
    </motion.div>
  );
};
