import { StateNode, TLStateNodeConstructor } from '@tldraw/editor'
import { TextIdle } from '../TldrawExports'
import { Pointing } from './toolStates/Pointing'

/** @public */
export class CustomTextShapeTool extends StateNode {
	static override id = 'textToolCustom'
	static override initial = 'idle'
	static override children(): TLStateNodeConstructor[] {
		return [TextIdle, Pointing]
	}
	override shapeType = 'textCustom'
}
