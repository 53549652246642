import { useOutlet } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import PageAnimationWrapper from './PageAnimationWrapper';
import Breadcrumbs from './PageHeader';
import Sidepanel from '../../lib/Containers/Sidepanel';
import { CreationView } from '../Creations/CreationView';
import {
  useCurrentCreationId,
  useCurrentProjectId,
} from '../store/currentCreationIdStore';
import { useCurrentAlbumId } from '../store/currentAlbumIdStore';
import AlbumView from '../Albums/AlbumView';
import { ProjectView } from '../Projects/ProjectView';

const Layout = () => {
  const location = useLocation();
  const element = useOutlet(); //We use the hook instead of component for the animation to work
  const { creationId, setCreationId } = useCurrentCreationId();
  const { currentAlbumId, setCurrentAlbumId } = useCurrentAlbumId();
  const { currentProjectId, setCurrentProjectId } = useCurrentProjectId();

  return (
    <div className='relative grid h-dvh w-full grid-cols-[min-content_1fr]'>
      <LayoutGroup>
        <Sidebar />
        <motion.div
          layout='position'
          className='relative flex h-full w-full flex-col overflow-hidden bg-surface-secondary p-2 md:p-6'>
          <AnimatePresence mode='wait'>
            {creationId && (
              <Sidepanel
                closeFn={() => {
                  setCreationId(null);
                }}>
                <CreationView />
              </Sidepanel>
            )}

            {currentAlbumId && (
              <Sidepanel
                closeFn={() => {
                  setCurrentAlbumId(null);
                }}>
                <AlbumView />
              </Sidepanel>
            )}

            {currentProjectId && (
              <Sidepanel
                closeFn={() => {
                  setCurrentProjectId(null);
                }}>
                <ProjectView />
              </Sidepanel>
            )}
          </AnimatePresence>

          <Breadcrumbs />

          <AnimatePresence mode='wait' initial={false}>
            <PageAnimationWrapper key={location.pathname}>
              {element && element}
            </PageAnimationWrapper>
          </AnimatePresence>
        </motion.div>
      </LayoutGroup>
    </div>
  );
};

export default Layout;
