import { Box, Vec, VecModel } from "tldraw";

const isPointInBounds = (point: Vec | VecModel, bounds: Box | null | undefined, padding: number = 0): boolean => {
    if (!bounds) return false;
    
    return (point.x + padding >= bounds.x) &&
        (point.y + padding >= bounds.y) &&
        (point.x - padding <= (bounds.x + bounds.w)) &&
        (point.y - padding <= (bounds.y + bounds.h));
}

export default isPointInBounds;