import useGetProject from '@/hooks/projects/useGetProject';
import useGetCreations from '@/hooks/creations/useGetCreations';
import { CreationCard } from '../../Creations/CreationCard';
import { useTranslation } from 'react-i18next';

interface AlbumTabProps {
  projectId: string;
}

const CreationTab = ({ projectId }: AlbumTabProps) => {
  const { t } = useTranslation("dashboard");
  //get all the whiteboards in the project
  const { project } = useGetProject(projectId);

  if (!project) return null;
  const { whiteboards } = project;

  //map over the whiteboards and get all the creations
  return (
    <div className='flex flex-col gap-4 overflow-hidden'>
      <h1 className='text-lg'>{t('pages.project.creations.inThisProject')}</h1>
      <div className='grid h-full grid-cols-4 gap-3 overflow-y-auto'>
        {whiteboards?.map((whiteboard) => {
          return (
            <WhiteboardCreations
              key={whiteboard.id}
              whiteboardId={whiteboard.id}
            />
          );
        })}
      </div>
    </div>
  );
};

const WhiteboardCreations = ({ whiteboardId }: { whiteboardId: string }) => {
  const { creations } = useGetCreations(whiteboardId);
  console.log(creations);
  if (!creations) return null;
  return (
    <>
      {creations.map((creation) => (
        <CreationCard key={creation.id} creationId={creation.id} />
      ))}
    </>
  );
};

export default CreationTab;
