import { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Button from '@/components/lib/Button';
import { faChalkboard, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Whiteboard, WhiteboardShort } from '@/types/api';
import useGetProject from '@/hooks/projects/useGetProject';
import { useCreateWhiteboard } from '@/hooks/whiteboard/useCreateWhiteboard';
import useClickOutside from '@/hooks/useClickOutside';
import { useGetWhiteboards } from '@/hooks/whiteboard/useGetWhiteboards';
import Dropdown from '@/components/lib/Dropdown/Dropdown';
import {
  Option,
  DropdownElementProps,
} from '@/components/lib/Dropdown/types/dropdown.types';
import SimpleButton from '@/components/lib/SimpleButton';
import { WhiteboardCard } from '../../Whiteboards/WhiteboardCard';
import { useParams } from 'react-router-dom';
import useAddWhiteboardToProject from '@/hooks/whiteboard/useAddWhiteboardToProject';
import { useTranslation } from 'react-i18next';

interface AlbumTabProps {
  projectId: string;
}

const WhiteboardTab = ({ projectId }: AlbumTabProps) => {
  const { project } = useGetProject(projectId);
  const [addingWhiteboard, setAddingWhiteboard] = useState(false);
  const { t } = useTranslation('dashboard');

  if (!project) return null;
  const { whiteboards } = project;

  const numberOfVisibleWhiteboards = whiteboards?.reduce((acc, current) => {
    if (current.status === 'VISIBLE') return acc + 1;
    return acc;
  }, 0);
  const numberOfArchivedWhiteboards = whiteboards?.reduce((acc, current) => {
    if (current.status === 'ARCHIVED') return acc + 1;
    return acc;
  }, 0);

  return (
    <motion.div layout className='flex h-full flex-col gap-3 overflow-hidden'>
      <div className='relative flex w-full items-center gap-4'>
        <h1 className='text-lg'>
          {`${numberOfVisibleWhiteboards ?? 0} ${t('items.whiteboards', { count: numberOfVisibleWhiteboards ?? 0 })} (${numberOfArchivedWhiteboards ?? 0} ${t('common.archived', { count: numberOfArchivedWhiteboards ?? 0 })})`}
        </h1>
        <Button
          variant='secondary'
          outline={true}
          size='xs'
          round
          onClick={() => setAddingWhiteboard(true)}
          leftIcon={faPlus}
        />

        <AddWhiteboardModal
          isOpen={addingWhiteboard}
          onClose={() => setAddingWhiteboard(false)}
          projectId={projectId}
          existingWhiteboards={whiteboards}
        />
      </div>

      <div className='grid grid-cols-4 gap-3 overflow-y-auto'>
        {whiteboards
          ?.sort((a, b) => {
            const aCreatedAt = new Date(a.updatedAt).getTime();
            const bCreatedAt = new Date(b.updatedAt).getTime();

            return bCreatedAt - aCreatedAt;
          })
          .map((whiteboard) => {
            if (whiteboard.status === 'ARCHIVED') return null;
            return <WhiteboardCard whiteboardId={whiteboard.id} />;
          })}
      </div>
    </motion.div>
  );
};

interface AddWhiteboardModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  existingWhiteboards?: Whiteboard[];
}

const AddWhiteboardModal = ({
  isOpen,
  onClose,
  projectId,
  existingWhiteboards,
}: AddWhiteboardModalProps) => {
  const { createNewWhiteboard } = useCreateWhiteboard();
  const ref = useRef(null);
  const { t } = useTranslation('dashboard');

  useClickOutside(ref, onClose);

  if (!isOpen) return null;

  return (
    <div className='fixed left-0 top-0 z-100 flex h-full w-full items-center justify-center backdrop-blur-sm'>
      <div
        ref={ref}
        className='relative flex flex-col gap-4 rounded-xl bg-surface-secondary p-4'>
        <h1 className='text-md'>
          {t('pages.project.whiteboards.addWhiteboard')}
        </h1>
        <WhiteboardDropdown
          whiteboards={existingWhiteboards}
          setAddingWhiteboard={onClose}
        />
        <Button
          onClick={() => {
            createNewWhiteboard(undefined, projectId);
            onClose();
          }}
          outline={true}
          size={'xs'}>
          {t('pages.project.whiteboards.createNewWhiteboard')}
        </Button>
      </div>
    </div>
  );
};

/* WHITEBOARD DROPDOWN */
type WhiteboardDropdownProps = {
  whiteboards?: Whiteboard[]; // Replace 'any' with your Whiteboard type
  setAddingWhiteboard: (value: boolean) => void;
};

const WhiteboardDropdown = ({
  whiteboards: projectWhiteboards,
  setAddingWhiteboard,
}: WhiteboardDropdownProps) => {
  const { whiteboards } = useGetWhiteboards('visible');
  const { addWhiteboardToProject } = useAddWhiteboardToProject();
  const { projectId } = useParams();
  const ref = useRef(null);
  const { t } = useTranslation('dashboard');

  if (!whiteboards || !projectId) return null;

  // Remove whiteboards that are already in the project
  const whiteboardsFiltered = whiteboards.filter((whiteboard) => {
    return !projectWhiteboards?.find(
      (projectWhiteboard) => projectWhiteboard.id === whiteboard.id
    );
  });

  const whiteboardOptions: Option[] = whiteboardsFiltered.map((whiteboard) => ({
    id: whiteboard.id,
    label: whiteboard.name,
    value: whiteboard,
    element: (props) => (
      <WhiteboardDropdownElement {...props} whiteboard={whiteboard} />
    ),
  }));

  return (
    <div ref={ref} className='flex w-full flex-col gap-2'>
      <div className='flex h-full w-full flex-col gap-2'>
        <Dropdown
          options={whiteboardOptions}
          search
          triggerStyles='border'
          isContentContained={false}
          icon={faChalkboard}
          showSelectedWithinList={false}
          placeholder={t('pages.project.whiteboards.chooseWhiteboard')}
          onChange={(option) => {
            if (!option || option.id === 'new') return;
            // Add logic here to add existing whiteboard to project
            addWhiteboardToProject(option.id, projectId);
            setAddingWhiteboard(false);
          }}
          initialValue={null}
        />
      </div>
    </div>
  );
};

const WhiteboardDropdownElement = ({
  whiteboard,
  reset,
}: DropdownElementProps & { whiteboard: WhiteboardShort }) => {
  return (
    <div
      className='flex w-full items-center justify-between gap-2 text-sm'
      onClick={() => reset && reset()}>
      <div className='flex items-center gap-2'>
        <div className='aspect-square rounded-md bg-white p-2'>
          <SimpleButton
            className='text-text-negative'
            size='squareXXS'
            leftIcon={faChalkboard}
          />
        </div>
        <div>{whiteboard.name}</div>
      </div>
    </div>
  );
};

export default WhiteboardTab;
