import useImageWithAuth from '../../../../hooks/useImageWithAuth';
import { ImgHTMLAttributes } from 'react';

import ImageLoading from './ImageLoading';
import { useCreatorContext } from '../../../../pages/Creator';

type ResultsGenerationImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  imagePath?: string;
  isLoading?: boolean;
};

const ResultsGenerationImage = ({
  imagePath,
  isLoading = false,

  ...rest
}: ResultsGenerationImageProps) => {
  const { imgUrl, loading: imageLoading } = useImageWithAuth(imagePath);
  const { importImageFromDOMElement } = useCreatorContext();

  return imageLoading || isLoading ? (
    <ImageLoading />
  ) : (
    <img
      {...rest}
      //FIXME: use generation ID instead of image URL
      id={imgUrl}
      src={imgUrl}
      onClick={() => {
        if (!importImageFromDOMElement) return
        importImageFromDOMElement(imgUrl);
      }}
    />
  );
};

export default ResultsGenerationImage;
