import { StateNode, TLStateNodeConstructor } from '@tldraw/editor';
import { Pointing } from './toolStates/Pointing';
import { GeoIdle } from '../TldrawExports';

/** @public */
export class CustomRectangleShapeTool extends StateNode {
	static override id = 'rectangleToolCustom'
	static override initial = 'idle'
	static override children(): TLStateNodeConstructor[] {
		return [GeoIdle, Pointing]
	}
	override shapeType = 'geoCustom'
}

/** @public */
export class CustomEllipseShapeTool extends StateNode {
	static override id = 'ellipseToolCustom'
	static override initial = 'idle'
	static override children(): TLStateNodeConstructor[] {
		return [GeoIdle, Pointing]
	}
	override shapeType = 'geoCustom'
}
