/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  geoShapeProps,
  GeoShapeUtil,
  getDefaultColorTheme,
  HTMLContainer,
  LABEL_FONT_SIZES,
  RecordProps,
  SVGContainer,
  SvgExportContext,
  SvgTextLabel,
  TEXT_PROPS,
  TextLabel,
  TLBaseShape,
  TLGeoShape,
  TLGeoShapeProps,
  TLPropsMigrations,
  TLResizeInfo,
  useDefaultColorTheme,
  useValue,
} from 'tldraw';

import { HyperlinkButton } from '../TldrawExports';
import { GeoShapeBody } from './components/GeoShapeBody';
import {
  erasableShapeDefaultProps,
  ErasableShapeProps,
  erasableShapeProps,
  resizeEraserPaths,
} from '../sharedFeatures/EraserMaskFeature';
import { EraserPathSegment } from '../../CustomShapeUtil/CustomImageShapeUtil';
import {
  colorableShapeDefaultProps,
  colorableShapeProps,
  ColorableShapeProps,
} from '../sharedFeatures/HexColorFeature';

// Can't be exported from tldraw, redefine it here
const LABEL_PADDING = 16;

// Defining the custom geo shape props
type TLCustomGeoShapeProps = TLGeoShapeProps &
  ErasableShapeProps &
  ColorableShapeProps;
// Defining the custom geo shape
export type TLGeoShapeCustom = TLBaseShape<'geoCustom', TLCustomGeoShapeProps>;

export class CustomGeoShapeUtil extends GeoShapeUtil {
  static override type = 'geoCustom' as 'geo';

  // Adding the custom properties (hexColor and eraserPaths) to the baseProps
  // baseProps is an object of validators coming from the TLDRAW validating library (https://tldraw.dev/reference/validate/T)
  // ex: T.string, T.number, T.any, etc
  static override props: RecordProps<TLGeoShapeCustom> = {
    ...geoShapeProps, //We inherit the baseProps from the GeoShape...
    //...and add the custom properties
    ...colorableShapeProps,
    ...erasableShapeProps,
  };

  // Migration should be handle at some point
  // A migration is necessary when the shape properties change and we need to update the old shapes to the new properties
  static override migrations = null as unknown as TLPropsMigrations;

  override getDefaultProps(): TLGeoShapeCustom['props'] {
    const props = super.getDefaultProps();
    return {
      ...props,
      ...erasableShapeDefaultProps,
      ...colorableShapeDefaultProps,
    };
  }

  override component(shape: TLGeoShape) {
    const { id, type, props } = shape;
    const { fill, font, align, verticalAlign, size, text } = props;
    const theme = useDefaultColorTheme();
    const { editor } = this;
    const isOnlySelected = useValue(
      'isGeoOnlySelected',
      () => shape.id === editor.getOnlySelectedShapeId(),
      []
    );
    const isEditingAnything = editor.getEditingShapeId() !== null;
    const showHtmlContainer = isEditingAnything || shape.props.text;
    const isForceSolid = useValue(
      'force solid',
      () => {
        return editor.getZoomLevel() < 0.2;
      },
      [editor]
    );

    return (
      <>
        <SVGContainer>
          <GeoShapeBody
            shape={shape}
            shouldScale={true}
            forceSolid={isForceSolid}
          />
        </SVGContainer>
        {showHtmlContainer && (
          <HTMLContainer
            style={{
              overflow: 'hidden',
              width: shape.props.w,
              height: shape.props.h + props.growY,
            }}>
            <TextLabel
              shapeId={id}
              type={type}
              font={font}
              fontSize={LABEL_FONT_SIZES[size] * shape.props.scale}
              lineHeight={TEXT_PROPS.lineHeight}
              padding={LABEL_PADDING * shape.props.scale}
              fill={fill}
              align={align}
              verticalAlign={verticalAlign}
              text={text}
              isSelected={isOnlySelected}
              labelColor={theme[props.labelColor].solid}
              wrap
            />
          </HTMLContainer>
        )}
        {shape.props.url && <HyperlinkButton url={shape.props.url} />}
      </>
    );
  }

  /// Override toSvg with exact same code in order to use our
  /// GeoShapeBody that applies hexColor and eraset path.
  override toSvg(shape: TLGeoShape, ctx: SvgExportContext) {
    // We need to scale the shape to 1x for export
    const newShape = {
      ...shape,
      props: {
        ...shape.props,
        w: shape.props.w / shape.props.scale,
        h: shape.props.h / shape.props.scale,
      },
    };
    const props = newShape.props;
    /// probably useless in our case and need an import from
    /// node_modules/tldraw/src/lib/shapes/shared/defaultStyleDefs.tsx
    /// which cause build issues.
    //ctx.addExportDef(getFillDefForExport(props.fill))

    let textEl;
    if (props.text) {
      //ctx.addExportDef(getFontDefForExport(props.font))
      const theme = getDefaultColorTheme(ctx);

      const bounds = new Box(0, 0, props.w, props.h + props.growY);
      textEl = (
        <SvgTextLabel
          fontSize={LABEL_FONT_SIZES[props.size]}
          font={props.font}
          align={props.align}
          verticalAlign={props.verticalAlign}
          text={props.text}
          labelColor={theme[props.labelColor].solid}
          bounds={bounds}
          padding={16}
        />
      );
    }

    return (
      <>
        <GeoShapeBody shouldScale={false} shape={newShape} forceSolid={false} />
        {textEl}
      </>
    );
  }

  // This function is called when the shape is resized
  // The super handles the resizing of the shape segments
  // We need to resize the eraser paths ourselves
  override onResize(shape: TLGeoShape, info: TLResizeInfo<TLGeoShape>) {
    // Super
    const { x, y, props } = super.onResize(shape, info);

    // Casting the shape to our custom shape
    const customShape = shape as unknown as TLGeoShapeCustom;

    const newEraserSegments: EraserPathSegment[] = resizeEraserPaths(
      customShape,
      info
    );

    // Returning the new props with new eraser paths added
    return {
      x: x,
      y: y,
      props: { ...props, eraserPaths: newEraserSegments },
    };
  }
}
