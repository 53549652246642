import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { ReactNode, MouseEvent } from 'react';

import { twMerge } from 'tailwind-merge';

//TODO: Replace where used by Button
export type SimpleButtonProps = {
  leftIcon?: IconDefinition;
  rightIcon?: IconDefinition;
  children?: ReactNode;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;

  size?: 'squareMD' | 'squareXS' | 'squareXXS' | 'xs' | 'md' | 'xl';
  theme?: 'flat' | 'outline' | 'tertiary';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'generateButton';
  state?: 'default' | 'active' | 'inactive' | 'disabled' | 'hidden';
  iconSize?: string;
  showLabel?: boolean;
  format?: 'square' | 'rounded';
  round?: boolean;
  href?: string;
  disabled?: boolean;
  inactive?: boolean;
  icon?: IconDefinition;
  height?: string;
  width?: string;
  customStyle?: string;
  hiddenMobile?: boolean;
  rotate?: string;
};

/* TODO: Refactor (using CVA maybe) */
const SimpleButton = ({
  leftIcon,
  rightIcon,
  children,
  onClick,
  size = 'md',
  theme = 'flat',
  variant,
  state = 'default',
  iconSize = '16px',
  showLabel = true,
  format = 'rounded',
  round = false,
  height = '',
  width = '',
  className,

  hiddenMobile = false,
  disabled = false,
  rotate = 'rotate-0',
}: SimpleButtonProps) => {
  if (state === 'hidden') return null;

  const baseStyles = 'flex items-center h-min justify-center font-semibold ';

  const sizeStyles = {
    squareMD: 'p-4 text-sm',
    squareXS: 'p-3 text-sm',
    squareXXS: 'p-0 text-xs',
    xs: 'py-1.5 px-2 text-xs',
    md: 'px-4 py-3 text-sm',
    xl: 'px-6 py-3 text-lg',
  };

  const themeStyles = {
    flat: '',
    outline: '',
    tertiary: '',
  };

  const stateStyles = {
    default: 'opacity-100 font-medium',
    active: 'opacity-100 text-d-surface-bg-accent bg-d-surface-bg-secondary',
    inactive:
      ' saturate-50 hover:opacity-100 font-medium hover:bg-d-surface-bg-base',
    disabled: ' saturate-50  cursor-not-allowed text-d-text-base text-black',
  };

  const formatStyles = {
    square: 'rounded-none',
    rounded: 'rounded-full',
  };

  const variantStyles = {
    primary: 'bg-d-surface-bg-secondary opacity-100',
    secondary: 'bg-transparent opacity-70 hover:opacity-100',
    tertiary: 'bg-d-surface-bg-tertiary',
    generateButton:
      'h-12 min-w-12 text-sm bg-d-surface-bg-accent rounded-2xl bg-gradient-to-r from-customGreen to-customPurple',
  };

  return (
    <motion.button
      layout='position'
      onClick={!disabled && onClick ? onClick : undefined}
      className={twMerge(
        baseStyles,
        sizeStyles[size],
        themeStyles[theme],
        variant ? variantStyles[variant] : '',
        stateStyles[state],
        formatStyles[format],
        hiddenMobile ? 'hidden' : '',

        disabled ? stateStyles.disabled : '',
        (leftIcon || rightIcon) && 'leading-[0px]',
        className
      )}
      disabled={disabled}
      style={{
        gap: showLabel ? '8px' : '0',
        width: width || undefined,
        height: height || undefined,
        rotate: rotate || undefined,
        borderRadius: round ? '9999px' : '12px',
      }}>
      {leftIcon && (
        <motion.div layout='position'>
          <FontAwesomeIcon
            icon={leftIcon}
            className='{rotate}' // Application de la rotation ici
            style={{ width: iconSize, height: iconSize }}
          />
        </motion.div>
      )}

      {showLabel && children && (
        <motion.div layout className='flex items-center text-nowrap'>
          {children}
        </motion.div>
      )}
      {rightIcon && (
        <motion.div layout='position'>
          <FontAwesomeIcon
            icon={rightIcon}
            className={rotate} // Application de la rotation ici
            style={{ width: iconSize, height: iconSize }}
          />
        </motion.div>
      )}
    </motion.button>
  );
};

export default SimpleButton;
