import { useKeycloak } from '@react-keycloak/web';
import { usePanelContext } from '../../components/Creator/Panel/context/usePanelContext';
import { GenerationStatus } from '@/types';

import { Creation } from '@/types/api';
import { useAxios } from '@/helpers/ProtectedLayout';

const useGenerate = () => {
  const { keycloak } = useKeycloak();

  const axios = useAxios().apiInstance;

  const { setError, setLoading } = usePanelContext();

  const getInProgressGeneration = async () => {
    const url = '/user/inProgressGeneration';

    setLoading(true);
    setError(null);

    try {
      const response = await axios.get<Creation>(url, {
        headers: {
          authorization: `Bearer ${keycloak.token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch in-progress generation');
      }

      const data = response.data;
      if (!data) {
        setLoading(false);
        return;
      }
      console.log(data);
      return data;
    } catch (err) {
      console.error(err);
      setError('default');
      setLoading(false);
    }
  };

  const getGeneration = async (id: string): Promise<Creation> => {
    try {
      const response = await axios.get<Creation>(`/generation/${id}`);

      if (response.status !== 200) {
        throw new Error('Failed to fetch generation');
      }

      const { status } = response.data;

      if (
        status === GenerationStatus.FAILED ||
        status === GenerationStatus.TIMEOUT
      ) {
        setError('default');
        throw new Error('Generation failed');
      }

      if (status === GenerationStatus.IN_PROGRESS) {
        throw new Error('Generation in progress');
      }

      if (status === GenerationStatus.COMPLETED) {
        return response.data;
      }

      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Generation fetch failed: ${error.message}`);
      }
      throw error;
    }
  };

  return {
    getInProgressGeneration,
    getGeneration,
  };
};

export default useGenerate;
