import { useEffect, useState } from 'react';
import useRenameWhiteboard from '../../../hooks/whiteboard/useRenameWhiteboard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import { useGetWhiteboard } from '@/hooks/whiteboard/useGetWhiteboard';

type RenamableWhiteboardProps = {
  whiteboardId: string;
  className?: string;
};

const RenamableWhiteboard = ({
  whiteboardId,
  className,
}: RenamableWhiteboardProps) => {
  const { whiteboard } = useGetWhiteboard(whiteboardId);
  const [newName, setNewName] = useState(whiteboard?.name);

  useEffect(() => {
    if (!whiteboard) return;
    setNewName(whiteboard.name);
  }, [whiteboard?.name]);

  const [isEditing, setIsEditing] = useState(false);

  const { renameWhiteboard } = useRenameWhiteboard();

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const editName = async () => {
    console.log(newName);
    if (!whiteboard) return;
    if (newName === '') {
      setNewName(whiteboard.name);
      setIsEditing(false);
      return;
    }
    try {
      console.log(whiteboard.id);
      await renameWhiteboard({
        whiteboardId: whiteboard.id,
        newName: newName ?? whiteboard.name,
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update whiteboard name', error);
    }
  };

  const keyDownName = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (newName === '') {
        setNewName(whiteboard?.name);
        setIsEditing(false);
        return;
      }
      editName();
    }
  };

  return (
    <div
      className={twMerge(
        'grid w-fit grid-cols-[1fr_min-content] gap-2 overflow-hidden',
        className
      )}
      onDoubleClick={() => setIsEditing(true)}>
      {isEditing ? (
        <input
          type='text'
          value={newName}
          onChange={changeName}
          onKeyDown={keyDownName}
          onBlur={editName}
          autoFocus
          className='w-full border-b border-gray-300 bg-transparent focus:outline-none'
        />
      ) : (
        <div className='overflow-hidden text-ellipsis text-nowrap'>
          {whiteboard?.name}
        </div>
      )}
      <div
        className='cursor-pointer opacity-40'
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsEditing(true);
        }}>
        <FontAwesomeIcon icon={faPencil} height={10} width={10} />
      </div>
    </div>
  );
};

export default RenamableWhiteboard;
