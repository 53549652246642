import { useQuery } from 'react-query';

import { useAlbumAPI } from './useAlbumAPI';

const useGetAlbum = (albumId: string) => {
  const { getAlbum } = useAlbumAPI();

  const { data, isLoading, isError, error, status } = useQuery({
    queryKey: ['album', albumId],
    queryFn: async () => await getAlbum(albumId),
  });

  return {
    album: data,
    status,
    isLoading,
    isError,
    error,
  };
};

export default useGetAlbum;
