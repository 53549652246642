import { useTranslation } from 'react-i18next';
import TabHelp from '../Tabs/TabHelp';
import TextArea from '../../../lib/Inputs/TextArea';
// Import TabDropdown
import { usePanelContext } from '../context/usePanelContext';
import { motion } from 'framer-motion';
import NewAlbum from '../Album/NewAlbum';
import ModifyAlbum from '../Album/ModifyAlbum';
import { usePanelInputContext } from '../context/usePanelInputContext';
import { useAlbumContext } from '../context/useAlbumContext';

import { UserRole } from '../../../../types';

import AlbumDropdown from './StyleDropdowns/AlbumDropdown';
import GraphicStyleDropdown from './StyleDropdowns/GraphicStyleDropdown';

import Slider from '../../../lib/Inputs/Slider';

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHasRole } from '@/hooks/useHasRole';

export const Imagine = () => {
  const hasRole = useHasRole();

  /* CONTEXT */
  const { setImagineInputText, setAlbumCursor, imagineInputText, albumCursor } =
    usePanelInputContext();
  const { whiteboardId } = useParams();

  const { albumToModify, selectedAlbum } = useAlbumContext();

  /* HELP POPUP STATE */
  const { showHelp, setShowHelp, showCreateNewAlbum } = usePanelContext();

  const { t } = useTranslation(['panel']);

  // Check userRoles
  const inspireAllowed = hasRole(UserRole.Inspire);

  // Get input text from local storage
  useEffect(() => {
    const savedText = localStorage.getItem(`imagineTextInput-${whiteboardId}`);
    if (savedText) {
      setImagineInputText(savedText);
    }

    const savedAlbumCursor = localStorage.getItem(`imagineAlbumCursor-${whiteboardId}`);
    if (savedAlbumCursor) {
      setAlbumCursor(parseFloat(savedAlbumCursor));
    }

  }, []);

  const handleTextChange = (text: string) => {
    setImagineInputText(text);
    localStorage.setItem(`imagineTextInput-${whiteboardId}`, text);
  };

  const handleAlbumCursorChange = (value: number) => {
    setAlbumCursor(value);
    localStorage.setItem(`imagineAlbumCursor-${whiteboardId}`, value.toString());
  };

  return (
    <motion.div layout className='flex w-full flex-col gap-3 text-xs'>
      {/* HELP */}
      {showHelp && (
        <TabHelp
          setShowHelp={setShowHelp}
          steps={[
            t('panel:Imagine.help.step1'),
            t('panel:Imagine.help.step2'),
            t('panel:Imagine.help.step3'),
          ]}
        />
      )}

      {/* NEW ALBUM POPUP */}
      {showCreateNewAlbum && <NewAlbum />}

      {/* VIEW ALBUM TO MODIFY */}
      {albumToModify && <ModifyAlbum albumId={albumToModify} />}

      {(!albumToModify || !showCreateNewAlbum) && (
        <motion.div layout className='flex flex-col gap-6 rounded-2xl'>
          {/* INPUT */}
          <TextArea
            label={t('panel:Imagine.promptLabel')}
            id='prompt'
            rows={4}
            placeholder={t('panel:Imagine.promptPlaceholder')}
            value={imagineInputText} // Add this line
            onChange={(e) => {
              handleTextChange(e.target.value);
            }}
          />

          <motion.div layout className='flex flex-col gap-3 text-xs'>
            {/* DESIGN STYLE */}
            <motion.div
              layout
              className='text-d-text-negative pl-2 font-jakarta text-sm text-d-text-2'>
              {t('panel:Imagine.designStyleLabel')}
            </motion.div>

            <motion.div layout className='relative grid grid-cols-2 gap-2'>
              <GraphicStyleDropdown />

              {inspireAllowed && <AlbumDropdown />}
            </motion.div>
            {inspireAllowed && selectedAlbum && (
              <>
                <motion.div
                  layout
                  className='text-d-text-negative pl-2 font-jakarta text-sm text-d-text-2'>
                  {t('panel:Inspire.inspirationLevel')}
                </motion.div>
                <Slider
                  labels={[
                    t('panel:Inspire.SliderLabels.None'),
                    t('panel:Inspire.SliderLabels.Average'),
                    t('panel:Inspire.SliderLabels.Strong'),
                  ]}
                  step={0.1}
                  range={[0, 1]}
                  initialValue={albumCursor}
                  onChange={(value) => handleAlbumCursorChange(value)}
                />
              </>
            )}
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  );
};
