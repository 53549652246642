import {
  canonicalizeRotation,
  getPerfectDashProps,
  Group2d,
  STROKE_SIZES,
  TLGeoShape,
  useEditor,
  Vec,
} from 'tldraw';
import {
  getCloudArcs,
  getCloudPath,
  getDrawHeartPath,
  getHeartParts,
  getHeartPath,
  getLines,
  getRoundedInkyPolygonPath,
  getRoundedPolygonPoints,
  inkyCloudSvgPath,
} from '../../TldrawExports';
import { ShapeFill } from '../../sharedFeatures/CustomShapeFill';
import { TLGeoShapeCustom } from '../CustomGeoShapeUtil';
import { getEraserMask } from '../../sharedFeatures/EraserMaskFeature';

// and needs to be modified to handle the eraser paths
export function GeoShapeBody({
  shape,
  shouldScale,
  forceSolid,
}: {
  shape: TLGeoShape;
  shouldScale: boolean;
  forceSolid: boolean;
}) {
  const scaleToUse = shouldScale ? shape.props.scale : 1;
  const editor = useEditor();
  const { id, props } = shape;
  const { w, dash, growY, size, scale } = props;
  const strokeWidth = STROKE_SIZES[size] * scaleToUse;
  const h = props.h + growY;

  //---------------------END OF ORIGINAL CODE----------------------------//

  /// Casting the shape to our custom shape
  const customShape = shape as unknown as TLGeoShapeCustom;
  const eraserMask = getEraserMask(editor, customShape);

  let maskUrl: string | undefined = undefined;
  if (eraserMask) maskUrl = 'url(#eraserMask' + customShape.id + ')';

  /// Now, replace ShapeFill by our own, giving it the hexColor and eraser mask
  switch (props.geo) {
    case 'cloud': {
      if (dash === 'solid') {
        const d = getCloudPath(w, h, id, size, scale);
        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <path
              d={d}
              stroke={customShape.props.hexColor}
              strokeWidth={strokeWidth}
              fill='none'
              mask={maskUrl}
            />
            {eraserMask}
          </>
        );
      } else if (dash === 'draw') {
        const d = inkyCloudSvgPath(w, h, id, size, scale);
        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <path
              d={d}
              stroke={customShape.props.hexColor}
              strokeWidth={strokeWidth}
              fill='none'
              mask={maskUrl}
            />
            {eraserMask}
          </>
        );
      } else {
        const d = getCloudPath(w, h, id, size, scale);
        const arcs = getCloudArcs(w, h, id, size, scale);

        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <g
              strokeWidth={strokeWidth}
              stroke={customShape.props.hexColor}
              fill='none'
              pointerEvents='all'
              mask={maskUrl}>
              {arcs.map(({ leftPoint, rightPoint, center, radius }, i) => {
                const arcLength = center
                  ? radius *
                    canonicalizeRotation(
                      canonicalizeRotation(Vec.Angle(center, rightPoint)) -
                        canonicalizeRotation(Vec.Angle(center, leftPoint))
                    )
                  : Vec.Dist(leftPoint, rightPoint);

                const { strokeDasharray, strokeDashoffset } =
                  getPerfectDashProps(arcLength, strokeWidth, {
                    style: dash,
                    start: 'outset',
                    end: 'outset',
                    forceSolid,
                  });

                return (
                  <path
                    key={i}
                    d={
                      center
                        ? `M${leftPoint.x},${leftPoint.y}A${radius},${radius},0,0,1,${rightPoint.x},${rightPoint.y}`
                        : `M${leftPoint.x},${leftPoint.y}L${rightPoint.x},${rightPoint.y}`
                    }
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset}
                    mask={maskUrl}
                  />
                );
              })}
            </g>
            {eraserMask}
          </>
        );
      }
    }
    case 'ellipse': {
      const geometry = shouldScale
        ? // cached
          editor.getShapeGeometry(shape)
        : // not cached
          editor.getShapeUtil(shape).getGeometry(shape);
      const d = geometry.getSvgPathData(true);

      if (dash === 'dashed' || dash === 'dotted') {
        const perimeter = geometry.length;
        const { strokeDasharray, strokeDashoffset } = getPerfectDashProps(
          perimeter < 64 ? perimeter * 2 : perimeter,
          strokeWidth,
          {
            style: dash,
            snap: 4,
            closed: true,
            forceSolid,
          }
        );

        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <path
              d={d}
              strokeWidth={strokeWidth}
              fill='none'
              stroke={customShape.props.hexColor}
              strokeDasharray={strokeDasharray}
              strokeDashoffset={strokeDashoffset}
              mask={maskUrl}
            />
            {eraserMask}
          </>
        );
      } else {
        const geometry = shouldScale
          ? // cached
            editor.getShapeGeometry(shape)
          : // not cached
            editor.getShapeUtil(shape).getGeometry(shape);
        const d = geometry.getSvgPathData(true);
        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <path
              d={d}
              stroke={customShape.props.hexColor}
              strokeWidth={strokeWidth}
              fill='none'
              mask={maskUrl}
            />
            {eraserMask}
          </>
        );
      }
    }
    case 'oval': {
      const geometry = shouldScale
        ? // cached
          editor.getShapeGeometry(shape)
        : // not cached
          editor.getShapeUtil(shape).getGeometry(shape);
      const d = geometry.getSvgPathData(true);
      if (dash === 'dashed' || dash === 'dotted') {
        const perimeter = geometry.getLength();
        const { strokeDasharray, strokeDashoffset } = getPerfectDashProps(
          perimeter < 64 ? perimeter * 2 : perimeter,
          strokeWidth,
          {
            style: dash,
            snap: 4,
            start: 'outset',
            end: 'outset',
            closed: true,
            forceSolid,
          }
        );

        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <path
              d={d}
              strokeWidth={strokeWidth}
              fill='none'
              stroke={customShape.props.hexColor}
              strokeDasharray={strokeDasharray}
              strokeDashoffset={strokeDashoffset}
              mask={maskUrl}
            />
            {eraserMask}
          </>
        );
      } else {
        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <path
              d={d}
              stroke={customShape.props.hexColor}
              strokeWidth={strokeWidth}
              fill='none'
              mask={maskUrl}
            />
            {eraserMask}
          </>
        );
      }
    }
    case 'heart': {
      if (dash === 'dashed' || dash === 'dotted' || dash === 'solid') {
        const d = getHeartPath(w, h);
        const curves = getHeartParts(w, h);

        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            {curves.map((c, i) => {
              const { strokeDasharray, strokeDashoffset } = getPerfectDashProps(
                c.length,
                strokeWidth,
                {
                  style: dash,
                  snap: 1,
                  start: 'outset',
                  end: 'outset',
                  closed: true,
                  forceSolid,
                }
              );
              return (
                <path
                  key={`curve_${i}`}
                  d={c.getSvgPathData()}
                  strokeWidth={strokeWidth}
                  fill='none'
                  stroke={customShape.props.hexColor}
                  strokeDasharray={strokeDasharray}
                  strokeDashoffset={strokeDashoffset}
                  pointerEvents='all'
                  mask={maskUrl}
                />
              );
            })}
            {eraserMask}
          </>
        );
      } else {
        const d = getDrawHeartPath(w, h, strokeWidth, shape.id);
        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <path
              d={d}
              stroke={customShape.props.hexColor}
              strokeWidth={strokeWidth}
              fill='none'
              mask={maskUrl}
            />
            {eraserMask}
          </>
        );
      }
    }
    default: {
      const geometry = shouldScale
        ? // cached
          editor.getShapeGeometry(shape)
        : // not cached
          editor.getShapeUtil(shape).getGeometry(shape);

      const outline =
        geometry instanceof Group2d
          ? geometry.children[0].vertices
          : geometry.vertices;
      const lines = getLines(shape.props, strokeWidth);

      if (dash === 'solid') {
        let d = 'M' + outline[0] + 'L' + outline.slice(1) + 'Z';

        if (lines) {
          for (const [A, B] of lines) {
            d += `M${A.x},${A.y}L${B.x},${B.y}`;
          }
        }

        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <path
              d={d}
              stroke={customShape.props.hexColor}
              strokeWidth={strokeWidth}
              fill='none'
              mask={maskUrl}
            />
            {eraserMask}
          </>
        );
      } else if (dash === 'dashed' || dash === 'dotted') {
        const d = 'M' + outline[0] + 'L' + outline.slice(1) + 'Z';

        return (
          <>
            <ShapeFill
              d={d}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <g
              strokeWidth={strokeWidth}
              stroke={customShape.props.hexColor}
              fill='none'
              pointerEvents='all'
              mask={maskUrl}>
              {Array.from(Array(outline.length)).map((_, i) => {
                const A = Vec.ToFixed(outline[i]);
                const B = Vec.ToFixed(outline[(i + 1) % outline.length]);
                const dist = Vec.Dist(A, B);
                const { strokeDasharray, strokeDashoffset } =
                  getPerfectDashProps(dist, strokeWidth, {
                    style: dash,
                    start: 'outset',
                    end: 'outset',
                    forceSolid,
                  });

                return (
                  <line
                    key={i}
                    x1={A.x}
                    y1={A.y}
                    x2={B.x}
                    y2={B.y}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset}
                    mask={maskUrl}
                  />
                );
              })}
              {lines &&
                lines.map(([A, B], i) => {
                  const dist = Vec.Dist(A, B);

                  const { strokeDasharray, strokeDashoffset } =
                    getPerfectDashProps(dist, strokeWidth, {
                      style: dash,
                      start: 'skip',
                      end: 'skip',
                      snap: dash === 'dotted' ? 4 : undefined,
                      forceSolid,
                    });

                  return (
                    <path
                      key={`line_fg_${i}`}
                      d={`M${A.x},${A.y}L${B.x},${B.y}`}
                      stroke={customShape.props.hexColor}
                      strokeWidth={strokeWidth}
                      fill='none'
                      strokeDasharray={strokeDasharray}
                      strokeDashoffset={strokeDashoffset}
                      mask={maskUrl}
                    />
                  );
                })}
            </g>
            {eraserMask}
          </>
        );
      } else if (dash === 'draw') {
        let d = getRoundedInkyPolygonPath(
          getRoundedPolygonPoints(
            id,
            outline,
            strokeWidth / 3,
            strokeWidth * 2,
            2
          )
        );

        if (lines) {
          for (const [A, B] of lines) {
            d += `M${A.toFixed()}L${B.toFixed()}`;
          }
        }

        const innerPathData = getRoundedInkyPolygonPath(
          getRoundedPolygonPoints(id, outline, 0, strokeWidth * 2, 1)
        );

        return (
          <>
            <ShapeFill
              d={innerPathData}
              fill={customShape.props.fill}
              color={customShape.props.hexColor}
              mask={maskUrl}
            />
            <path
              d={d}
              stroke={customShape.props.hexColor}
              strokeWidth={strokeWidth}
              fill='none'
              mask={maskUrl}
            />
            {eraserMask}
          </>
        );
      }
    }
  }
}
