import { useTranslation } from 'react-i18next';
import useGetAlbum from '../../../hooks/albums/useGetAlbum';

type AlbumNameProps = {
  albumId: string;
};

const AlbumName = ({ albumId }: AlbumNameProps) => {
  const { album } = useGetAlbum(albumId);
  const { t } = useTranslation('dashboard');

  if (!album) {
    return <div>{t('album.albumNotFound')}</div>;
  }

  return <p>{album.name}</p>;
};

export default AlbumName;
