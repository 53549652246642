import {
  faFolder,
  faTrash,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../lib/Button';
import { TimeTags } from '../Creations/CreationCard';

import { useGetWhiteboard } from '../../../hooks/whiteboard/useGetWhiteboard';
import useArchiveWhiteboard from '../../../hooks/whiteboard/useArchiveWhiteboard';
import useRestoreWhiteboard from '../../../hooks/whiteboard/useRestoreWhiteboard';
import RenamableWhiteboard from './RenamableWhiteboard';
import { WhiteboardImage } from './WhiteboardImage';
import { ProjectName } from './ProjectName';
import { useHasRole } from '@/hooks/useHasRole';
import { UserRole } from '@/types';
import { useTranslation } from 'react-i18next';

type WhiteboardCardProps = {
  whiteboardId: string;
  width?: string;
};

export const WhiteboardCard = ({
  whiteboardId,
  width,
}: WhiteboardCardProps) => {
  const { whiteboard } = useGetWhiteboard(whiteboardId);
  const hasRole = useHasRole();
  const { t } = useTranslation("dashboard");

  const hasProjectsRole = hasRole(UserRole.Projects);

  const { archiveWhiteboard } = useArchiveWhiteboard();
  const { restoreWhiteboard } = useRestoreWhiteboard();

  //Check if we're in a project page
  const { projectId: projectIdParam } = useParams<{ projectId: string }>();

  const navigate = useNavigate();
  if (!whiteboard) return null;

  const { id, projectId, status } = whiteboard;

  return (
    <div
      className={`flex flex-col gap-3 rounded-xl bg-surface-primary p-4`}
      style={{ width: width }}>
      {/* PREVIEW IMAGE */}
      <div
        className='aspect-[16/11] w-full cursor-pointer rounded-xl bg-surface-secondary'
        onClick={() => {
          navigate('/creator/' + whiteboardId);
        }}>
        <WhiteboardImage whiteboardId={whiteboardId} />
      </div>

      <div className='flex items-center justify-between gap-4'>
        {/* TITLE */}
        <div className='relative w-full overflow-hidden'>
          {hasProjectsRole && (
            <div className='flex items-center gap-1 text-xs text-slate-200/60'>
              <FontAwesomeIcon icon={faFolder} />
              {projectId ? <ProjectName projectId={projectId} /> : t('common.noFolder')}
            </div>
          )}
          <h1 className='w-full overflow-hidden text-ellipsis'>
            <RenamableWhiteboard whiteboardId={whiteboardId} />
          </h1>
        </div>
        {/* ARCHIVE AND RESTORE */}
        {hasProjectsRole && (
          <Button
            className='text-sm'
            variant='secondary'
            round
            size='small'
            iconSize='10px'
            rightIcon={status === 'VISIBLE' ? faTrash : faTrashRestore}
            onClick={() => {
              if (status === 'VISIBLE') archiveWhiteboard(whiteboardId);
              else restoreWhiteboard(whiteboardId);
            }}></Button>
        )}
      </div>

      <div className='flex items-center justify-between'>
        {/* DATE */}
        <TimeTags createdAt={whiteboard.createdAt} />
        {/* DETAIL LINK*/}
        <Button
          className='justify-self-end text-xs'
          variant='primary'
          round
          size='xs'
          onClick={() => {
            if (projectIdParam)
              navigate('/projects/' + projectIdParam + '/whiteboard/' + id);
            else navigate('/whiteboards/' + id);
          }}>
          {t('common.details')}
        </Button>
      </div>
    </div>
  );
};
