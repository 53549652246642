import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookOpen,
  faTimesCircle,
  faTrash,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import AlbumImage from '../../Creator/Panel/Album/AlbumImage';
import useGetAlbum from '../../../hooks/albums/useGetAlbum';
import { TimeTags } from '../Creations/CreationCard';
import RenamableAlbum from '../../Creator/Panel/Album/RenamableAlbum';
import Button from '../../lib/Button';
import { useCurrentAlbumId } from '../store/currentAlbumIdStore';
import useArchiveAlbum from '@/hooks/albums/useArchiveAlbum';
import useRestoreAlbum from '@/hooks/albums/useRestoreAlbum';
import { useParams } from 'react-router-dom';
import useRemoveAlbumFromProjects from '@/hooks/albums/useRemoveAlbumFromProjects';
import { useTranslation } from 'react-i18next';

type AlbumViewProps = {
  albumId: string;
};

const AlbumCard = ({ albumId }: AlbumViewProps) => {
  //Check if we're in a project page
  const { projectId } = useParams();

  const { album } = useGetAlbum(albumId);
  const { setCurrentAlbumId } = useCurrentAlbumId();
  const { archiveAlbum } = useArchiveAlbum();
  const { restoreAlbum } = useRestoreAlbum();
  const { removeAlbumFromProjects } = useRemoveAlbumFromProjects();

  const { t } = useTranslation('dashboard');

  if (!album) return null;
  const { images, id, createdAt, status } = album;

  const moreThanFourImages = images.length > 4;

  return (
    <motion.div
      layoutId={id}
      layout
      className={`relative flex flex-col gap-4 rounded-2xl bg-surface-primary p-4`}>
      {/* DELETED FROM PROJECT */}
      {projectId && (
        <div
          className='absolute -right-2 -top-2 cursor-pointer'
          onClick={() => removeAlbumFromProjects(albumId, [projectId])}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
      )}
      {/* IMAGES */}
      <div className='flex flex-col gap-2'>
        <motion.div layout className={`grid w-full grid-cols-4 gap-2`}>
          {images
            .slice(0, moreThanFourImages ? 3 : 4)
            .map((image: { id: string }) => {
              return (
                <motion.div
                  layout
                  layoutId={image.id}
                  key={image.id}
                  className=''>
                  <AlbumImage albumId={album.id} imageId={image.id} />
                </motion.div>
              );
            })}
          {moreThanFourImages && (
            <motion.div
              layout
              layoutId={images[3].id}
              className='relative overflow-hidden rounded-2xl'>
              <AlbumImage albumId={album.id} imageId={images[3].id} />
              <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center bg-slate-800 bg-opacity-70'>
                <div className='text-2xl font-bold text-white'>
                  +{images.length - 3}
                </div>
              </div>
            </motion.div>
          )}
        </motion.div>
        <p className='text-xs opacity-50'>
          {images.length} {t('album.imageInAlbum', { count: images.length })}
        </p>
      </div>

      <div className='flex items-center justify-between text-sm'>
        <h1 className='flex items-center justify-center gap-1'>
          <FontAwesomeIcon icon={faBookOpen} />
          <RenamableAlbum
            key={album.name}
            albumId={album.id}
            className='gap-2 bg-transparent p-0 text-white'
          />
        </h1>
      </div>
      <div className='flex items-center justify-between text-xs'>
        <TimeTags createdAt={createdAt} />
        <div className='flex items-center gap-2'>
          <FontAwesomeIcon
            className='cursor-pointer'
            icon={status === 'ARCHIVED' ? faTrashRestore : faTrash}
            onClick={() =>
              status === 'ARCHIVED'
                ? restoreAlbum(album, albumId)
                : archiveAlbum(album, albumId)
            }
          />
          <Button
            size={'xs'}
            onClick={() => {
              setCurrentAlbumId(albumId);
            }}>
            {t('common.details')}{' '}
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default AlbumCard;
