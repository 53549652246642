import { Editor, TLShapeId, useReactor } from 'tldraw';

type OnSelection = (shapes: TLShapeId[]) => void;

/**
 * Custom hook that listens for selection changes in the editor and triggers a callback function.
 *
 * @param {OnSelection} onSelection - This callback function runs everytime the selection changes. It provides the selected shape ids.
 *
 * @returns {void}
 */
export const useOnSelection = (
  onSelection: OnSelection,
  editor: Editor | null
) => {
  useReactor(
    'on selection change',
    () => {
      if (!editor) return;

      const selectedShapeIds = editor.getSelectedShapeIds();

      onSelection(selectedShapeIds);
    },
    [editor]
  );
};
