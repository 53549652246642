import { Loader2 } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import useGetCreations from '../../../hooks/creations/useGetCreations';
import PageTitle from '../Layout/PageTitle';

import { CreationCard } from './CreationCard';
import { useTranslation } from 'react-i18next';

const Creations = () => {
  const { ref, inView } = useInView();
  const { t } = useTranslation('dashboard');

  //Don't render all card at first
  const [itemCount, setItemCount] = useState(12);
  useEffect(() => {
    const interval = setInterval(() => {
      setItemCount((prev) => prev * 2);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const {
    creations,
    isLoading,
    isError,

    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetCreations();

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  if (isLoading) return <div>{t('state.loading')}.</div>;
  if (isError) return <div>{t('state.error')}</div>;
  if (!creations?.length) return <div>{t('homepage.noCreations')}</div>;

  return (
    <div className='flex h-full flex-col gap-3'>
      <PageTitle title={t('pageNames.creations')} />
      <div className='grid max-h-full grid-cols-1 gap-4 overflow-y-auto md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
        {creations.slice(0, itemCount).map((creation) => (
          <CreationCard key={creation.id} creationId={creation.id} />
        ))}
        <div
          ref={ref}
          className='col-span-1 flex justify-center p-4 md:col-span-2 lg:col-span-3 2xl:col-span-4'>
          {isFetchingNextPage && <Loader2 className='animate-spin' />}
        </div>
      </div>
    </div>
  );
};

export default Creations;
