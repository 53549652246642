import {
  faFileCircleQuestion,
  faImages,
  faPlus,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../../lib/Button';
import PageTitle from '../Layout/PageTitle';
import { useCreateWhiteboard } from '../../../hooks/whiteboard/useCreateWhiteboard';
import { useGetWhiteboards } from '../../../hooks/whiteboard/useGetWhiteboards';

import { WhiteboardCard } from './WhiteboardCard';
import { useHasRole } from '@/hooks/useHasRole';

import { UserRole } from '@/types';
import { useGetLastUpdatedWhiteboard } from '@/hooks/whiteboard/useGetLastUpdatedWhiteboard';
import Tabs from '@/components/lib/Tabs/Tabs';
import { useTranslation } from 'react-i18next';

const Whiteboards = () => {
  const { createNewWhiteboard } = useCreateWhiteboard();
  const { whiteboard } = useGetLastUpdatedWhiteboard();
  const { t } = useTranslation('dashboard');



  const hasRole = useHasRole();
  const hasProjectsRole = hasRole(UserRole.Projects);

  return (
    <div className='flex h-full flex-col gap-4'>
      <div className='flex content-center items-center gap-4'>
        {/* TITLE */}
        <PageTitle title={t('pageNames.whiteboards')} />
        {/* NEW WHITEBOARD */}
        <div className='group relative'>
          <Button
            leftIcon={faPlus}
            round
            size={'small'}
            variant={'accent'}
            state={!hasProjectsRole && whiteboard ? 'disabled' : 'default'}
            onClick={() => {
              createNewWhiteboard(
                new Date().toLocaleDateString() +
                  ' - ' +
                  new Date().toLocaleTimeString()
              );
            }}
          />
          {!hasProjectsRole && whiteboard && (
            <div className='absolute mt-2 hidden w-max max-w-52 rounded-xl bg-green-to-violet-gradient p-4 group-hover:block'>
              <div className='z-[10000] rounded text-xs text-white'>
                {t('needToUpgrade')}{' '}
              </div>
            </div>
          )}
        </div>
      </div>

      {hasProjectsRole ? (
        <Tabs
          tabs={[
            {
              content: <VisibleWhiteboards />,
              id: 'visible',
              title: t('items.whiteboards', { count: 2 }),
              icon: faImages,
            },
            {
              content: <ArchivedWhiteboards />,
              id: 'archived',
              title: t('common.archived', { count: 2 }),
              icon: faTrashCan,
            },
            {
              content: <UnClassifiedWhiteboards />,
              id: 'unclassified',
              title: t('common.noFolder'),
              icon: faFileCircleQuestion,
            },
          ]}
        />
      ) : (
        <>
          {' '}
          <div className='grid grid-cols-2 gap-4 overflow-y-auto xl:grid-cols-3 2xl:grid-cols-4'>
            {whiteboard && (
              <WhiteboardCard whiteboardId={whiteboard.id}></WhiteboardCard>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const ArchivedWhiteboards = () => {
  const { whiteboards } = useGetWhiteboards('archived');

  return (
    <div className='grid h-full grid-cols-1 gap-4 overflow-y-auto md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
      {whiteboards &&
        whiteboards.map((whiteboard) => (
          <WhiteboardCard whiteboardId={whiteboard.id}></WhiteboardCard>
        ))}
    </div>
  );
};

const VisibleWhiteboards = () => {
  const { whiteboards } = useGetWhiteboards('visible');

  return (
    <div className='grid h-full grid-cols-1 gap-4 overflow-y-auto md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
      {whiteboards &&
        whiteboards.map((whiteboard) => (
          <WhiteboardCard whiteboardId={whiteboard.id}></WhiteboardCard>
        ))}
    </div>
  );
};

const UnClassifiedWhiteboards = () => {
  const { whiteboards } = useGetWhiteboards('visible', true);

  return (
    <div className='grid h-full grid-cols-1 gap-4 overflow-y-auto md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
      {whiteboards &&
        whiteboards.map((whiteboard) => (
          <WhiteboardCard whiteboardId={whiteboard.id}></WhiteboardCard>
        ))}
    </div>
  );
};

export default Whiteboards;
