import { useAlbumContext } from '../../context/useAlbumContext';
import Dropdown from '../../../../lib/Dropdown/Dropdown';
import {
  faBookOpen,
  faPencil,
  faPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { AlbumShort } from '../../../../../types/api';
import {
  DropdownElementProps,
  DropdownHeaderProps,
  Option,
} from '../../../../lib/Dropdown/types/dropdown.types';
import { useTranslation } from 'react-i18next';
import useGetAlbums from '../../../../../hooks/albums/useGetAlbums';
import { motion } from 'framer-motion';
import SimpleButton from '../../../../lib/SimpleButton';
import { usePanelContext } from '../../context/usePanelContext';
import { useGetWhiteboard } from '@/hooks/whiteboard/useGetWhiteboard';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const AlbumDropdown = () => {
  const { selectedAlbum, setSelectedAlbum, setAlbumToModify } =
    useAlbumContext();
  //Get the related whiteboard
  const { whiteboardId } = useParams();
  const { whiteboard } = useGetWhiteboard(whiteboardId ?? '');

  const { albums, isLoading } = useGetAlbums(
    'visible',
    whiteboard?.project?.id ?? ''
  );
  const { t } = useTranslation(['panel']);

  const triggerStyles = selectedAlbum
    ? 'bg-d-surface-bg-accent text-text-negative'
    : 'outline outline-1 outline-d-text-2 bg-transparent';

  const [initialAlbum, setInitialAlbum] = useState<Option | null>(null);
  const [albumOptions, setAlbumOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!albums) return;

    const selectedAlbumFromStorage = localStorage.getItem(`imagineSelectedAlbum-${whiteboardId}`);
    if (selectedAlbumFromStorage) {
      setSelectedAlbum(selectedAlbumFromStorage);
    }

    const selectedAlbumData = albums.find((album) => album.id === selectedAlbumFromStorage);
    if (selectedAlbumData) {
      setInitialAlbum({
        id: selectedAlbumData.id,
        label: selectedAlbumData.name,

        element: (props) => (
          <AlbumDropdownElement {...props} album={selectedAlbumData} />
        ),
      });
    }

    setAlbumOptions(albums.map((album) => ({
      id: album.id,
      label: album.name,
      value: album,
      element: (props) => <AlbumDropdownElement {...props} album={album} />,
    })));
  }, [albums]);

  const handleAlbumSelectionChange = (value: string | null) => {
    setSelectedAlbum(value);
    setAlbumToModify(value);
    localStorage.setItem(`imagineSelectedAlbum-${whiteboardId}`, value || '');
  };

  if (isLoading) return;
  if (!albums) return;

  return (
    <div className='flex w-full flex-col gap-2'>
      <Dropdown
        initialValue={initialAlbum}
        options={albumOptions}
        Header={(props) => <AlbumDropdownHeader {...props} />}
        search
        isContentContained={false}
        icon={faBookOpen}
        showSelectedWithinList={true}
        placeholder={t('panel:Inspire.AlbumPlaceholder')}
        triggerStyles={triggerStyles}
        onChange={(option) => {
          handleAlbumSelectionChange(option ? option.id : null)
        }}
      />
    </div>
  );
};

const AlbumDropdownElement = ({
  album,
  isSelected,
  close,
  reset,
}: DropdownElementProps & { album: AlbumShort }) => {
  const { setAlbumToModify } = useAlbumContext();

  return (
    <div className='flex w-full items-center justify-between gap-2'>
      <div className='flex items-center gap-2'>
        <div className='aspect-square rounded-md bg-white p-2'>
          <SimpleButton
            className='text-text-negative'
            size='squareXXS'
            leftIcon={isSelected ? faTimes : faBookOpen}
            onClick={() => {
              if (isSelected && reset) {
                reset();
              }
            }}
          />
        </div>
        <div>{album.name}</div>
      </div>
      {isSelected && (
        <div
          className='cursor-pointer rounded-md border p-2'
          onClick={(e) => {
            e.stopPropagation();
            close && close();
            setAlbumToModify(album.id);
          }}>
          <SimpleButton size='squareXXS' leftIcon={faPencil} />
        </div>
      )}
    </div>
  );
};

const AlbumDropdownHeader = ({ close }: DropdownHeaderProps) => {
  const { t } = useTranslation(['panel']);
  const { setShowCreateNewAlbum } = usePanelContext();
  return (
    <motion.div layout className=''>
      <motion.div
        layout
        className='flex w-full items-center justify-between px-4'>
        <motion.div layout className='font-semibold'>
          {t('panel:Inspire.AlbumDropdownHeader')}
        </motion.div>
        <SimpleButton
          leftIcon={faPlus}
          className={'bg-green-to-violet-gradient text-white'}
          size='squareXS'
          onClick={() => {
            close && close();
            setShowCreateNewAlbum(true);
          }}
          variant='primary'></SimpleButton>
      </motion.div>
    </motion.div>
  );
};

export default AlbumDropdown;
