import { ReactNode } from 'react';

type InfoTagProps = {
  children: ReactNode;
};

const InfoTag = ({ children }: InfoTagProps) => {
  return (
    <div className='flex w-min items-center gap-2 rounded-lg border border-info-tag p-2 py-1 text-info-tag'>
      {children}
    </div>
  );
};

export default InfoTag;
